import React from 'react';
import PageSection from 'components/PageSection';
import { Body1, SectionTitle } from 'components/Typography';

const ComingSoon = () => {
    return (
        <PageSection element="main" className="pt-8 pb-32">
            <SectionTitle>Coming soon</SectionTitle>
            <Body1>We are working on this page, it will be available very soon</Body1>
        </PageSection>
    );
};

export default ComingSoon;
