import React from 'react';
import PageSection from 'components/PageSection';
import { Title2 } from 'components/Typography';
import { backedBy } from 'config/partners';
import Gallery from 'components/Gallery';

const BackedBy = () => {
    const backedByNotHidden = backedBy.filter((company) => !company.hidden);

    return (
        <PageSection
            className="relative z-10 bg-white"
            containerClass="flex flex-col items-center gap-24 py-16 md:pb-32 lg:py-44"
        >
            <Title2 className="fade-in-scroll text-neutral-700 text-center">Backed by the best VCs in energy</Title2>
            <Gallery className="fade-in-scroll" items={backedByNotHidden} size="small" />
        </PageSection>
    );
};

export default BackedBy;
