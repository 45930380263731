const size = {
  xs: "360px",
  sm: "480px",
  md: "768px",
  lg: "1152px",
  xl: "1440px",
};

export const xsmall = (styles) => {
  return `@media screen and (min-width: ${size.xs}) {${styles}}`;
};
export const small = (styles) => {
  return `@media screen and (min-width: ${size.sm}) {${styles}}`;
};
export const medium = (styles) => {
  return `@media screen and (min-width: ${size.md}) {${styles}}`;
};
export const large = (styles) => {
  return `@media screen and (min-width: ${size.lg}) {${styles}}`;
};
export const xLarge = (styles) => {
  return `@media screen and (min-width: ${size.xl}) {${styles}}`;
};
