import React from 'react';
import PropTypes from 'prop-types';

const Textarea = ({ className = '', error, id = '', item, onChange, style = {}, validateForm }) => {
    const handleChange = (ev) => {
        const value = ev.target.value;
        onChange(item.id, value);
    };

    return (
        <div
            className={`flex flex-col gap-2 ${item.fullSize ? 'md:col-span-2' : ''} ${className}`}
            id={id}
            style={style}
        >
            <label className="font-bold md:text-lg lg:text-xl">
                {item.label}
                {item.required ? ' *' : ''}
            </label>
            <div className="relative">
                <textarea
                    className={`w-full border-1 rounded-lg py-4 px-2 min-h-[180px] md:text-lg lg:text-xl ${
                        error
                            ? 'border-error focus:border-2 focus:outline-none focus:border-error'
                            : 'border-neutral-900'
                    }`}
                    id={item.id}
                    name={item.id}
                    onBlur={(ev) => validateForm(item.id, ev.target.value)}
                    onChange={handleChange}
                    placeholder={item.placeholder}
                    required={item.required}
                    value={item.value}
                />
                {error && (
                    <svg
                        className="absolute right-2 top-[50%] translate-y-[-50%]"
                        width="20"
                        height="20"
                        viewBox="0 0 20 20"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <path
                            fillRule="evenodd"
                            clipRule="evenodd"
                            d="M10 1.75C5.44321 1.75 1.75 5.44321 1.75 10C1.75 14.5568 5.44321 18.25 10 18.25C14.5568 18.25 18.25 14.5568 18.25 10C18.25 5.44321 14.5568 1.75 10 1.75ZM10 19.75C4.61479 19.75 0.25 15.3852 0.25 10C0.25 4.61479 4.61479 0.25 10 0.25C15.3852 0.25 19.75 4.61479 19.75 10C19.75 15.3852 15.3852 19.75 10 19.75Z"
                            className="fill-error"
                        />
                        <path
                            fillRule="evenodd"
                            clipRule="evenodd"
                            d="M10 4.75C10.4142 4.75 10.75 5.08579 10.75 5.5V10.5C10.75 10.9142 10.4142 11.25 10 11.25C9.58579 11.25 9.25 10.9142 9.25 10.5V5.5C9.25 5.08579 9.58579 4.75 10 4.75Z"
                            className="fill-error"
                        />
                        <circle cx="10" cy="14" r="1" className="fill-error" />
                    </svg>
                )}
            </div>
            {error && <span className="text-error">{error}</span>}
        </div>
    );
};

export default Textarea;

Textarea.propTypes = {
    className: PropTypes.string,
    error: PropTypes.string,
    id: PropTypes.string,
    item: PropTypes.shape({
        fullSize: PropTypes.bool,
        id: PropTypes.string,
        label: PropTypes.string,
        placeholder: PropTypes.string,
        required: PropTypes.bool,
        values: PropTypes.string
    }),
    onChange: PropTypes.func,
    style: PropTypes.object,
    validateForm: PropTypes.func
};
