import React, { useState } from "react";
import PageSection from "components/PageSection";
import { Body2, Small, Title2Alt } from "components/Typography";
import Accordion from "components/Accordion";

const steps = {
    application: 0,
    initialChat: 1,
    formalInterview: 2,
    decission: 3
};

const RecruitmentProcess = () => {
    const [openStep, setOpenStep] = useState(steps.application);
    return (
        <PageSection
            className="relative z-10 bg-white"
            containerClass="flex flex-col lg:flex-row justify-between py-16 md:pb-32 lg:py-44"
            id="careers-list"
        >
            <div className="lg:w-5/12">
                <Body2 className="fade-in-scroll uppercase text-secondary mb-4">
                    how does recruitment work?
                </Body2>
                <Title2Alt className="fade-in-scroll mb-4">Our process</Title2Alt>
                <Small className="fade-in-scroll">
                    We treat candidates the way we want to be treated. That means a simple recruitment process that
                    values your time and operates with respect. Every touchpoint with InRange is an opportunity for us to get to
                    know you, and for you to get to know us.
                </Small>
            </div>

            <div className="lg:w-5/12 mt-16 lg:mt-40 fade-in-scroll">
                <Accordion
                    isOpen={openStep === steps.application}
                    setIsOpen={() =>
                        setOpenStep((prevState) => (prevState === steps.application ? null : steps.application))
                    }
                    index="01"
                    title="Application"
                    open
                >
                    <p>
                        Candidates are invited to submit their application. We assess each candidate's qualifications and 
                        suitability for the role. If we think there could be a fit, we reach out to chat.
                    </p>
                </Accordion>
                <Accordion
                    isOpen={openStep === steps.initialChat}
                    setIsOpen={() =>
                        setOpenStep((prevState) => (prevState === steps.initialChat ? null : steps.initialChat))
                    }
                    index="02"
                    title="Initial chat"
                >
                    <p>
                         This is an opportunity for us to get to know a candidate better, understand their background and career goals, and
                        answer any questions they may have about the role or the company. 
                    </p>
                </Accordion>
                <Accordion
                    isOpen={openStep === steps.formalInterview}
                    setIsOpen={() =>
                        setOpenStep((prevState) => (prevState === steps.formalInterview ? null : steps.formalInterview))
                    }
                    index="03"
                    title="Formal interview"
                >
                    <p>
                        This is where the fun really begins! You'll have the opportunity to meet with various members of our
                        team and show us your skills, experience, and personality. We want you to feel comfortable and
                        be yourself, so don't be afraid to ask questions.
                    </p>
                </Accordion>
                <Accordion
                    isOpen={openStep === steps.decission}
                    setIsOpen={() =>
                        setOpenStep((prevState) => (prevState === steps.decission ? null : steps.decission))
                    }
                    index="04"
                    title="Decision"
                >
                    <p>
                        After the formal interview(s), we discuss and make a decision on an offer. We are intentional about
                        communicating decisions to all candidates in a timely and respectful manner. 
                    </p>
                </Accordion>
            </div>
        </PageSection>
    );
};

export default RecruitmentProcess;
