import React, { useLayoutEffect, useRef } from 'react';
import { useLocation } from 'react-router';
import { useGsapContext } from 'gsapContext';

const GlobalAnimations = ({ children }) => {
    const $contextRef = useRef();
    const location = useLocation();
    const { gsap } = useGsapContext();

    useLayoutEffect(() => {
        let ctx = gsap.context((self) => {
            gsap.utils.toArray('.fade-in-scroll').forEach((elem) => {
                gsap.fromTo(
                    elem,
                    {
                        opacity: 0
                    },
                    {
                        opacity: 1,
                        scrollTrigger: {
                            trigger: elem,
                            start: 'top bottom-=150',
                            toggleActions: 'restart none none reverse'
                        }
                    }
                );
            });
            gsap.utils.toArray('.fade-in-scroll-2').forEach((elem) => {
                gsap.fromTo(
                    elem,
                    {
                        opacity: 0
                    },
                    {
                        opacity: 1,
                        delay: 0.3,
                        scrollTrigger: {
                            trigger: elem,
                            start: 'top bottom-=150',
                            toggleActions: 'restart none none reverse'
                        }
                    }
                );
            });
        }, $contextRef);

        return () => ctx.revert();
        // eslint-disable-next-line
    }, [$contextRef, location.pathname]);

    return <div ref={$contextRef}>{children}</div>;
};

export default GlobalAnimations;
