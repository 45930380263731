import React, { useLayoutEffect, useRef } from "react";
import PageSection from "components/PageSection";
import { Body1, Title1 } from "components/Typography";
import appConfig from "config/appConfig";
import { useGsapContext } from "gsapContext";
import { paths } from 'constants';
import { Link } from 'react-router-dom';
import Button from 'components/Button';

const EPCSolutionHeader = () => {
  const $context = useRef();
  const { gsap } = useGsapContext();

  useLayoutEffect(() => {
    let ctx = gsap.context(() => {
      const timeline = gsap.timeline({ delay: 0.3 });
      timeline.fromTo(
        ".bottom-fade-in",
        { opacity: 0, y: "50px" },
        { opacity: 1, y: 0, duration: 0.2 }
      );
      timeline.fromTo(
        ".bottom-fade-in-2",
        { opacity: 0, y: "50px" },
        { opacity: 1, y: 0, duration: 0.2 }
      );
      timeline.fromTo(
        ".bottom-fade-in-3",
        { opacity: 0, y: "50px" },
        { opacity: 1, y: 0, duration: 0.2 }
      );
    }, $context);

    return () => ctx.revert();
    // eslint-disable-next-line
  }, []);

  return (
    <PageSection
      ref={$context}
      element="main"
      className={`pb-16 lg:pb-32 ${
        appConfig.layout.header.fixed ? "pt-24 lg:pt-32" : "pt-16 lg:pt-20"
      }`}
      containerClass="flex flex-col lg:flex-row gap-16"
    >
      <div className="w-full lg:w-1/2">
        <Title1 className="bottom-fade-in">
        Connect to more customers, more profitably.
        </Title1>
        <Body1 className="bottom-fade-in-2 mt-6 lg:mt-10 mb-6">
        As an InRange EPC contractor partner, you get connected to our portfolio of engaged landlords and pre-vetted projects. <br/> <br/>
        Benefit from scale with larger installations and greater operational efficiency.
        </Body1>
        <div className="bottom-fade-in-3">
        <Link to={paths.contactUs}>
                    <Button
                        element="div"
                        className="px-2 lg:px-10"
                    >
                        Get in touch
                    </Button>
                </Link>
        </div>
      </div>
    </PageSection>
  );
};

export default EPCSolutionHeader;
