import React, { useLayoutEffect, useRef } from 'react';
import List from 'components/List';
import ListItem from 'components/ListItem';
import PageSection from 'components/PageSection';
import { socialMedia } from 'config/navigation';
import appConfig from 'config/appConfig';
import { Body1, Featured2, Title1 } from 'components/Typography';
import RequestADemoForm from 'components/composite/RequestADemoForm';
import { useGsapContext } from 'gsapContext';

// import RedirectPage from 'pages/RedirectPage';

// function RequestDemoPage() {
//     return (
//         <RedirectPage
//             destination={'https://calendly.com/john-1778/30min'}
//             text={'Book time to talk to us about a demo...'}
//         />
//     );
// }

const RequestDemoPage = () => {
    const $context = useRef();
    const { gsap } = useGsapContext();

    useLayoutEffect(() => {
        let ctx = gsap.context(() => {
            const timeline = gsap.timeline({ delay: 0.3 });
            timeline.fromTo('.bottom-fade-in', { opacity: 0, y: '50px' }, { opacity: 1, y: 0, duration: 0.2 });
            timeline.fromTo('.bottom-fade-in-2', { opacity: 0, y: '50px' }, { opacity: 1, y: 0, duration: 0.2 });
            timeline.fromTo('.bottom-fade-in-3', { opacity: 0, y: '50px' }, { opacity: 1, y: 0, duration: 0.2 });
            timeline.fromTo('.bottom-fade-in-4', { opacity: 0, y: '50px' }, { opacity: 1, y: 0, duration: 0.2 });
        }, $context);

        return () => ctx.revert();
        // eslint-disable-next-line
    }, []);

    return (
        <PageSection
            ref={$context}
            element="main"
            containerClass="flex flex-col-reverse lg:flex-row gap-16 lg:gap-0"
            className={`pb-16 lg:pb-32 ${appConfig.layout.header.fixed ? 'pt-16 lg:pt-24' : 'pt-8 lg:pt-12'}`}
        >
            <div className="w-full lg:w-1/4 flex flex-col md:flex-row md:justify-between lg:justify-start lg:flex-col gap-6">
                <div className="bottom-fade-in-2 flex flex-col gap-3">
                    <Featured2 className="">Inquiries</Featured2>
                    <a href={`mailto:${appConfig.appInformation.inquiriesEmail}`}>
                        <Body1 className="text-neutral-900">{appConfig.appInformation.inquiriesEmail}</Body1>
                    </a>
                </div>
                {/* <div className="bottom-fade-in-3 flex flex-col gap-3">
                    <Featured2>Support</Featured2>
                    <Body1 className="text-neutral-900">
                        <a href={`mailto:${appConfig.appInformation.supportEmail}`}>
                            {appConfig.appInformation.supportEmail}
                        </a>
                    </Body1>
                </div> */}
                <div className="bottom-fade-in-4 flex flex-col gap-3">
                    <Featured2>Social Media</Featured2>
                    <List className="gap-4" horizontal>
                        {socialMedia
                            .filter((item) => !item.hidden)
                            .map((item) => (
                                <ListItem
                                    style={{ transform: 'scale(1.3)' }}
                                    externalLink
                                    key={item.link}
                                    iconColor="primary"
                                    item={item}
                                    onlyIcon
                                />
                            ))}
                    </List>
                </div>
            </div>
            <div className="bottom-fade-in w-full lg:w-3/4 lg:px-16 lg:border-l-1 border-neutral">
                <Title1 className="mb-4">Request a demo</Title1>
                <Body1 className="mb-6">
                    We're excited to show you how InRange can help you generate more revenue from your assets, or cut costs on your energy.
                </Body1>
                <RequestADemoForm />
            </div>
        </PageSection>
    );
};

export default RequestDemoPage;
