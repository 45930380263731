import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';

const Elements = {
    footer: forwardRef(({ children, ...props }, ref) => (
        <footer ref={ref} {...props}>
            {children}
        </footer>
    )),
    header: forwardRef(({ children, ...props }, ref) => (
        <header ref={ref} {...props}>
            {children}
        </header>
    )),
    main: forwardRef(({ children, ...props }, ref) => (
        <main ref={ref} {...props}>
            {children}
        </main>
    )),
    section: forwardRef(({ children, ...props }, ref) => (
        <section ref={ref} {...props}>
            {children}
        </section>
    )),
    div: forwardRef(({ children, ...props }, ref) => (
        <div ref={ref} {...props}>
            {children}
        </div>
    ))
};

const PageSection = forwardRef(
    (
        {
            background = '',
            backgroundSize = '',
            className = '',
            children,
            color = 'default',
            containerClass = '',
            element = 'section',
            id = '',
            style = {}
        },
        ref
    ) => {
        const Element = Elements[element];

        return (
            <Element
                className={`${
                    color === 'inverted' ? 'bg-primary text-white' : color === 'neutral' ? 'bg-neutral' : ''
                } ${className}`}
                id={id}
                ref={ref}
                style={{
                    ...style,
                    background,
                    backgroundSize,
                    backgroundColor: color === 'inverted' ? '#00022F' : color === 'neutral' ? '#EEEEEE' : ''
                }}
            >
                <div className={`container-screen ${containerClass}`}>{children}</div>
            </Element>
        );
    }
);

export default PageSection;

PageSection.propTypes = {
    background: PropTypes.string,
    backgroundSize: PropTypes.string,
    children: PropTypes.node,
    className: PropTypes.string,
    color: PropTypes.oneOf(['default', 'inverted', 'neutral']),
    containerClass: PropTypes.string,
    element: PropTypes.oneOf(['section', 'main', 'header', 'footer', 'div']),
    id: PropTypes.string,
    style: PropTypes.object
};
