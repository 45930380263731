import React, { useLayoutEffect, useRef } from "react";
import PageSection from "components/PageSection";
import { Body1, Body2, Title1 } from "components/Typography";
import { useGsapContext } from "gsapContext";
import AnalyseSite from "components/composite/AnalyseSite";

const Header = () => {
  const $context = useRef();
  const { gsap } = useGsapContext();

  useLayoutEffect(() => {
    let ctx = gsap.context(() => {
      const timeline = gsap.timeline();
      timeline.fromTo(
        ".bottom-fade-in-1",
        { opacity: 0, y: "50px" },
        { opacity: 1, y: 0, duration: 0.2 }
      );
      timeline.fromTo(
        ".bottom-fade-in-2",
        { opacity: 0, y: "50px" },
        { opacity: 1, y: 0, duration: 0.2 }
      );
      timeline.fromTo(
        ".bottom-fade-in-3",
        { opacity: 0, y: "50px" },
        { opacity: 1, y: 0, duration: 0.2 }
      );
      timeline.fromTo(
        ".bottom-fade-in-4",
        { opacity: 0, y: "50px" },
        { opacity: 1, y: 0, duration: 0.2 }
      );
    }, $context);

    return () => ctx.revert();
    // eslint-disable-next-line
  }, []);

  return (
    <PageSection
      ref={$context}
      containerClass="pb-16 md:pb-24 pt-4 md:pt-16"
      element="main"
    >
      <div className="lg:w-7/12">
        <Body2 className="bottom-fade-in-1 uppercase mb-4">
          the inrange platform
        </Body2>
        <Title1 className="bottom-fade-in-2 text-secondary">
        Turning the built environment into a clean energy network.
        </Title1>
        <Body1 className="bottom-fade-in-3 mt-6 md:mt-10 mb-6">
        InRange’s AI-powered platform simplifies and accelerates the renewable energy procurement process.
        </Body1>
        <Body1 className="bottom-fade-in-3 mt-6 md:mt-6 mb-6">
        We automate analysis, streamline deals, align stakeholders, and optimise energy networks to unlock a new energy asset class.
        </Body1>
        <Body1 className="bottom-fade-in-3 mt-6 md:mt-6 mb-6">
          See for yourself.
        </Body1>
        <div className={"bottom-fade-in-4"}>
          <AnalyseSite buttonColor={"primary"} border={true}></AnalyseSite>
        </div>
      </div>
    </PageSection>
  );
};

export default Header;
