import React from "react";
import EnergyBuyerSolutionHeaderAnalyse from "./EnergyBuyerSolutionHeaderAnalyse";
import EnergyBuyerSolutionSectionFour from "./EnergyBuyerSolutionSectionFour";
import EnergyBuyerSolutionSectionThree from "./EnergyBuyerSolutionSectionThree";
import EnergyBuyerSolutionSectionTwo from "./EnergyBuyerSolutionSectionTwo";
import DynamicAnalyse from "pages/DynamicAnalyse";

const SolutionPage = () => {
  return (
    <>
      <EnergyBuyerSolutionHeaderAnalyse />
      <EnergyBuyerSolutionSectionTwo />
      <EnergyBuyerSolutionSectionThree />
      <EnergyBuyerSolutionSectionFour />
      <DynamicAnalyse page="tenant" />
    </>
  );
};

export default SolutionPage;
