import React from "react";
import EPCSolutionHeaderAnalyse from "./EPCSolutionHeaderAnalyse";
import EPCSolutionSectionFive from "./EPCSolutionSectionFive";
import EPCSolutionSectionFour from "./EPCSolutionSectionFour";
import EPCSolutionSectionThree from "./EPCSolutionSectionThree";
import EPCSolutionSectionTwo from "./EPCSolutionSectionTwo";

const SolutionPage = () => {
  return (
    <>
      <EPCSolutionHeaderAnalyse />
      <EPCSolutionSectionTwo />
      <EPCSolutionSectionThree />
      <EPCSolutionSectionFour />
      <EPCSolutionSectionFive />
    </>
  );
};

export default SolutionPage;
