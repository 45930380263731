import Button from "components/Button";
import List from "components/List";
import ListItem from "components/ListItem";
import { Logo } from "components/Logo";
import PageSection from "components/PageSection";
import { navItems, socialMedia } from "config/navigation";
import React from "react";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";
import { paths } from "constants";

const Footer = ({ fixed }) => {
  return (
    <PageSection element="footer" className="py-16" color="inverted">
      <div className="flex flex-col-reverse md:flex-col lg:flex-row w-full justify-between pb-4 md:pb-8 lg:pb-16">
        <div className="flex-1 flex flex-col-reverse md:flex-col gap-8">
          <Link to={paths.home}>
            <Logo className="w-36" color="white" includeLogoType />
          </Link>

          <nav>
            <ul className="flex flex-col md:flex-row gap-4 md:items-center">
              {navItems
                .filter((item) => !item.hidden)
                .map((item) => {
                  if (item.name === "Career")
                    return (
                      <ListItem
                        target={item.target}
                        externalLink={item.externalLink}
                        key={item.link}
                        item={{
                          ...item,
                        }}
                      />
                    );
                  if (item.name === "Solutions")
                    return (
                      <ListItem
                        dropdownClassName="text-white bg-primary"
                        item={{
                          name: item.name,
                          customElement: (
                            <div className="flex items-center gap-2">
                              {item.name}
                              <svg
                                className="hidden md:block"
                                width="12"
                                height="8"
                                viewBox="0 0 12 8"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  d="M1.41 0.579956L6 5.16996L10.59 0.579956L12 1.99996L6 7.99996L0 1.99996L1.41 0.579956Z"
                                  fill="white"
                                />
                              </svg>
                            </div>
                          ),
                          children: item.children,
                        }}
                        key={item.link}
                      />
                    );
                  return (
                    <ListItem
                      target={item.target}
                      externalLink={item.externalLink}
                      key={item.link}
                      item={item}
                    />
                  );
                })}
            </ul>
          </nav>
        </div>

        <div className="flex-1 flex flex-col items-end gap-8 mb-16 md:mb-0 md:mt-16 lg:mt-0 lg:pt-2">
          <p className="text-right">The next generation clean energy company</p>
          <Link to={paths.requestDemo}>
            <Button element="div" color="secondary">
              Request a demo
            </Button>
          </Link>
        </div>
      </div>

      <div className="flex flex-col-reverse md:flex-row gap-8 justify-between md:items-center border-t-1 border-white pt-8">
        <List className="gap-4" horizontal>
          {socialMedia
            .filter((item) => !item.hidden)
            .map((item) => (
              <ListItem externalLink key={item.link} item={item} onlyIcon />
            ))}
        </List>

        <div className="flex gap-4 items-center text-sm md:text-base">
          {/* <Link to="#">Terms of Service</Link>
                    <span>|</span> */}
          <Link to={paths.privacy}>Privacy Policy</Link>
        </div>
      </div>
    </PageSection>
  );
};

export default Footer;

Footer.propTypes = {
  fixed: PropTypes.bool,
};
