import React, { useCallback, useEffect, useState } from "react";
import styled from "styled-components";
import PropTypes from "prop-types";
import TextField from "components/TextField";
import Button from "components/Button";
import { medium, large } from "styles/breakpoints";
import Textarea from "components/Textarea";
import * as yup from "yup";

const FormStyled = styled.form`
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  align-items: flex-start;
  width: 100%;
  & .fields-wrapper {
    display: grid;
    grid-template-columns: 1fr;
    gap: 1.5rem 2.5rem;
    width: 100%;
    ${medium(`
            grid-template-columns: repeat(2, 1fr);
        `)}
  }
  & .fields {
    width: 100%;
    ${large(`
            width: 70%;
        `)}
  }
`;

const FieldOptions = {
  text: (args) => <TextField {...args} type="text" />,
  textarea: (args) => <Textarea {...args} />,
  number: (args) => <TextField {...args} type="number" />,
  date: () => {},
  select: () => {},
  checkbox: () => {},
  radio: () => {},
  email: (args) => <TextField {...args} type="email" />,
  default: (args) => <TextField {...args} type="text" />,
};

const Form = ({
  children,
  className = "",
  fields,
  id = "",
  loading,
  nativeSubmit,
  onSubmit,
  style = {},
  submitText = "Submit",
  validations = {},
  validateOnChange = true,
  wrap = true,
  inheritFont = false,
  ...rest
}) => {
  const [errors, setErrors] = useState({});
  const [isValidForm, setIsValidForm] = useState(false);
  const [values, setValues] = useState({});

  const validateFormFn = useCallback(
    async (newValues) => {
      const valid = await yup
        .object()
        .shape({
          ...validations,
        })
        .isValid(newValues);
      setIsValidForm(valid);
    },
    [validations]
  );

  useEffect(() => {
    if (fields.length) {
      fields.forEach((field) => {
        setErrors((prevState) => ({
          ...prevState,
          [field.id]: null,
        }));
      });
    }
  }, [fields]);

  const handleChange = async (key, value) => {
    const newValues = {
      ...values,
      [key]: value,
    };
    setValues(newValues);
    await validateFormFn(newValues);
    if (validateOnChange) {
      await validateForm(key, value);
    }
  };

  const handleSubmit = (ev) => {
    if (!nativeSubmit) {
      ev.preventDefault();
      onSubmit?.(values);
    } else if (onSubmit) onSubmit(ev);
    else return ev;
  };

  const validateForm = useCallback(
    async (fieldId, value) => {
      if (!validations?.[fieldId]) return;
      try {
        await validations[fieldId].validate(value);
        setErrors((prevState) => ({
          ...prevState,
          [fieldId]: null,
        }));
      } catch (error) {
        setErrors((prevState) => ({
          ...prevState,
          [fieldId]: error.errors?.[0],
        }));
      }
    },
    [validations]
  );

  return (
    <FormStyled
      className={className}
      id={id}
      onSubmit={handleSubmit}
      style={style}
      {...rest}
    >
      <div className={wrap ? "fields-wrapper" : "fields"}>
        {fields.map((field) => {
          const { type, value, ...restField } = field;
          const Element = FieldOptions[type] || FieldOptions.default;
          return (
            <Element
              key={field.id}
              error={errors[field.id]}
              item={{ value: values[field.id] || field.value, ...restField }}
              gap={rest.gap}
              onChange={handleChange}
              validateForm={validateForm}
              inheritFont={inheritFont}
            />
          );
        })}
      </div>
      {children && <div className="children-wrapper">{children}</div>}
      <Button disabled={!isValidForm || loading} type="submit">
        {submitText}
      </Button>
    </FormStyled>
  );
};

export default Form;

Form.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  fields: PropTypes.arrayOf(
    PropTypes.shape({
      fullSize: PropTypes.bool,
      id: PropTypes.string,
      label: PropTypes.string,
      placeholder: PropTypes.string,
      required: PropTypes.bool,
      type: PropTypes.oneOf([
        "text",
        "textarea",
        "number",
        "date",
        "select",
        "checkbox",
        "radio",
        "email",
      ]),
      values: PropTypes.string,
    })
  ),
  id: PropTypes.string,
  nativeSubmit: PropTypes.bool,
  onSubmit: PropTypes.func,
  style: PropTypes.object,
  submitText: PropTypes.string,
  validations: PropTypes.object,
};
