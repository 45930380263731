import React from "react";
import PageSection from "components/PageSection";
import { Body1, Body2, Title2Alt, Title2 } from "components/Typography";

const OurValues = () => {
    return (
        <PageSection className="relative z-10 bg-white" color="neutral" containerClass="py-16 md:pb-32 lg:py-44">
            <div className="lg:w-1/2 mb-24">
                <Body2 className="fade-in-scroll uppercase text-secondary mb-4">Our values</Body2>
                <Title2Alt className="fade-in-scroll">Tenacity through our values</Title2Alt>
            </div>
            <div className="grid lg:grid-cols-2 gap-24">
                <div className="fade-in-scroll">
                    <Title2 className="text-secondary mb-8">Do the right thing</Title2>
                    <Body1>
                        Our guiding principle for how InRange started, and the north-star in how we do business
                        with our clients, investors, partners, community, and each other. That is how we'll protect the planet.
                    </Body1>
                </div>
                <div className="fade-in-scroll">
                    <Title2 className="text-secondary mb-8">Move with purpose</Title2>
                    <Body1>
                        We are driven by our purpose and our mission. We believe that everyone needs to embody that
                        in their daily work. This becomes the way we are able achieve what people think to
                        be impossible.
                    </Body1>
                </div>
                <div className="fade-in-scroll">
                    <Title2 className="text-secondary mb-8">Nurture trust and integrity</Title2>
                    <Body1>
                        We love disagreeble givers that provide the candid feedback we need to hear. We trust each other, and know that 
                         critical feedback given with integrity raises the bar for us all to do, and be, our best.
                    </Body1>
                </div>
                <div className="fade-in-scroll">
                    <Title2 className="text-secondary mb-8">Be unstoppable</Title2>
                    <Body1>
                        We know that no matter your experience or background, we are in uncharted territory. 
                        Your ability to break down any challenge and solve it relentlessly is what
                        makes us unstoppable.
                    </Body1>
                </div>
            </div>
        </PageSection>
    );
};

export default OurValues;
