import PageSection from 'components/PageSection';
import { Body1, SectionTitle } from 'components/Typography';
import React from 'react';

const SolutionSectionFour = () => {
    return (
        <PageSection containerClass="py-16 md:py-32" className="border-b-1 border-neutral">
            <div className="w-full lg:w-1/2">
                <SectionTitle className="fade-in-scroll">
                Reliable energy on the InRange Marketplace
                </SectionTitle>
            </div>
            <div className="flex flex-col lg:flex-row gap-8 lg:gap-16 mt-16">
                <div className="flex-1">
                    <Body1 className="fade-in-scroll">
                        Participation in the InRange network grants your team unparalleled access to local, clean energy procurement. Buy on-site or from nearby buildings on the InRange Marketplace at fixed, long term tariffs.
                        </Body1>
                </div>
                <div className="flex-1">
                    <Body1 className="fade-in-scroll">
                     InRange sleeves with your current supplier to deliver network energy with simple billing. We also issue you the renewable energy certificates (REGOs) that prove the origin of the energy you purchased.
                    </Body1>
                </div>
            </div>
        </PageSection>
    );
};

export default SolutionSectionFour;
