import React from 'react';
import PropTypes from 'prop-types';

const Gallery = ({ className, items, size = 'default', ...props }) => {
    return (
        <div
            className={`flex gap-16 items-center justify-center md:justify-evenly flex-wrap ${className || ''}`}
            {...props}
        >
            {items.map((item) => (
                <div
                    key={item.id || item.name}
                    className={size === 'large' ? 'h-24' : size === 'small' ? 'h-5 md:h-8' : 'h-16'}
                >
                    <img className="h-full object-contain" src={item.image} alt={item.name} />
                </div>
            ))}
        </div>
    );
};

export default Gallery;

Gallery.propTypes = {
    items: PropTypes.arrayOf(
        PropTypes.shape({
            id: PropTypes.string,
            name: PropTypes.string,
            image: PropTypes.any
        })
    ),
    size: PropTypes.oneOf(['small', 'default', 'large'])
};
