import React from 'react';
import PageSection from 'components/PageSection';
import { Body1, SectionSubtitle, SectionTitle } from 'components/Typography';
import generationPotentialImg from 'assets/images/installer-1.jpg';

const GenerationPotential = () => {
    return (
        <PageSection className="pb-28">
            <div className="flex flex-col lg:flex-row gap-16 lg:items-center">
                <div className="flex-1">
                    <SectionTitle className="fade-in-scroll">
                    Streamline deal execution
                    </SectionTitle>
                    <div className="flex flex-col gap-4 lg:gap-6 mt-12 w-3/4">
                        <div className="fade-in-scroll flex gap-8">
                        <svg
                                className="shrink-0 mt-2 md:mt-3"
                                width="28"
                                height="22"
                                viewBox="0 0 28 22"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <path
                                    d="M12.6665 17.6673H15.3332V12.0673L17.4665 14.2007L19.3332 12.334L13.9998 7.00065L8.6665 12.334L10.5332 14.2007L12.6665 12.0673V17.6673ZM3.33317 21.6673C2.59984 21.6673 1.97228 21.4064 1.4505 20.8847C0.927837 20.362 0.666504 19.734 0.666504 19.0007V3.00065C0.666504 2.26732 0.927837 1.63976 1.4505 1.11798C1.97228 0.595317 2.59984 0.333984 3.33317 0.333984H11.3332L13.9998 3.00065H24.6665C25.3998 3.00065 26.0278 3.26198 26.5505 3.78465C27.0723 4.30643 27.3332 4.93398 27.3332 5.66732V19.0007C27.3332 19.734 27.0723 20.362 26.5505 20.8847C26.0278 21.4064 25.3998 21.6673 24.6665 21.6673H3.33317ZM3.33317 3.00065V19.0007H24.6665V5.66732H12.8998L10.2332 3.00065H3.33317ZM3.33317 3.00065V19.0007V3.00065Z"
                                    fill="#00022F"
                                />
                            </svg>
                            <SectionSubtitle className="md:w-3/4 lg:w-auto">
                            Landlords and tenants align on pre-approved contracts.
                            </SectionSubtitle>
                        </div>
                        <div className="fade-in-scroll flex gap-8">
                        <svg
                                className="shrink-0 mt-2 md:mt-3"
                                width="28"
                                height="28"
                                viewBox="0 0 28 28"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <path
                                    d="M13.9865 0.666016C6.6265 0.666016 0.666504 6.63935 0.666504 13.9993C0.666504 21.3594 6.6265 27.3327 13.9865 27.3327C21.3598 27.3327 27.3332 21.3594 27.3332 13.9993C27.3332 6.63935 21.3598 0.666016 13.9865 0.666016ZM13.9998 24.666C8.1065 24.666 3.33317 19.8927 3.33317 13.9993C3.33317 8.10602 8.1065 3.33268 13.9998 3.33268C19.8932 3.33268 24.6665 8.10602 24.6665 13.9993C24.6665 19.8927 19.8932 24.666 13.9998 24.666ZM14.6665 7.33268H12.6665V15.3327L19.6665 19.5327L20.6665 17.8927L14.6665 14.3327V7.33268Z"
                                    fill="#00022F"
                                />
                            </svg>
                            <SectionSubtitle className="md:w-3/4 lg:w-auto">
                            Optimised procurement reduces cost per kWp.
                            </SectionSubtitle>
                        </div>
                        <div className="fade-in-scroll flex gap-8">
                            <svg
                               className="shrink-0 mt-2 md:mt-3"
                               width="29"
                               height="29"
                               viewBox="0 0 29 29"
                               fill="none"
                               xmlns="http://www.w3.org/2000/svg"
                           >
                               <path
                                   d="M19.3332 15.3327L28.6185 20.7487L24.6545 21.882L27.4878 26.79L25.1785 28.1234L22.3452 23.2167L19.3812 26.0833L19.3332 15.3327ZM16.6665 5.99935H19.3332V8.66602H25.9998C26.3535 8.66602 26.6926 8.80649 26.9426 9.05654C27.1927 9.30659 27.3332 9.64573 27.3332 9.99935V15.3327H24.6665V11.3327H11.3332V24.666H16.6665V27.3327H9.99984C9.64622 27.3327 9.30708 27.1922 9.05703 26.9422C8.80698 26.6921 8.6665 26.353 8.6665 25.9993V19.3327H5.99984V16.666H8.6665V9.99935C8.6665 9.64573 8.80698 9.30659 9.05703 9.05654C9.30708 8.80649 9.64622 8.66602 9.99984 8.66602H16.6665V5.99935ZM3.33317 16.666V19.3327H0.666504V16.666H3.33317ZM3.33317 11.3327V13.9993H0.666504V11.3327H3.33317ZM3.33317 5.99935V8.66602H0.666504V5.99935H3.33317ZM3.33317 0.666016V3.33268H0.666504V0.666016H3.33317ZM8.6665 0.666016V3.33268H5.99984V0.666016H8.6665ZM13.9998 0.666016V3.33268H11.3332V0.666016H13.9998ZM19.3332 0.666016V3.33268H16.6665V0.666016H19.3332Z"
                                   fill="#00022F"
                               />
                            </svg>
                            <div>
                                <SectionSubtitle className="md:w-3/4 lg:w-auto">
                                Trusted network of partner Tier 1 installers.
                                </SectionSubtitle>
                                <Body1 className="mt-2 lg:mt-4">
                                Our network of vetted, experienced installers get you operational safely and reliably. If you have a preferred EPC contractor, <a href="https://inrange.io/solution/epc" target="_blank" rel="noreferrer"><u>refer them to join</u></a> and perform your installations.
                                </Body1>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="flex-1">
                    <div className="fade-in-scroll flex flex-col justify-center bg-primary p-4 h-[500px]">
                        <div className="fade-in-scroll bg-primary flex flex-col justify-center gap-4 py-8">
                            <img className="fade-in-scroll object-contain" src={generationPotentialImg} alt="" />
                        </div>
                    </div>
                </div>
            </div>
        </PageSection>
    );
};

export default GenerationPotential;
