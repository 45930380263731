import {
  postPublicUserInteractionMessage,
  useBuildingsByBounds,
} from "InRangeAPI";
import AddressBar from "components/AddressBar";
import MapWithPolygons from "components/MapWithPolygons";
import { useEffect, useState } from "react";

const { timeZone, locale } = Intl.DateTimeFormat().resolvedOptions();

const AnalyseSiteMap = ({
  initialLatLong,
  buildingPoint,
  setBuildingPoint,
  setBuildingPostcodeAddress,
  setTotalBuildingArea,
  setBuildingKey,
  setValid,
  formValues,
}) => {
  const [currentAddress, setCurrentAddress] = useState("");
  const [mapBounds, setMapBounds] = useState();
  const [mapCenter, setMapCenter] = useState(buildingPoint);

  const [buildingsInBounds, setBuildingsInBounds] = useState({});
  const [selectedBuilding, setSelectedBuilding] = useState({});
  const [addressError, setAddressError] = useState(false);

  const fetchBuildingsInBounds = useBuildingsByBounds(mapBounds);

  const onSuggestionClick = (suggestion) => {
    if (!suggestion) {
      setAddressError(true);
      return;
    }

    if (suggestion.place_name === currentAddress) return;

    setCurrentAddress(suggestion.place_name);
    setAddressError(false);
    const center = suggestion.center;

    const lat = center[1];
    const long = center[0];
    const name = formValues?.firstName
      ? formValues.firstName + " " + formValues.lastName
      : undefined;
    setMapCenter([lat, long]);
    const data = {
      companyEmail: formValues?.companyEmail,
      name: name,
      address: suggestion.place_name,
      timezone: timeZone,
      language: locale,
    };
    postPublicUserInteractionMessage(data, "ADDRESS_ANALYSED");
  };

  useEffect(() => {
    if (!fetchBuildingsInBounds.data) return;

    // If we're too zoomed out, don't render billions of buildings
    const mapSizeMeters = mapBounds._northEast.distanceTo(mapBounds._southWest);
    if (mapSizeMeters > 7000) return;

    const buildingData = fetchBuildingsInBounds.data;
    const currentSelectedBuilding = selectedBuilding;
    const buildingKeys = Reflect.ownKeys(buildingData);

    const unselectedBuildings = {};
    buildingKeys.forEach((key) => {
      if (!currentSelectedBuilding[key])
        unselectedBuildings[key] = fetchBuildingsInBounds.data[key];
      else {
        setTotalBuildingArea(currentSelectedBuilding[key].surface_area_sqm);
        setBuildingKey(key);
      }
    });
    setBuildingsInBounds(unselectedBuildings);
  }, [
    fetchBuildingsInBounds.data,
    selectedBuilding,
    mapBounds,
    setTotalBuildingArea,
    setBuildingKey,
  ]);

  return (
    <div>
      <div className="flex w-full items-center z-20 relative max-w-[675px]">
        <AddressBar
          initialLatLong={initialLatLong}
          setPosition={setMapCenter}
          border={true}
          setError={setAddressError}
          suggestionHandler={onSuggestionClick}
          setCurrentAddress={setCurrentAddress}
        />
      </div>
      <div>
        {addressError && (
          <span className="text-error">Provide a valid address.</span>
        )}
      </div>
      <MapWithPolygons
        position={mapCenter}
        setBuildingPoint={setBuildingPoint}
        setBuildingPostcodeAddress={setBuildingPostcodeAddress}
        buildingsInBounds={buildingsInBounds}
        setMapBounds={setMapBounds}
        setValid={setValid}
        setSelectedBuilding={setSelectedBuilding}
        selectedBuilding={selectedBuilding}
        formValues={formValues}
      ></MapWithPolygons>
    </div>
  );
};

export default AnalyseSiteMap;
