import {
  buildPublicUserInteractionData,
  createPublicUserInteraction,
  postPublicUserInteractionMessage,
} from "InRangeAPI";
import dotsLoader from "assets/dotsLoader.gif";
import ClaimBuilding from "components/ClaimBuilding";
import RequestUpdate from "components/RequestUpdate";
import AnalyseStatBoxes from "components/composite/AnalyseStatBoxes";
import { useRef } from "react";
import styled from "styled-components";
import { large, medium } from "styles/breakpoints";
import RevealQuickAnalysisForm from "./RevealQuickAnalysisForm";

const RevealQuickAnalysis = ({
  buildingPoint,
  buildingPostcodeAddress,
  dataLoading,
  formSubmitted,
  setFormSubmitted,
  claimed,
  setClaimed,
  values,
  hidden,
  buildingKey,
  requestedUpdate,
  setRequestedUpdate,
  buildingValidity,
  formValues,
  setFormValues,
}) => {
  const validBuilding = buildingValidity.location && buildingValidity.size;
  const boxesRef = useRef(null);

  const handleSubmit = (newFormValues) => {
    const data = buildPublicUserInteractionData(
      newFormValues,
      buildingKey,
      buildingPoint,
      buildingPostcodeAddress,
      values
    );
    setFormValues(newFormValues);
    setFormSubmitted(true);
    postPublicUserInteractionMessage(data, "REVEAL_RESULTS");
    boxesRef.current.scrollIntoView({ behavior: "smooth" });
  };
  const handleRequestedUpdate = (newFormValues) => {
    const data = buildPublicUserInteractionData(
      newFormValues,
      buildingKey,
      buildingPoint,
      buildingPostcodeAddress,
      values
    );
    const companyEmail = newFormValues.companyEmail;
    const name = newFormValues.firstName + " " + newFormValues.lastName;
    const latitude = buildingPoint[0];
    const longitude = buildingPoint[1];
    const status = buildingValidity.size ? "INVALID_FOREIGN" : "INVALID_SIZE";
    setFormValues(newFormValues);
    setFormSubmitted(true);
    setRequestedUpdate(true);
    postPublicUserInteractionMessage(
      data,
      !buildingValidity.size ? "REQUEST_UPDATE_SMALL" : "REQUEST_UPDATE_FOREIGN"
    );
    createPublicUserInteraction(
      companyEmail,
      latitude,
      longitude,
      name,
      buildingKey,
      status
    ); // We create a public user here as the UI skips the request update step
    boxesRef.current.scrollIntoView({ behavior: "smooth" });
  };

  return (
    <div className="w-full px-2">
      <Body>
        {buildingKey ? (
          <p className="px-2 analyse:w-[480px] mb-3 analyse:mt-2">
            <b>Quick Analysis</b> ran using AI, satellite images, demand
            profiles, and financial models.
          </p>
        ) : (
          <p className="px-2 analyse:w-[480px] mb-3 analyse:mt-2">
            Select a building on the map for a Quick Analysis.
          </p>
        )}
        <div ref={boxesRef}>
          <AnalyseStatBoxes values={values} hidden={hidden}></AnalyseStatBoxes>
        </div>
        {buildingKey &&
          (dataLoading ? (
            <div className="flex items-center w-full justify-center analyse:justify-normal">
              <img
                src={dotsLoader}
                className="md:ml-[135px]"
                height={"200px"}
                width={"200px"}
                alt="loading..."
              />
            </div>
          ) : (
            <>
              {validBuilding ? (
                <>
                  {formSubmitted ? (
                    <div className="px-2 analyse:w-[480px]">
                      <p className="mt-3">Results revealed!</p>
                      <ClaimBuilding
                        buildingPoint={buildingPoint}
                        formValues={formValues}
                        values={values}
                        claimed={claimed}
                        setClaimed={setClaimed}
                        buildingKey={buildingKey}
                        buildingPostcodeAddress={buildingPostcodeAddress}
                      />
                    </div>
                  ) : (
                    <div className="px-2 analyse:w-[480px]">
                      <p className="mt-3">
                        Provide your contact info to reveal even more.
                      </p>
                      <RevealQuickAnalysisForm
                        handleSubmit={handleSubmit}
                        validateOnChange={true}
                        submitText="Reveal more results"
                      />
                    </div>
                  )}
                </>
              ) : (
                <div className="px-2 analyse:w-[480px]">
                  <p className="mt-3">
                    InRange does not currently support this building type or
                    location.
                  </p>
                  {formSubmitted ? (
                    <RequestUpdate
                      buildingPoint={buildingPoint}
                      requestedUpdate={requestedUpdate}
                      setRequestedUpdate={setRequestedUpdate}
                      formValues={formValues}
                      buildingKey={buildingKey}
                      buildingValidity={buildingValidity}
                      buildingPostcodeAddress={buildingPostcodeAddress}
                    />
                  ) : (
                    <>
                      <p className="mt-3">
                        Leave your contact info and we’ll update you when we
                        support it. In the meantime, you can try another
                        building.
                      </p>
                      <RevealQuickAnalysisForm
                        handleSubmit={handleRequestedUpdate}
                        validateOnChange={true}
                        submitText="Let me know!"
                      />
                    </>
                  )}
                </div>
              )}
            </>
          ))}
      </Body>
    </div>
  );
};

const Body = styled.div`
  font-size: 14px;
  line-height: 1.4;
  font-style: normal;
  font-weight: 400;
  ${(props) =>
    props.color &&
    `
    color: ${props.color};
  `}
  ${medium(`
    font-size: 15px;
  `)}
    ${large(`
    font-size: 18px;
  `)}
`;

export default RevealQuickAnalysis;
