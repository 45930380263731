import React, { useLayoutEffect, useRef } from 'react';
import PageSection from 'components/PageSection';
import { Body1, SectionTitle } from 'components/Typography';
import investment4 from 'assets/images/investment4.png';
import investment5 from 'assets/images/investment5.png';
import { useGsapContext } from 'gsapContext';

const SolutionSectionFour = () => {
    const $context = useRef();
    const { gsap } = useGsapContext();

    useLayoutEffect(() => {
        let ctx = gsap.context(() => {
            const timeline = gsap.timeline({
                scrollTrigger: {
                    trigger: $context.current,
                    start: 'top center-=100',
                    end: 'top top',
                    toggleActions: 'restart none none reverse'
                }
            });
            timeline.fromTo('.image-4', { opacity: 0 }, { opacity: 1, duration: 0.2, delay: 0.3 });
            timeline.fromTo('.image-5', { opacity: 0 }, { opacity: 1, duration: 0.2 });
        }, $context);

        return () => ctx.revert();
        // eslint-disable-next-line
    }, []);

    return (
        <PageSection ref={$context} color="neutral" containerClass="flex flex-col lg:flex-row gap-4 lg:gap-16 py-32">
            <div className="w-full lg:w-3/5">
                <SectionTitle className="fade-in-scroll">Engage your tenants at scale</SectionTitle>
                <Body1 className="fade-in-scroll pt-4 pb-8 lg:max-w-xl">
                Make instant, competitive tariff offers to your tenants with InRange's automated tariff engine.
                </Body1>
                <Body1 className="fade-in-scroll lg:max-w-xl">
                Rely on institutionally-accepted PPAs and lease variations to streamline tenant alignment.
                <br/> <br/>
                Enjoy accelerated tenant engagements with tenants already on the InRange platform.
                </Body1>
            </div>
            <div className="relative w-full lg:w-2/5">
                <div className="relative flex flex-col items-end h-full py-16">
                    <div className="relative md:mr-[5vw] lg:mr-0 image-5 w-56 left-4 md:left-0 md:w-[30rem] lg:w-96 z-20">
                        <img src={investment4} alt="" />
                    </div>
                    <div className="absolute md:mr-[5vw] lg:mr-0 image-4 w-56 md:w-[30rem] lg:w-96 top-32 lg:top-[50%] right-24 md:right-72 lg:right-auto lg:-left-16 lg:translate-y-[-20%] z-10">
                        <img src={investment5} alt="" />
                    </div>
                </div>
            </div>
        </PageSection>
    );
};

export default SolutionSectionFour;
