import InitialAnimation from "components/InitialAnimation";
import React from "react";
import BackedBy from "./BackedBy";
import BannerAnalyse from "./BannerAnalyse";
import Capacity from "./Capacity";
import Hero from "./Hero";
import HowItWorks from "./HowItWorks";
import JoinUs from "./JoinUs";
import Partners from "./Partners";

function HomePage() {
  return (
    <>
      <Hero />
      <Partners />
      <BannerAnalyse />
      <HowItWorks />
      <Capacity />
      <BackedBy />
      <JoinUs />
      <InitialAnimation />
    </>
  );
}

export default HomePage;
