import React from 'react';
import PageSection from 'components/PageSection';
import { SectionTitle } from 'components/Typography';
import appConfig from 'config/appConfig';
import Button from 'components/Button';
import { Link } from 'react-router-dom';
import { paths } from 'constants';

const Feedback = () => {
    return (
        <PageSection
            color="inverted"
            element="main"
            className={`pb-16 lg:pb-32 border-b-1 border-primary-300 ${
                appConfig.layout.header.fixed ? 'pt-12 lg:pt-16' : 'pt-4 lg:pt-8'
            }`}
            containerClass="flex flex-col md:flex-row gap-16"
        >
            <div className="flex-1 flex flex-col items-center md:items-start lg:items-center gap-4">
                <SectionTitle className="text-white">Thanks for your feedback</SectionTitle>
                <p className="text-center md:text-left lg:text-center">
                    We've collected your response, and appreciate your insight!
                </p>
                <Link to={paths.home}>
                    <Button color="secondary" element="div">
                        Go to homepage
                    </Button>
                </Link>
            </div>
        </PageSection>
    );
};

export default Feedback;
