import React from 'react';
import PageSection from 'components/PageSection';
import distributedEnergyImg1 from 'assets/images/distributed-energy-1.png';
import distributedEnergyImg2 from 'assets/images/distributed-energy-2.png';
import { Body1, SectionTitle } from 'components/Typography';
import { Link } from 'react-router-dom';
import { paths } from 'constants';

const DistributedEnergy = () => {
    return (
        <PageSection>
            <div className="flex flex-col lg:flex-row gap-8 lg:items-center">
                <div className="flex-1">
                    <SectionTitle className="fade-in-scroll mb-8">
                    Enterprise power procurement
                    </SectionTitle>
                    <Body1 className="fade-in-scroll mb-4">
                    InRange is true enterprise power procurement: Instead of individual right-sized projects with consultants and developers, we provide a tech-enabled unified point of access for all personas to make decisions and execute installations at scale.
                    <br/> <br/> That's why landlords, tenants, and data centres rely on the InRange platform with their scalable renewable energy procurement, and top-tier EPC contractors collaborate with InRange on superior installations.
                    </Body1>
                </div>

                <div className="flex-1">
                    <div className="w-full overflow-x-scroll lg:overflow-hidden pb-4 lg:pb-0">
                        <div className="flex items-center gap-4 pt-16 w-[160vw] md:w-[120vw] lg:w-full">
                            <div className="lg:flex-1 basis-1/3">
                                <Link className="block w-full" to={paths.requestDemo}>
                                    <img
                                        className="fade-in-scroll w-full object-contain"
                                        src={distributedEnergyImg1}
                                        alt=""
                                    />
                                </Link>
                            </div>
                            <div className="flex-1 flex lg:flex-col gap-4">
                                <div className="flex-1">
                                    <Link className="block w-full" to={paths.requestDemo}>
                                        <img
                                            className="fade-in-scroll w-full object-contain"
                                            src={distributedEnergyImg2}
                                            alt=""
                                        />
                                    </Link>
                                </div>
                                <div className="flex-1">
                                    <Link className="block w-full" to={paths.requestDemo} />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </PageSection>
    );
};

export default DistributedEnergy;
