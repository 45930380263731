import React from 'react';
import PageSection from 'components/PageSection';
import { SectionTitle } from 'components/Typography';
import notFoundImg from 'assets/images/notfound-img.png';
import appConfig from 'config/appConfig';
import Button from 'components/Button';
import { Link } from 'react-router-dom';
import { paths } from 'constants';

const NotFound = () => {
    return (
        <PageSection
            color="inverted"
            element="main"
            className={`pb-16 lg:pb-32 border-b-1 border-primary-300 ${
                appConfig.layout.header.fixed ? 'pt-12 lg:pt-16' : 'pt-4 lg:pt-8'
            }`}
            containerClass="flex flex-col md:flex-row gap-16"
        >
            <div className="flex-1 flex flex-col items-center md:items-start lg:items-center gap-4">
                <SectionTitle className="text-white">Oops!</SectionTitle>
                <p className="text-9xl md:text-[150px] lg:text-[250px] md:leading-[150px] lg:leading-[250px] font-bold text-secondary">
                    404
                </p>
                <SectionTitle className="text-white">Page Not Found!</SectionTitle>
                <p className="text-center md:text-left lg:text-center">
                    The page you are looking for might have been removed had its name changed or is temporarily
                    unavailable.
                </p>
                <Link to={paths.home}>
                    <Button color="secondary" element="div">
                        Go Back
                    </Button>
                </Link>
            </div>
            <div className="flex-1 hidden md:flex items-center justify-center">
                <img src={notFoundImg} alt="" />
            </div>
        </PageSection>
    );
};

export default NotFound;
