import React, { useLayoutEffect, useRef } from "react";
import PageSection from "components/PageSection";
import { Body1, Title1 } from "components/Typography";
import appConfig from "config/appConfig";
import AnalyseSite from "components/composite/AnalyseSite";
import { useGsapContext } from "gsapContext";

const EnergyBuyerSolutionHeader = () => {
  const $context = useRef();
  const { gsap } = useGsapContext();

  useLayoutEffect(() => {
    let ctx = gsap.context(() => {
      const timeline = gsap.timeline({ delay: 0.3 });
      timeline.fromTo(
        ".bottom-fade-in",
        { opacity: 0, y: "50px" },
        { opacity: 1, y: 0, duration: 0.2 }
      );
      timeline.fromTo(
        ".bottom-fade-in-2",
        { opacity: 0, y: "50px" },
        { opacity: 1, y: 0, duration: 0.2 }
      );
      timeline.fromTo(
        ".bottom-fade-in-3",
        { opacity: 0, y: "50px" },
        { opacity: 1, y: 0, duration: 0.2 }
      );
    }, $context);

    return () => ctx.revert();
    // eslint-disable-next-line
  }, []);

  return (
    <PageSection
      ref={$context}
      element="main"
      className={`pb-16 lg:pb-32 ${
        appConfig.layout.header.fixed ? "pt-24 lg:pt-32" : "pt-16 lg:pt-20"
      }`}
      containerClass="flex flex-col lg:flex-row gap-16"
    >
      <div className="w-full lg:w-1/2">
        <Title1 className="bottom-fade-in">
        Supercharge your energy procurement, cleanly.
        </Title1>
        <Body1 className="bottom-fade-in-2 mt-6 lg:mt-10 mb-6">
        InRange streamlines and automates the end-to-end energy procurement process to unlock savings across your entire portfolio.<br/> <br/>
        Maximise protection from volatile energy markets as part of the InRange network of renewable energy assets. <br/>Start for free.
        </Body1>
        <div className="bottom-fade-in-3">
          <AnalyseSite buttonColor={"primary"} border={true}></AnalyseSite>
        </div>
      </div>
    </PageSection>
  );
};

export default EnergyBuyerSolutionHeader;
