import React, { useLayoutEffect, useRef } from "react";
import styled from "styled-components";
import PageSection from "components/PageSection";
import { Body1, Title1 } from "components/Typography";
import { useGsapContext } from "gsapContext";
import { large, medium } from "styles/breakpoints";
import { HashLink } from "react-router-hash-link";

const StyledPrivacyPolicy = styled.div`
    a {
        text-decoration: underline;
    }
    ol {
        padding-left: 2rem;
        counter-reset: item;
        & li {
            display: block;
            &:before {
                content: counters(item, ".") ".  ";
                counter-increment: item;
            }
        }
    }
    ul {
        list-style: disc;
        padding-left: 1rem;
    }
    p,
    li {
        margin: 0.5rem 0;
        font-size: 14px;
        ${medium(`
            font-size: 16px;
        `)}
        ${large(`
            font-size: 18px;
        `)}
    }

    & .anchor {
        scroll-margin-top: calc(1rem + 114px);
    }
`;

const PrivacyPolicyPage = () => {
    const $context = useRef();
    const { gsap } = useGsapContext();

    useLayoutEffect(() => {
        let ctx = gsap.context(() => {
            const timeline = gsap.timeline();
            timeline.fromTo(".bottom-fade-in-1", { opacity: 0, y: "50px" }, { opacity: 1, y: 0, duration: 0.2 });
            timeline.fromTo(".bottom-fade-in-2", { opacity: 0, y: "50px" }, { opacity: 1, y: 0, duration: 0.2 });
        }, $context);

        return () => ctx.revert();
        // eslint-disable-next-line
    }, []);

    return (
        <PageSection
            ref={$context}
            className="relative z-10 bg-white"
            containerClass="flex flex-col items-center md:w-7/12 py-16 md:pb-32 lg:py-24"
            element="main"
        >
            <Title1 className="bottom-fade-in-1 text-neutral-700 text-center mb-8">Privacy Policy</Title1>
            <Body1 className="bottom-fade-in-2 text-center mb-12">
                Your privacy is important to us at InRange. We respect your privacy regarding any information we may
                collect from you across our website.
            </Body1>

            <StyledPrivacyPolicy>
                <p className="font-semibold">Overview - the key information you should be aware of</p>
                <p className="font-semibold">(A) . Who we are</p>
                <p>
                    We are InRange Ltd, an enterprise electricity provider for business customers. We have created a
                    platform using artificial intelligence to understand, forecast and optimize energy generation and
                    consumption within local energy markets (the "Platform"). Our Platform enables commercial businesses
                    to develop an energy procurement strategy, designed to hedge risk from market volatility to assess
                    how much renewable energy could be generated from their commercial buildings. Our company number is
                    13133674 and our registered office is at 128 City Road, London, EC1V 2NX. InRange Ltd is the
                    controller of your personal information, and is responsible for your personal information. All
                    references in this policy to "InRange", "our", "us" or "we" refer to InRange Ltd. All references in
                    this policy to "our website", refer to the website owned and operated by InRange at
                    https://www.inrange.io/.
                </p>
                <p>
                    <span className="font-semibold">(B). Our values and what this policy is for:</span>
                    We value your privacy and want to be accountable and fair to you as well as transparent with you in
                    the way that we collect and use your personal information. We also want you to know your rights in
                    relation to your information which you can find <HashLink to="#your-rights">here</HashLink>.
                </p>
                <p>
                    In line with these values, this privacy policy tells you what to expect when we collect and use
                    personal information about you. We have tried to make it easy for you to navigate so you can find
                    the information that is most relevant to you and our relationship with you.
                </p>
                <p>
                    <span className="font-semibold">(C) . Who this policy applies to:</span> This policy applies to:
                </p>
                <ol>
                    <li>
                        Visitors to our website e.g., customers and prospective customers who contact us with enquiries
                        or submit a "demo request";
                    </li>
                    <li>Users of our Platform; and</li>
                    <li>our suppliers, and employees of our suppliers.</li>
                </ol>
                <p>
                    <span className="font-semibold">(D) . What this policy contains:</span>
                    This privacy policy describes the following important topics relating to your information (you can
                    click on the links to find out more):
                </p>
                <ol>
                    <HashLink to="#how-we-obtain-personal-information">
                        <li>How we obtain your personal information</li>
                    </HashLink>
                    <HashLink to="#collection-personal-information">
                        <li>Collection of your personal information and how we use it </li>
                    </HashLink>
                    <HashLink to="#legal-basis-personal-information">
                        <li>Our legal basis for using your personal information </li>
                    </HashLink>
                    <HashLink to="#sharing-personal-information">
                        <li>How and why we share your personal information with others </li>
                    </HashLink>
                    <HashLink to="#storing-perosnal-information">
                        <li>How long we store your personal information </li>
                    </HashLink>
                    <HashLink to="#your-rights">
                        <li>Your rights </li>
                    </HashLink>
                    <HashLink to="#marketing">
                        <li>Marketing </li>
                    </HashLink>
                    <HashLink to="#transfer-personal-information">
                        <li>Where we may transfer your personal information </li>
                    </HashLink>
                    <HashLink to="#risks-and-security">
                        <li>Risks and how we keep your personal information secure </li>
                    </HashLink>
                    <HashLink to="#other-websites">
                        <li>Links to other websites </li>
                    </HashLink>
                    <HashLink to="#changes-this-privacy-policy">
                        <li>Changes to this privacy policy and</li>
                    </HashLink>
                    <HashLink to="#further-questions">
                        <li>Further questions and how to make a complaint.</li>
                    </HashLink>
                </ol>
                <p>
                    <span className="font-semibold">(E) . Your rights to object:</span>
                    You have various rights in respect of our use of your personal information as set out in{" "}
                    <HashLink to="#your-rights">section 6</HashLink>. Two of the fundamental rights to be aware of are
                    that:
                </p>
                <ol>
                    <li>
                        you may ask us to stop using your personal information for direct-marketing purposes. If you
                        exercise this right, we will stop using your personal information for this purpose.
                    </li>
                    <li>
                        you may ask us to consider any valid objections which you have to our use of your personal
                        information where we process your personal information on the basis of our, or another person's,
                        legitimate interest.
                    </li>
                </ol>
                <p>
                    You can find out more information in <HashLink to="#your-rights">section 6</HashLink>.
                </p>
                <p>
                    <span className="font-semibold">(F). What you need to do and your confirmation to us:</span>
                    Please read this privacy policy carefully to understand how we handle your personal information. By
                    engaging with us in the ways set out in this privacy policy, you confirm that you have read and
                    understood the entirety of this privacy policy, as it applies to you.
                </p>
                <p className="font-semibold">The detail - the key information you should be aware of</p>
                <ol>
                    <li id="how-we-obtain-personal-information" className="anchor font-semibold">
                        How we obtain your personal information
                        <ol className="font-normal">
                            <li>
                                You may provide us with your personal information voluntarily. We may also receive
                                information about you from third parties such as marketing agencies, market research
                                companies, our suppliers and public agencies, which we refer to as "third party sources"
                                or "suppliers" throughout this policy.
                            </li>
                            <li>
                                You may give us personal information about yourself by using the "demo request form"
                                provided on our website, by registering for an account on the Platform, or by contacting
                                us by phone, email or other means. If you are a supplier, you may also give us personal
                                information about you when you are offering or providing services to us.
                            </li>
                        </ol>
                    </li>
                    <li id="collection-personal-information" className="anchor font-semibold">
                        Collection of your personal information and how we use it
                        <ol className="font-normal">
                            <li className="font-semibold">
                                We, or third parties on our behalf, may collect and use any of the following information
                                about you:
                                <p className="font-normal pl-12">i. your full name including your title;</p>
                                <p className="font-normal pl-12">ii. your company's postal address;</p>
                                <p className="font-normal pl-12">
                                    iii. your company's full address (where it is different to the postal address);
                                </p>
                                <p className="font-normal pl-12">iv. your company email address;</p>
                                <p className="font-normal pl-12">v. your company telephone and/or mobile number;</p>
                                <p className="font-normal pl-12">
                                    vi. information provided by you when you correspond with us;
                                </p>
                                <p className="font-normal pl-12">vii. any updates to information provided to us;</p>
                                <p className="font-normal pl-12">
                                    viii. information needed to provide the Platform to you including account details,
                                    Platform user name and password, order details, order history and payment details;
                                </p>
                                <p className="font-normal pl-12">ix. customer services information;</p>
                                <p className="font-normal pl-12">
                                    x. customer relationship management and marketing information;
                                </p>
                                <p className="font-normal pl-12">
                                    xi. information you provide to help us provide you with an improved Platform, for
                                    example if we ask you to fill in a survey or questionnaire; and
                                </p>
                                <p className="font-normal pl-12">
                                    xii. the following informmation created and recorded automatically when you visit
                                    our website or use our Platform:
                                </p>
                                <p className="font-normal pl-24">
                                    <span className="font-semibold">a. Technical information.</span>
                                    This includes: the Internet Protocol (IP) address used to connect your computer to
                                    the internet address; the website address and country from which you access
                                    information; the files requested; browser type and version; browser plug-in types
                                    and versions; operating system; and platform. We use this personal information to
                                    administer our website and Platform, to measure the efficiency of our systems and to
                                    undertake an analysis on the locations from which people access our webpages; and
                                </p>
                                <p className="font-normal pl-24">
                                    <span className="font-semibold">
                                        b. Information about your visit and your behaviour on our website and Platform
                                    </span>
                                    (for example, the pages that you click on). This may include the website you visit
                                    before and after visiting our website (including date and time), time and length of
                                    visits to certain pages, page interaction information (such as scrolling, clicks,
                                    and mouse-overs), methods used to browse away from the page, traffic data, location
                                    data, weblogs and other communication data and information provided when requesting
                                    further service or downloads.
                                </p>
                            </li>
                            <li className="font-semibold">
                                We will collect, use and store the personal information listed above for the following
                                reasons:
                                <ol className="font-normal">
                                    <p> i. to allow you to access and use our website or Platform;</p>
                                    <p>
                                        ii. to receive enquiries and "demo requests" from you through the website or
                                        Platform;
                                    </p>
                                    <p>
                                        iii. for improvement and maintenance of our website and Platform, and to provide
                                        technical support for our website and Platform;
                                    </p>
                                    <p>iv. to ensure the security of our website and Platform;</p>
                                    <p>
                                        v. to send you certain communications about our Platform such as administrative
                                        messages (for example, setting out changes to our terms and conditions and
                                        keeping you informed about our fees and charges), or for business development
                                        and marketing purposes; and
                                    </p>
                                    <p>
                                        vi. to recognise you and your business when you return to our website or
                                        Platform, to store information about your preferences, and to allow us to
                                        customise the website according to your businesses interests;
                                    </p>
                                    <p>
                                        vii. to evaluate your visit to our website or Platform, and to prepare reports
                                        or compile statistics to understand the type of people and businesses who use
                                        our website and Platform, how they use our website and Platform and to make our
                                        website and Platform more intuitive. Such details will be anonymised as far as
                                        reasonably possible and you will not be identifiable from the information
                                        collected; and
                                    </p>
                                    <p>
                                        viii. where you are a supplier, to enable us to purchase and receive products
                                        and services from you (including supplier due diligence, payment and expense
                                        reporting and financial audits).
                                    </p>
                                </ol>
                            </li>
                            <li id="cookies-policy" className="anchor font-semibold">
                                A word about cookies
                                <ol className="font-normal">
                                    <p>
                                        i. Some pages on our website and Platform use cookies, which are small files
                                        placed on your internet browser when you visit our website. We use cookies in
                                        order to offer you a more tailored experience in the future, by understanding
                                        and remembering your particular browsing preferences.
                                    </p>
                                    <p>
                                        ii. Where we use cookies on our website and Platform, you may block these at any
                                        time. To do so, you can activate the setting on your browser that allows you to
                                        refuse the setting of all or some cookies. However, if you use your browser
                                        settings to block all cookies (including essential cookies), you may not be able
                                        to access all or parts of our website or to use all the functionality provided
                                        through our website.
                                    </p>
                                    <p>
                                        iii. For detailed information on the cookies we use and the purposes for which
                                        we use them, please refer to our{" "}
                                        <HashLink to="#cookies-policy">cookies policy here</HashLink>.
                                    </p>
                                </ol>
                            </li>
                            <li> Special categories of data. We do not collect special categories of data.</li>
                            <li>
                                Information we need to provide services to you. We need certain types of personal
                                information so that we can provide services to you and perform contractual and other
                                legal obligations that we have to you. If you do not provide us with such personal
                                information, or if you ask us to delete it, you may no longer be able to access our
                                products and services.
                            </li>
                            <li>
                                Whatever our relationship with you is, we may also collect, use and store your personal
                                information for the following additional reasons:
                                <p className="pl-12">
                                    (a) to deal with any enquiries or issues you have about how we collect, store and
                                    use your personal information, or any requests made by you for a copy of the
                                    information we hold about you. If we do not have a contract with you, we may process
                                    your personal information for these purposes where it is in our legitimate interests
                                    for customer services purposes;
                                </p>
                                <p className="pl-12">
                                    (b) for internal corporate reporting, business administration, ensuring adequate
                                    insurance coverage for our business, ensuring the security of company facilities,
                                    research and development, and to identify and implement business efficiencies. We
                                    may process your personal information for these purposes where it is in our
                                    legitimate interests to do so;
                                </p>
                                <p className="pl-12">
                                    ( c ) cto comply with any procedures, laws and regulations which apply to us - this
                                    may include where we reasonably consider it is in our legitimate interests or the
                                    legitimate interests of others to comply, as well as where we are legally required
                                    to do so; and
                                </p>
                                <p className="pl-12">
                                    (d) to establish, exercise or defend our legal rights - this may include where we
                                    reasonably consider it is in our legitimate interests or the legitimate interests of
                                    others, as well as where we are legally required to do so.
                                </p>
                            </li>
                        </ol>
                    </li>
                    <li id="legal-basis-personal-information" className="anchor font-semibold">
                        Legal basis for use of your personal information
                        <ol className="font-normal">
                            <li>
                                We consider that the legal bases for using your personal information as set out in this
                                privacy policy are as follows:
                                <p className="pl-12">
                                    (a) our use of your personal information is necessary to perform our obligations
                                    under any contract with you (for example, to provide our products and services to
                                    you, to comply with the terms of use of our website which you accept by browsing our
                                    website and/or to comply with our contract to provide you access to and use of our
                                    Platform); or
                                </p>
                                <p className="pl-12">
                                    (b) our use of your personal information is necessary for complying with our legal
                                    obligations; or
                                </p>
                                <p className="pl-12">
                                    ( c ) where neither (a) nor (b) apply, use of your personal information is necessary
                                    for our legitimate interests or the legitimate interests of others (for example, to
                                    ensure the security of our website). Our legitimate interests are to:
                                    <p className="pl-12">(i) run, grow and develop our</p>
                                    <p className="pl-12">(ii) Platform;operate our website;</p>
                                    <p className="pl-12">
                                        (iii) carry out marketing, market research and business development; and
                                    </p>
                                    <p className="pl-12">(iv) and</p>
                                    <p className="pl-12">(v) for internal business administration purposes.</p>
                                </p>
                            </li>
                            <li>
                                If we rely on our (or another person's) legitimate interests for using your personal
                                information, we will undertake a balancing test to ensure that our (or the other
                                person's) legitimate interests are not outweighed by your interests or fundamental
                                rights and freedoms which require protection of the personal information.
                            </li>
                            <li>
                                We may process your personal information in some cases for marketing purposes on the
                                basis of your consent (which you may withdraw at any time after giving it, as described
                                below).
                            </li>
                            <li>
                                If we rely on your consent for us to use your personal information in a particular way,
                                but you later change your mind, you may withdraw your consent by contacting us at
                                dataprotection@inrange.io and we will stop doing so. However, if you withdraw your
                                consent, this may impact the ability for us to be able to provide our Platform to you
                                and any associated services.
                            </li>
                        </ol>
                    </li>
                    <li id="sharing-personal-information" className="anchor font-semibold">
                        How and why we share your personal information with others
                        <ol className="font-normal">
                            <li>
                                We will share your personal information with the following third parties or categories
                                of third parties:
                                <p className="pl-12">(a) Amazon AWS, who host our website;</p>
                                <p className="pl-12">
                                    (b)CRM platform providers who hosts our document and email solution and may access
                                    personal data stored within that solution when providing support and maintenance
                                    services;
                                </p>
                                <p className="pl-12">
                                    ( c ) we may share anonymised and aggregated statistical information with our
                                    suppliers to demonstrate what interest there has been in any marketing campaigns we
                                    have assisted our suppliers in carrying out;
                                </p>
                                <p className="pl-12">
                                    (d) our other service providers and sub-contractors, including payment processors,
                                    utility providers, suppliers of technical and support services, insurers, logistic
                                    providers, and cloud service providers;
                                </p>
                                <p className="pl-12">
                                    (e) companies that assist in our marketing, advertising and promotional activities;
                                    and
                                </p>
                                <p className="pl-12">
                                    (f) analytics and search engine providers that assist us in the improvement and
                                    optimisation of our website.
                                </p>
                            </li>
                            <li>
                                Any third parties with whom we share your personal information are limited (by law and
                                by contract) in their ability to use your personal information for any purpose other
                                than to provide services for us.
                            </li>
                            <li>
                                We will also disclose your personal information to third parties:
                                <p className="pl-12">
                                    (a) where it is in our legitimate interests to do so to run, grow and develop our
                                    business; for example, if we sell or buy any business or assets, we may disclose
                                    your personal information to the prospective seller or buyer of such business or
                                    assets;
                                </p>
                                <p className="pl-12">
                                    (b) if we are under a duty to disclose or share your personal information in order
                                    to comply with any legal obligation, any lawful request from government or law
                                    enforcement officials and as may be required to meet national security or law
                                    enforcement requirements or prevent illegal activity;
                                </p>
                                <p className="pl-12">
                                    ( c ) in order to enforce or apply our terms of use, our terms and conditions for
                                    customers or any other agreement or to respond to any claims, to protect our rights
                                    or the rights of a third party, to protect the safety of any person or to prevent
                                    any illegal activity; or
                                </p>
                                <p className="pl-12">
                                    (d) to protect the rights, property, or safety of InRange, our staff, our customers
                                    or other persons. This may include exchanging personal information with other
                                    organisations for the purposes of fraud protection.
                                </p>
                            </li>
                            <li>
                                We may also disclose and use anonymised, aggregated reporting and statistics about users
                                of our website or Platform for the purpose of internal reporting or reporting to third
                                parties, and for our marketing and promotion purposes. None of these anonymised,
                                aggregated reports or statistics will enable our users to be personally identified.
                            </li>
                        </ol>
                    </li>
                    <li id="storing-perosnal-information" className="anchor font-semibold">
                        How long we store your personal information
                        <p className="font-normal">
                            We keep your personal information for no longer than necessary for the purposes for which
                            the personal information is used by us (as described in this privacy policy). The length of
                            time for which we retain personal information depends on the purposes for which we collect
                            and use it and/or as required to comply with applicable laws and to establish, exercise or
                            defend our legal rights.
                        </p>
                    </li>
                    <li id="your-rights" className="anchor font-semibold">
                        Your rights
                        <ol className="font-normal">
                            <li>
                                You have certain rights in relation to your personal information. If you would like
                                further information in relation to these or would like to exercise any of them, please
                                contact us via email at dataprotection@inrange.io at any time. You have the following
                                rights:
                                <p className="pl-12">
                                    (a) <span className="font-semibold">Right of access.</span> You have a right of
                                    access to any personal information we hold about you. You can ask us for a copy of
                                    your personal information; confirmation as to whether your personal information is
                                    being used by us; details about how and why it is being used; and details of the
                                    safeguards which are in place if we transfer your information outside of the United
                                    Kingdom.
                                </p>
                                <p className="pl-12">
                                    (b) <span className="font-semibold"> Right to update your information.</span> You
                                    have a right to request an update to any of your personal information which is out
                                    of date or incorrect.
                                </p>
                                <p className="pl-12">
                                    ( c ) <span className="font-semibold">Right to delete your information.</span> You
                                    have a right to ask us to delete any personal information which we are holding about
                                    you in certain specific circumstances. You can ask us for further information on
                                    these specific circumstances by contacting us using the details in{" "}
                                    <HashLink to="#further-questions">section 12</HashLink>.
                                    <br />
                                    We will pass your request onto other recipients of your personal information unless
                                    that is impossible or involves disproportionate effort. You can ask us who the
                                    recipients are, using the contact details in{" "}
                                    <HashLink to="#further-questions">section 12</HashLink>.
                                </p>
                                <p className="pl-12">
                                    (d)
                                    <span className="font-semibold"> Right to restrict use of your information: </span>
                                    You have a right to ask us to restrict the way that we process your personal
                                    information in certain specific circumstances. You can ask us for further
                                    information on these specific circumstances by contacting us using the details in
                                    <HashLink to="#further-questions">section 12</HashLink>.
                                    <p>
                                        We will pass your request onto other recipients of your personal information
                                        unless that is impossible or involves disproportionate effort. You can ask us
                                        who the recipients are using the contact details in{" "}
                                        <HashLink to="#further-questions">section 12</HashLink>.
                                    </p>
                                </p>
                                <p className="pl-12">
                                    (e) <span className="font-semibold">Right to stop marketing:</span> You have a right
                                    to ask us to stop using your personal information for direct marketing purposes. If
                                    you exercise this right, we will stop using your personal information for this
                                    purpose.
                                </p>
                                <p className="pl-12">
                                    (f) <span className="font-semibold">Right to data portability:</span> You have a
                                    right to ask us to provide your personal information to a third party provider of
                                    services.
                                    <br />
                                    This right only applies where we use your personal information on the basis of your
                                    consent or performance of a contract; and where our use of your information is
                                    carried out by automated means.
                                </p>
                                <p className="pl-12">
                                    (g) <span className="font-semibold">Right to object.</span> You have a right to ask
                                    us to consider any valid objections which you have to our use of your personal
                                    information where we process your personal information on the basis of our or
                                    another person's legitimate interest.
                                </p>
                            </li>
                            <li>
                                We will consider all such requests and provide our response within a reasonable period
                                (and in any event within one month of your request unless we tell you we are entitled to
                                a longer period under applicable law). Please note, however, that certain personal
                                information may be exempt from such requests in certain circumstances, for example if we
                                need to keep using the information to comply with our own legal obligations or to
                                establish, exercise or defend legal claims.
                            </li>
                            <li>
                                If an exception applies, we will tell you this when responding to your request. We may
                                request you provide us with information necessary to confirm your identity before
                                responding to any request you make.
                            </li>
                        </ol>
                    </li>
                    <li id="marketing" className="anchor font-semibold">
                        Marketing
                        <ol className="font-normal">
                            <li>
                                We may collect and use your personal information for undertaking marketing by email,
                                telephone and on social media (for example, LinkedIn).
                            </li>
                            <li>
                                We may send you certain marketing communications (including electronic marketing
                                communications) if it is in our legitimate interests to do so for marketing and business
                                development purposes or, if you are a sole trader or a non-limited liability partnership
                                and we need to obtain your consent, if you have consented to receive such electronic
                                marketing information.
                            </li>
                            <li>
                                If you wish to stop receiving marketing communications, you can contact us by email at
                                dataprotection@inrange.io.
                            </li>
                        </ol>
                    </li>
                    <li id="transfer-personal-information" className="anchor font-semibold">
                        Where we may transfer your personal information outside of the UK
                        <ol className="font-normal">
                            <li>
                                Your personal information may be used, stored and/or accessed by staff operating outside
                                the UK working for us or our suppliers. Further details on to whom your personal
                                information may be disclosed are set out in section 4.
                            </li>
                            <li>
                                If we provide any personal information about you to any such non-UK suppliers, we will
                                take appropriate measures to ensure that the recipient protects your personal
                                information adequately in accordance with this privacy policy. These measures may
                                include the following:
                                <p className="pl-12">
                                    (a) ensuring that there is an adequacy decision by the UK Government in the case of
                                    transfers out of the UK which means that the recipient country is deemed to provide
                                    adequate protection for such personal data; or
                                </p>
                                <p className="pl-12">
                                    (b)where we use certain providers, we may use specific contracts approved for use in
                                    the UK which give personal data the same protection it has in the UK;
                                </p>
                            </li>
                            <li>
                                Further details on the steps we take to protect your personal information, in these
                                cases is available from us on request by contacting us by email at
                                dataprotection@inrange.io at any time.
                            </li>
                        </ol>
                    </li>
                    <li id="risks-and-security" className="anchor font-semibold">
                        Risks and how we keep your personal information secure{" "}
                        <ol className="font-normal">
                            <li>
                                The main risk of our processing of your personal information is if it is lost, stolen or
                                misused. This could lead to your personal information being in the hands of someone else
                                who may use it fraudulently or make public, information that you would prefer to keep
                                private.
                            </li>
                            <li>
                                For this reason, InRange is committed to protecting your personal information from loss,
                                theft and misuse. We take all reasonable precautions to safeguard the confidentiality of
                                your personal information, including through use of appropriate organisational and
                                technical measures e.g. password authentication and encryption (at rest and in transit).
                            </li>
                            <li>
                                In the course of provision of your personal information to us, your personal information
                                may be transferred over the internet. Although we make every effort to protect the
                                personal information which you provide to us, the transmission of information over the
                                internet is not completely secure. As such, you acknowledge and accept that we cannot
                                guarantee the security of your personal information transmitted to our website and that
                                any such transmission is at your own risk. Once we have received your personal
                                information, we will use strict procedures and security features to prevent unauthorised
                                access to it.
                            </li>
                            <li>
                                Where we have given you (or where you have chosen) a password which enables you to
                                access your online account on the Platform, you are responsible for keeping this
                                password confidential. We ask you not to share a password with anyone.
                            </li>
                        </ol>
                    </li>
                    <li id="other-websites" className="anchor font-semibold">
                        Links to other websites
                        <p className="font-normal">
                            Our website may contain hyperlinks to websites that are not operated by us. These hyperlinks
                            are provided for your reference and convenience only and do not imply any endorsement of the
                            activities of such third-party websites or any association with their operators. This
                            privacy policy only applies to the personal information that we collect or which we receive
                            from third party sources, and we cannot be responsible for personal information about you
                            that is collected and stored by third parties. Third party websites have their own terms and
                            conditions and privacy policies, and you should read these carefully before you submit any
                            personal information to these websites. We do not endorse or otherwise accept any
                            responsibility or liability for the content of such third party websites or third party
                            terms and conditions or policies.
                        </p>
                    </li>
                    <li id="changes-this-privacy-policy" className="anchor font-semibold">
                        Changes to our privacy policy
                        <p className="font-normal">
                            We may update our privacy policy from time to time. Any changes we make to our privacy
                            policy in the future will be posted on this page and, where appropriate, notified to you by
                            post or email. Please check back frequently to see any updates or changes to our privacy
                            policy.
                        </p>
                    </li>
                    <li id="further-questions" className="anchor font-semibold">
                        Further questions and how to make a complaint
                        <ol className="font-normal">
                            <li>
                                If you have any queries or complaints about our collection, use or storage of your
                                personal information, or if you wish to exercise any of your rights in relation to your
                                personal information, please contact{" "}
                                <a href="mailto:dataprotection@inrange.io">dataprotection@inrange.io</a>. We will
                                investigate and attempt to resolve any such complaint or dispute regarding the use or
                                disclosure of your personal information.
                            </li>
                            <li>
                                You may also make a complaint to the Information Commissioner's Office, or the data
                                protection regulator in the country where you usually live or work, or where an alleged
                                infringement of applicable data protection laws has taken place. Alternatively, you may
                                seek a remedy through the courts if you believe your rights have been breached.
                            </li>
                        </ol>
                    </li>
                </ol>
                <p className="pt-12">
                    The practices described in this privacy policy statement are current as of February 2023.
                </p>
            </StyledPrivacyPolicy>
        </PageSection>
    );
};

export default PrivacyPolicyPage;
