import React from 'react';
import PageSection from 'components/PageSection';
import { Title2 } from 'components/Typography';
import { partners } from 'config/partners';
// import Carousel from 'components/Carousel';

const Partners = () => {
    const partnersNotHidden = partners.filter((partner) => !partner.hidden);

    return (
        <PageSection containerClass="pb-14 md:pb-48 lg:pb-56  pt-24 md:pt-28 lg:pt-32">
            <Title2 className="fade-in-scroll text-neutral-700 text-center">Partnering with leaders in clean energy</Title2>
            <div className="fade-in-scroll mt-8 md:mt-16 lg:mt-24">
                {/* <Carousel infiniteLoop items={partners} show={4} /> */}
                <div className="flex items-center gap-4">
                    {partnersNotHidden.map((item) => (
                        <div
                            key={item.name}
                            className="flex items-center justify-center flex-1 w-24 h-12 md:h-20 lg:h-24"
                        >
                            <img className="object-contain h-full" src={item.image} alt={item.name} />
                        </div>
                    ))}
                </div>
            </div>
        </PageSection>
    );
};

export default Partners;
