import freethsLogo from 'assets/images/freeths-logo.png';
import ironMountainLogo from 'assets/images/iron-mountain-logo.png';
import ukgbcLogo from 'assets/images/UKGBC-bw.png';
import solarEnergyUKLogo from 'assets/images/solar-energy-uk-logo.png';
import jllLogo from 'assets/images/jll-logo.png';
import ricsLogo from 'assets/images/rics.jpg';
import logo247 from 'assets/images/24-7-logo.png';
import londonMetricLogo from 'assets/images/london-metric-logo.png';
import lowerCarbonLogo from 'assets/images/lower-carbon-logo.png';
import octopusVenturesLogo from 'assets/images/octopus-ventures-logo.png';
import qCellLogo from 'assets/images/qcell-logo.png';
import theRangeLogo from 'assets/images/the-range-logo.png';
import voyagerLogo from 'assets/images/voyager-logo.png';

export const partners = [
    {
        id: 'freeths',
        name: 'Freeths',
        hidden: true,
        image: freethsLogo
    },
    {
        id: 'iron-mountain',
        name: 'Iron Mountain',
        hidden: false,
        image: ironMountainLogo
    },
    {
        id: 'jll',
        name: 'JLL',
        hidden: true,
        image: jllLogo
    },
    {
        id: '24/7',
        name: '24/7',
        hidden: false,
        image: logo247
    },
    {
        id: 'solar-energy-uk',
        name: 'solar-energy-uk',
        hidden: false,
        image: solarEnergyUKLogo
    },
    {
        id: 'london-metric',
        name: 'London Metric',
        hidden: true,
        image: londonMetricLogo
    },
    {
        id: 'qcell',
        name: 'QCell',
        hidden: false,
        image: qCellLogo
    },
    {
        id: 'the-range',
        name: 'The Range',
        hidden: true,
        image: theRangeLogo
    },
   {
        id: 'ukgbc',
        name: 'UKGBC',
        hidden: false,
        image: ukgbcLogo
    },
    {
        id: 'rics',
        name: 'RICS',
        hidden: false,
        image: ricsLogo
    }   
];

export const backedBy = [
    {
        id: 'voyager',
        name: 'Voyager',
        hidden: false,
        image: voyagerLogo
    },
    {
        id: 'octopus-ventures',
        name: 'Octopus Ventures',
        hidden: false,
        image: octopusVenturesLogo
    },
    {
        id: 'lower-carbon',
        name: 'Lower Carbon',
        hidden: false,
        image: lowerCarbonLogo
    }
];
