import React, { useLayoutEffect, useRef } from 'react';
import PageSection from 'components/PageSection';
import { Body1, SectionTitle } from 'components/Typography';
import { useGsapContext } from 'gsapContext';
import investment1 from 'assets/images/investment1.png';
import investment2 from 'assets/images/investment2.png';
import investment3 from 'assets/images/investment3.png';

const SolutionSectionThree = () => {
    const $context = useRef();
    const { gsap } = useGsapContext();

    useLayoutEffect(() => {
        let ctx = gsap.context(() => {
            const timeline = gsap.timeline({
                scrollTrigger: {
                    trigger: $context.current,
                    start: 'top center-=100',
                    end: 'top top',
                    toggleActions: 'restart none none reverse'
                }
            });
            timeline.fromTo('.image-1', { opacity: 0 }, { opacity: 1, duration: 0.2, delay: 0.3 });
            timeline.fromTo('.image-2', { opacity: 0 }, { opacity: 1, duration: 0.2 });
            timeline.fromTo('.image-3', { opacity: 0 }, { opacity: 1, duration: 0.2 });
        }, $context);

        return () => ctx.revert();
        // eslint-disable-next-line
    }, []);

    return (
        <PageSection
            ref={$context}
            color="inverted"
            containerClass="flex flex-col-reverse lg:flex-row gap-8"
            className="py-24 lg:py-56"
        >
            <div className="relative flex-1">
                <div className="image-1 md:ml-[5vw] lg:ml-0 w-56 md:w-[30rem] lg:w-96">
                    <img src={investment1} alt="" />
                </div>
                <div className="image-2 md:ml-[5vw] lg:ml-0 w-56 md:w-[30rem] lg:w-96 absolute left-32 md:left-[16rem] lg:left-auto lg:right-0 top-[50%] translate-y-[-50%]">
                    <img src={investment2} alt="" />
                </div>
                <div className="image-3 md:ml-[5vw] lg:ml-0 w-56 md:w-[30rem] lg:w-96">
                    <img src={investment3} alt="" />
                </div>
            </div>
            <div className="flex-1">
                <SectionTitle className="fade-in-scroll text-white">Automated platform for portfolio insights</SectionTitle>
                <Body1 className="fade-in-scroll pt-4 pb-8 lg:max-w-lg">
                Make data-driven decisions with recommended tariffs, optimised system sizing, and clear financials that pass the CFO-test. <br/><br/>
                From the first engagement, InRange presents a free and accurate portfolio-wide view of site revenue and savings potential.  <br/><br/>
                Our network of Tier 1 EPC contractors install our optimised designs at consistent 25% lower costs to customer.  If you have a preferred EPC contractor, <a href="https://inrange.io/solution/epc" target="_blank" rel="noreferrer"><u>refer them to our network.</u></a>  <br/><br/>
                </Body1>
            </div>
        </PageSection>
    );
};

export default SolutionSectionThree;
