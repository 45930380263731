import React from 'react';
import PropTypes from 'prop-types';

const Button = ({
    children,
    className = '',
    element = 'button',
    id,
    color = 'primary',
    onClick,
    size = 'md',
    style = {},
    variant = 'contained',
    ...rest
}) => {
    const btnStyles = {
        primary: {
            contained: 'bg-primary text-white disabled:bg-neutral-900',
            outlined:
                'bg-transparent text-primary border-primary border-1 disabled:text-neutral-900 disabled:border-neutral-900',
            text: 'bg-transparent text-primary disabled:text-neutral-900'
        },
        secondary: {
            contained: 'bg-secondary text-white disabled:bg-neutral-900',
            outlined:
                'bg-transparent text-secondary border-secondary border-1 disabled:text-neutral-900 disabled:border-neutral-900',
            text: 'bg-transparent text-secondary disabled:text-neutral-900'
        },
        danger: {
            contained: 'bg-error text-white disabled:bg-neutral-900',
            outlined:
                'bg-transparent text-error border-error border-1 disabled:text-neutral-900 disabled:border-neutral-900',
            text: 'bg-transparent text-error disabled:text-neutral-900'
        },
        success: {
            contained: 'bg-green-500 text-white disabled:bg-neutral-900',
            outlined:
                'bg-transparent text-green-500 border-green-500 border-1 disabled:text-neutral-900 disabled:border-neutral-900',
            text: 'bg-transparent text-green-500 disabled:text-neutral-900'
        },
        default: {
            contained: 'bg-primary text-white disabled:bg-neutral-900'
        }
    };

    const btnStyle = btnStyles[color][variant] || btnStyles.default.contained;

    const sizeOptions = {
        sm: 'btn-sm',
        md: 'btn-md',
        lg: 'btn-lg'
    };

    return (
        <>
            {element === 'div' ? (
                <div
                    className={`${sizeOptions[size]} ${btnStyle} cursor-pointer w-fit ${className}`}
                    style={style}
                    onClick={onClick}
                    {...rest}
                >
                    {children}
                </div>
            ) : (
                <button
                    className={`${sizeOptions[size]} ${btnStyle} ${className}`}
                    style={style}
                    onClick={onClick}
                    {...rest}
                >
                    {children}
                </button>
            )}
        </>
    );
};

export default Button;

Button.propTypes = {
    children: PropTypes.node,
    className: PropTypes.string,
    color: PropTypes.oneOf(['primary', 'secondary', 'danger', 'success']),
    element: PropTypes.oneOf(['button', 'div']),
    id: PropTypes.string,
    onClick: PropTypes.func,
    size: PropTypes.oneOf(['sm', 'md', 'lg']),
    style: PropTypes.object,
    variant: PropTypes.oneOf(['contained', 'outlined', 'text'])
};
