import React from "react";
import PageSection from "components/PageSection";
import { Body1, Display2 } from "components/Typography";
import AnalyseSite from "components/composite/AnalyseSite";

const Banner = () => {
  return (
    <PageSection
      className="py-20 lg:py-32"
      color="inverted"
      containerClass="flex flex-col lg:flex-row gap-16 lg:gap-8"
    >
      <div className="fade-in-scroll flex-1 w-4/5 lg:w-auto">
        <Display2 className="text-white mb-6">
        Generate, buy, and sell  clean energy, faster.
        </Display2>
        <AnalyseSite buttonColor={"secondary"} border={false} />
      </div>
      <div className="fade-in-scroll flex-1 flex flex-col w-4/5 lg:w-auto">
        <Body1 className="border-b-1 border-white pb-6">
        InRange’s AI-powered platform enables your team to make data-driven decisions and unlocks a new source of reliable, clean energy.
        </Body1>
        <div className="grid grid-cols-3 mt-2">
          <div className="flex flex-col pr-9">
            <p className="text-base md:text-3xl lg:text-4xl lg:font-semibold py-2 md:py-4 pr-4">
              400+
            </p>
            <p className="text-xs md:text-base flex items-center gap-2">
              number of assets
            </p>
          </div>
          <div className="relative flex flex-col pr-9 before:content-[''] before:absolute before:top-0 before:left-[-1rem] before:md:left-[-2rem] before:h-3/4 before:border-white before:border-l-1">
            <p className="text-base md:text-3xl lg:text-4xl lg:font-semibold py-2 md:py-4 pr-4">
              440 MW
            </p>
            <p className="text-xs md:text-base flex items-center gap-2">
              generation capacity
            </p>
          </div>
          <div className="relative flex flex-col before:content-[''] before:absolute before:top-0 before:left-[-1rem] before:md:left-[-2rem] before:h-3/4 before:border-white before:border-l-1">
            <p className="text-base md:text-3xl lg:text-4xl lg:font-semibold py-2 md:py-4">
              412 GWh
            </p>
            <p className="text-xs md:text-base flex items-center gap-2">
             annual generation
            </p>
          </div>
        </div>
      </div>
    </PageSection>
  );
};

export default Banner;
