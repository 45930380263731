import PageSection from 'components/PageSection';
import { SectionTitle } from 'components/Typography';
import React from 'react';
import { paths } from 'constants';
import { Link } from 'react-router-dom';
import Button from 'components/Button';

const EPCSolutionSectionFive = () => {
    return (
        <PageSection containerClass="py-16 md:py-32" className="border-b-1 border-neutral">
            <div className="w-full lg:w-1/2">
                <SectionTitle className="fade-in-scroll">
                Partner with InRange to expand your reach and keep your team building.<br/><br/>
                </SectionTitle>
            </div>
            <div className="hidden md:flex md:items-center md:gap-4 lg:gap-0">
                <Link to={paths.contactUs}>
                    <Button
                        element="div"
                        className="px-2 lg:px-10"
                    >
                        Get in touch
                    </Button>
                </Link>
            </div>
        </PageSection>
    );
};

export default EPCSolutionSectionFive;
