import React from 'react';
import styled from 'styled-components';
import PageSection from 'components/PageSection';
import { Display3 } from 'components/Typography';
import appConfig from 'config/appConfig';
import grid from 'assets/images/grid-secondary.png';
import { large, medium, xLarge } from 'styles/breakpoints';

const BackgroundGrid = styled.div`
    display: none;
    position: absolute;
    background-image: url(${grid});
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    top: -5vh;
    left: -10vw;
    z-index: 2;
    ${medium(`
        width: 80vw;
        height: 70vh;
    `)}
    ${large(`
        display: block;
        width: 50vw;
        height: 70vh;
    `)}
    ${xLarge(`
        width: 600px;
        height: 500px;
    `)}
`;

const SolutionSectionTwo = () => {
    return (
        <PageSection
            color="inverted"
            containerClass={`relative pb-52 ${appConfig.layout.header.fixed ? 'pt-52' : 'pt-44'}`}
        >
            <BackgroundGrid />
            <div className="flex flex-col items-center justify-center gap-8 w-full">
                <Display3 className="fade-in-scroll max-w-[780px] text-secondary" style={{ lineHeight: 1.6 }}>
                Accelerated procurement process.<br/>
                Landlord engagement at scale. <br/>
                Fixed, long term tariffs.
                </Display3>
            </div>
        </PageSection>
    );
};

export default SolutionSectionTwo;
