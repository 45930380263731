import BackedBy from "pages/home/BackedBy";
import CareersHeader from "./CareersHeader";
import CareersList from "./CareersList";
import OurMission from "./OurMission";
import OurValues from "./OurValues";
import RecruitmentProcess from "./RecruitmentProcess";
import WhatWeOffer from "./WhatWeOffer";

function CareersPage() {
    return (
        <>
            <CareersHeader />
            <BackedBy />
            <OurMission />
            <OurValues />
            <WhatWeOffer />
            <CareersList />
            <RecruitmentProcess />
        </>
    );
}

export default CareersPage;
