import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';

const ListItemStyled = styled.li`
    & .children-wrapper {
        max-height: 0;
        overflow-y: hidden;
        transform-origin: top;
        transition: max-height 0.3s ease;
        border: none;
    }
    &:hover .children-wrapper {
        margin-top: 0.5rem;
        max-height: 100vh;
        padding: 1rem;
        border: 1px solid ${(props) => props.theme.colors.neutral};
    }
`;

const ListItemTemplate = ({ externalLink, iconColor = 'white', item, onlyIcon, target = '_blank' }) => (
    <>
        {item.link ? (
            externalLink ? (
                <a
                    className="flex gap-2 items-center"
                    href={item.link}
                    target={target}
                    rel={target === '_blank' ? 'noreferrer' : 'external'}
                    onClick={item.onClick}
                >
                    {item.Icon && <item.Icon color={iconColor} className="mr-2" />}
                    {onlyIcon ? null : item.customElement || item.name}
                </a>
            ) : (
                <Link className="flex gap-2 items-center w-full" to={item.link} onClick={item.onClick}>
                    {item.Icon && <item.Icon color={iconColor} className="mr-2" />}
                    {onlyIcon ? null : item.customElement || item.name}
                </Link>
            )
        ) : (
            <>
                {item.Icon && <item.Icon color={iconColor} className="mr-2" onClick={item.onClick} />}
                {onlyIcon ? null : item.customElement || item.name}
            </>
        )}
    </>
);

const ListItem = ({
    className = '',
    dropdownClassName = '',
    externalLink,
    hideChildren,
    id = '',
    iconColor = 'white',
    item,
    onlyIcon,
    target = '_blank',
    style = {},
    ...rest
}) => {
    return (
        <>
            <ListItemStyled className={'relative flex gap-2 items-center' + className} id={id} style={style} {...rest}>
                <ListItemTemplate
                    externalLink={externalLink}
                    iconColor={iconColor}
                    item={item}
                    onlyIcon={onlyIcon}
                    target={target}
                />
                {!hideChildren && item.children?.length && (
                    <div
                        className={`children-wrapper hidden md:flex flex-col absolute top-[100%] gap-4 whitespace-nowrap min-w-fit ${dropdownClassName}`}
                    >
                        {item.children
                            .filter((item) => !item.hidden)
                            .map((child) => (
                                <ListItemTemplate
                                    key={child.link}
                                    externalLink={externalLink}
                                    iconColor={iconColor}
                                    item={child}
                                    onlyIcon={onlyIcon}
                                    target={target}
                                />
                            ))}
                    </div>
                )}
            </ListItemStyled>
            {!hideChildren && item.children?.length && (
                <div className="md:hidden pl-6 flex flex-col gap-4 text-sm">
                    {item.children
                        .filter((item) => !item.hidden)
                        .map((child) => (
                            <ListItemTemplate
                                key={child.link}
                                externalLink={externalLink}
                                iconColor={iconColor}
                                item={child}
                                onlyIcon={onlyIcon}
                                target={target}
                            />
                        ))}
                </div>
            )}
        </>
    );
};

export default ListItem;

ListItem.propTypes = {
    className: PropTypes.string,
    dropdownClassName: PropTypes.string,
    externalLink: PropTypes.bool,
    hideChildren: PropTypes.bool,
    iconColor: PropTypes.oneOf(['white', 'primary']),
    id: PropTypes.string,
    item: PropTypes.shape({
        customElement: PropTypes.node,
        Icon: PropTypes.any,
        id: PropTypes.string,
        link: PropTypes.string,
        name: PropTypes.string.isRequired,
        onClick: PropTypes.func,
        children: PropTypes.arrayOf(
            PropTypes.shape({
                customElement: PropTypes.node,
                Icon: PropTypes.any,
                id: PropTypes.string,
                link: PropTypes.string,
                name: PropTypes.string.isRequired,
                onClick: PropTypes.func
            })
        )
    }),
    onlyIcon: PropTypes.bool,
    target: PropTypes.oneOf(['_blank', '_self', '_top']),
    style: PropTypes.object
};
