import PageSection from 'components/PageSection';
import { Body1, SectionTitle } from 'components/Typography';
import React from 'react';

const SolutionSectionFour = () => {
    return (
        <PageSection containerClass="py-16 md:py-32" className="border-b-1 border-neutral">
            <div className="w-full lg:w-1/2">
                <SectionTitle className="fade-in-scroll">
                Sub-hourly, locally matched energy
                </SectionTitle>
            </div>
            <div className="flex flex-col lg:flex-row gap-8 lg:gap-16 mt-16">
                <div className="flex-1">
                    <Body1 className="fade-in-scroll">
                    The InRange network of C&I energy assets are aggregated to meet your sub-hourly and local energy matching needs. Granular point of origin data allows traceability to time and place of electron generation.
                    </Body1>
                </div>
                <div className="flex-1">
                    <Body1 className="fade-in-scroll">
                     Choose VPPA or sleeved PPA. InRange delivers energy, reduces energy bills, and issues you the renewable energy certificates that prove the origin of the energy you purchased.
                    </Body1>
                </div>
            </div>
        </PageSection>
    );
};

export default SolutionSectionFour;
