import React from "react";
import DynamicAnalyse from "pages/DynamicAnalyse";
import LandlordSolutionHeaderAnalyse from "./LandlordSolutionHeaderAnalyse";
import LandlordSolutionSectionFive from "./LandlordSolutionSectionFive";
import LandlordSolutionSectionFour from "./LandlordSolutionSectionFour";
import LandlordSolutionSectionThree from "./LandlordSolutionSectionThree";
import LandlordSolutionSectionTwo from "./LandlordSolutionSectionTwo";

const SolutionPage = () => {
  return (
    <>
      <LandlordSolutionHeaderAnalyse />
      <LandlordSolutionSectionTwo />
      <LandlordSolutionSectionThree />
      <LandlordSolutionSectionFour />
      <LandlordSolutionSectionFive />
      <DynamicAnalyse page="landlord" />
    </>
  );
};

export default SolutionPage;
