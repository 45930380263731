import React, { useEffect } from "react";
import Footer from "./Footer";
import MainHeader from "./MainHeader";
import { Outlet, useLocation } from "react-router";
import { HashLink } from "react-router-hash-link";
import Button from "components/Button";
import appConfig from "config/appConfig";
import { paths } from "constants";
import CookiesBanner from "./CookiesBanner";

const validPaths = Object.values(paths);

const Layout = () => {
  const location = useLocation();

  useEffect(() => {
    window.scroll(0, 0);
  }, [location.pathname]);

  const headerColor = (pathName) => {
    const rootPath = pathName.split("/")[1];
    if (rootPath === "analyse") return "default";

    return validPaths.includes(pathName) ? "default" : "inverted";
  };

  return (
    <div
      className={`relative flex flex-col justify-between min-h-screen ${
        appConfig.layout.header.fixed ? "pt-28" : ""
      }`}
    >
      <HashLink
        style={{ zIndex: 51 }}
        className="absolute top-2 left-1 -translate-x-96 focus:translate-x-0 transition-all"
        to="/#main"
      >
        <Button
          element="div"
          variant="outlined"
          size="sm"
          className="font-medium"
        >
          Skip Navigation
        </Button>
      </HashLink>
      {appConfig.layout.header.visible && (
        <MainHeader
          color={headerColor(location.pathname)}
          fixed={appConfig.layout.header.fixed}
        />
      )}
      <div className="flex-1">
        <Outlet />
      </div>

      <CookiesBanner />
      {appConfig.layout.footer.visible && (
        <Footer fixed={appConfig.layout.footer.visible} />
      )}
    </div>
  );
};

export default Layout;
