import React, { useLayoutEffect, useRef } from 'react';
import Button from 'components/Button';
import { Body1, Body2, Title1 } from 'components/Typography';
import PageSection from 'components/PageSection';
import { Link } from 'react-router-dom';
import { useGsapContext } from 'gsapContext';

import heroImg from 'assets/images/hero-1.png';
import exportTariff from 'assets/images/export-tariff.png';
import onsiteGeneration from 'assets/images/generation.png';
import appConfig from 'config/appConfig';
import { paths } from 'constants';

function HomePage() {
    const $context = useRef();
    const { gsap } = useGsapContext();

    useLayoutEffect(() => {
        let ctx = gsap.context((self) => {
            const timeline = gsap.timeline({ delay: 1 });
            timeline.fromTo('.bottom-fade-in', { opacity: 0 }, { opacity: 1, duration: 0.2 });
            timeline.to('.images-wrapper', { scale: 1.5 });
            timeline.to('.first-img', { opacity: 0, duration: 0.5 });
            timeline.to('.hero-card-1', { opacity: 1, scale: 1, duration: 0.1 });
            timeline.to('.hero-card-2', { opacity: 1, scale: 1, duration: 0.1 });
            timeline.to('.hero-card-3', { opacity: 1, scale: 1, duration: 0.1 });
            timeline.to('.hero-card-4', { opacity: 1, scale: 1, duration: 0.1 });

            gsap.to('.img-blue-bg', { backgroundColor: 'transparent', delay: 1.2 });
        }, $context);

        return () => ctx.revert();
        // eslint-disable-next-line
    }, []);

    return (
        <PageSection
            ref={$context}
            element="main"
            id="main"
            containerClass={`flex flex-col lg:flex-row gap-16 pt-4 pb-4 lg:pb-32 ${
                appConfig.layout.header.fixed ? 'lg:pt-24' : 'lg:pt-16'
            }`}
        >
            <div className="bottom-fade-in flex flex-1 flex-col gap-6 items-start w-full">
                <Body2 className="uppercase">Accelerate the generation of new renewable energy</Body2>
                <Title1>The enterprise power procurement platform</Title1>
                <Body1>
                Tenants, landlords, and data centres use the InRange platform to streamline their energy procurement process, creating renewable energy networks from commercial real estate.
                </Body1>
                <Link to={paths.product}>
                    <Button element="div">Get offer</Button>
                </Link>
            </div>

            <div className="img-blue-bg w-full bg-primary flex flex-1 items-center justify-center pr-8 pl-16 pt-14 pb-24 md:pb-80 lg:pb-14">
                <div
                    style={{
                        backgroundImage: `url(${heroImg})`,
                        backgroundPosition: 'center',
                        backgroundSize: 'contain',
                        backgroundRepeat: 'no-repeat'
                    }}
                    className="images-wrapper md:max-w-2xl md:max-h-96 relative origin-top-left lg:origin-left"
                >
                    <img className="first-img object-contain w-full h-full" src={heroImg} alt="Product" />

                    <img
                        className="hero-card-1 absolute opacity-0 scale-75 h-8 md:h-12 lg:h-16 top-8 md:top-24 lg:top-24 -left-12 md:left-0 lg:-left-20 origin-right"
                        src={onsiteGeneration}
                        alt=""
                    />
                    <img
                        className="hero-card-2 absolute opacity-0 scale-75 h-8 md:h-12 lg:h-16 top-20 md:top-48 lg:top-48 -left-12 md:left-0 lg:-left-20"
                        src={exportTariff}
                        alt=""
                    />

                </div>
            </div>
        </PageSection>
    );
}

export default HomePage;
