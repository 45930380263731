import React, { useLayoutEffect, useRef } from "react";
import PageSection from "components/PageSection";
// import landlordSolutionChart from 'assets/images/landlord-solution-chart.png';
import { Body1, Title1 } from "components/Typography";
import appConfig from "config/appConfig";
import { useGsapContext } from "gsapContext";
import AnalyseSite from "components/composite/AnalyseSite";

const LandlordSolutionHeader = () => {
  const $context = useRef();
  const { gsap } = useGsapContext();

  useLayoutEffect(() => {
    let ctx = gsap.context(() => {
      const timeline = gsap.timeline({ delay: 0.3 });
      timeline.fromTo(
        ".bottom-fade-in",
        { opacity: 0, y: "50px" },
        { opacity: 1, y: 0, duration: 0.2 }
      );
      timeline.fromTo(
        ".bottom-fade-in-2",
        { opacity: 0, y: "50px" },
        { opacity: 1, y: 0, duration: 0.2 }
      );
      timeline.fromTo(
        ".bottom-fade-in-3",
        { opacity: 0, y: "50px" },
        { opacity: 1, y: 0, duration: 0.2 }
      );
      // timeline.fromTo('.bottom-fade-in-4', { opacity: 0, y: '50px' }, { opacity: 1, y: 0, duration: 0.2 });
    }, $context);

    return () => ctx.revert();
    // eslint-disable-next-line
  }, []);

  return (
    <PageSection
      ref={$context}
      element="main"
      color="neutral"
      className={`relative z-10 pb-16 lg:pb-32 ${
        appConfig.layout.header.fixed ? "pt-24 lg:pt-32" : "pt-16 lg:pt-20"
      }`}
      containerClass="flex flex-col lg:flex-row gap-16"
    >
      <div className="flex-1">
        <Title1 className="bottom-fade-in">
        Unlock your portfolio’s renewable energy potential
        </Title1>
        <Body1 className="bottom-fade-in-2 mt-6 md:mt-10 mb-6">
        InRange’s streamlined energy procurement process automates and enhances your established, or nascent, energy program. <br/><br/>
        Our tech platform brings speed, scale, and profitability - backed by a Tier 1 network of EPC contractors.
        </Body1>
        <div className="bottom-fade-in-3">
          <AnalyseSite buttonColor={"primary"} border={true}></AnalyseSite>
        </div>
      </div>
      <div className="flex-1">
        {/* <div className="bottom-fade-in-4">
                    <img src={landlordSolutionChart} alt="" />
                </div> */}
      </div>
    </PageSection>
  );
};

export default LandlordSolutionHeader;
