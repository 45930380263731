const appConfig = {
    appInformation: {
        appName: 'InRange',
        mainEmail: 'hello@inrange.io',
        inquiriesEmail: 'sales@inrange.io',
        supportEmail: 'support@inrange.io'
    },
    layout: {
        header: {
            visible: true,
            fixed: true
        },
        footer: {
            visible: true,
            fixed: false
        }
    }
};

export default appConfig;
