import React, { useLayoutEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import PageSection from 'components/PageSection';
import { Body1, Title1, Title2 } from 'components/Typography';
import access1 from 'assets/images/access1.png';
import access2 from 'assets/images/access2.png';
import access3 from 'assets/images/access3.png';
import act1 from 'assets/images/act1.png';
import act2 from 'assets/images/act2.png';
import act3 from 'assets/images/act3.png';
import act4 from 'assets/images/act4.png';
import analyze1 from 'assets/images/analyze1.png';
import analyze2 from 'assets/images/analyze2.png';
import analyze3 from 'assets/images/analyze3.png';
import { useGsapContext } from 'gsapContext';
import appConfig from 'config/appConfig';

const CardGrid = styled.div`
    display: grid;
    grid-template-columns: 30% 70%;
    grid-template-rows: 25% 25% 20% 30%;
    gap: 1rem;
    width: 100%;
    box-sizing: border-box;
    padding: 1rem;
    & .card-1 img,
    & .card-2 img,
    & .card-3 img {
        width: 100%;
        object-fit: contain;
    }
    & .card-1 {
        grid-column: 1/2;
        grid-row: 2/4;
    }
    & .card-2 {
        display: flex;
        align-items: flex-end;
        grid-column: 1/2;
        grid-row: 4/5;
        margin-bottom: 0.3rem;
    }
    & .card-3 {
        display: flex;
        align-items: flex-end;
        grid-column: 2/3;
        grid-row: 1/5;
        height: 100%;
    }
`;

const HowItWorks = () => {
    const $wrapper = useRef();

    const { gsap } = useGsapContext();

    const [stateTimeline, setStateTimeline] = useState(null);

    useLayoutEffect(() => {
        let ctx = gsap.context(() => {
            const timeline = gsap.timeline({
                repeat: -1,
                scrollTrigger: {
                    trigger: $wrapper.current,
                    start: 'top center',
                    toggleActions: 'restart none restart none'
                }
            });

            timeline.to('.tracker-analyze .tracker-dot_first', { backgroundColor: '#2779A7', duration: 0.1 });
            timeline.to('.tracker-analyze button', { color: '#2779A7', scale: 1.2, duration: 0.1 });
            timeline.fromTo('.analyze', { opacity: 0, yPercent: 50 }, { opacity: 1, yPercent: 0, delay: 0.6 });
            timeline.fromTo('.card-1', { opacity: 0 }, { opacity: 1, duration: 0.6 });
            timeline.fromTo('.card-2', { opacity: 0 }, { opacity: 1, duration: 0.6 });
            timeline.fromTo('.card-3', { opacity: 0 }, { opacity: 1, duration: 0.6 });
            timeline.add('analyze-label');
            timeline.to('.analyze', { opacity: 0, yPercent: -20, delay: 6 });
            timeline.to('.tracker-analyze .tracker-dot_first', { backgroundColor: '#00022F', duration: 0.1 });
            timeline.to('.tracker-analyze button', { color: '#00022F', scale: 1, duration: 0.1 });
            timeline.to('.analyze', { display: 'none', duration: 0.02 });
            timeline.fromTo('.access', { display: 'none' }, { display: 'flex', opacity: 0 });

            timeline.to('.tracker-access .tracker-dot', { backgroundColor: '#2779A7', duration: 0.1 });
            timeline.to('.tracker-access button', { color: '#2779A7', scale: 1.2, duration: 0.1 });
            timeline.fromTo('.access', { yPercent: 50, opacity: 0 }, { yPercent: 0, opacity: 1 });
            timeline.add('access-label');
            timeline.to('.access-1', { opacity: 0, duration: 0.1, delay: 1.4 });
            timeline.to('.access-2', { opacity: 0, duration: 0.1, delay: 1.4 });
            timeline.to('.access', { opacity: 0, yPercent: -20, delay: 6 });
            timeline.to('.tracker-access .tracker-dot', { backgroundColor: '#00022F', duration: 0.1 });
            timeline.to('.tracker-access button', { color: '#00022F', scale: 1, duration: 0.1 });
            timeline.to('.access', { display: 'none', duration: 0.02 });
            timeline.fromTo('.act', { display: 'none' }, { display: 'flex', opacity: 0 });

            timeline.to('.tracker-act .tracker-dot', { backgroundColor: '#2779A7', duration: 0.1 });
            timeline.to('.tracker-act button', { color: '#2779A7', scale: 1.2, duration: 0.1 });
            timeline.fromTo('.act', { yPercent: 50, opacity: 0 }, { yPercent: 0, opacity: 1 });
            timeline.fromTo('.act-1', { opacity: 0 }, { opacity: 1, duration: 0.6 });
            timeline.fromTo('.act-2', { opacity: 0 }, { opacity: 1, duration: 0.6 });
            timeline.fromTo('.act-3', { opacity: 0 }, { opacity: 1, duration: 0.6 });
            timeline.fromTo('.act-4', { opacity: 0 }, { opacity: 1, duration: 0.6 });
            timeline.to('.tracker-act .tracker-dot', { backgroundColor: '#00022F', duration: 0.1, delay: 6 });
            timeline.add('act-label');
            timeline.to('.tracker-act button', { color: '#00022F', scale: 1, duration: 0.1 });

            setStateTimeline(timeline);
        }, $wrapper);

        return () => {
            ctx.revert();
            setStateTimeline(null);
        };
        // eslint-disable-next-line
    }, []);

    return (
        <PageSection
            ref={$wrapper}
            containerClass={`relative overflow-hidden ${
                appConfig.layout.header.fixed ? 'pb-16 pt-24 md:pb-24 pt-32' : 'py-16 md:py-24'
            }`}
        >
            <Title1 className="fade-in-scroll text-secondary" letterSpacing=".06em">
            How we streamline energy procurement
            </Title1>
            <div className="flex flex-col-reverse md:flex-row items-start gap-16">
                <div className="flex md:flex-col w-full md:w-auto mt-4 md:mt-16 md:ml-2 px-4 md:px-0">
                    <div className="tracker-item tracker-analyze border-primary">
                        <span className="tracker-dot_first bg-primary" />
                        <button
                            onClick={() => stateTimeline.pause('analyze-label')}
                            className="leading-4 font-bold -translate-x-0 md:translate-x-0"
                        >
                            Accelerate action
                        </button>
                    </div>
                    <div className="tracker-item tracker-access border-primary flex md:block justify-center">
                        <span className="tracker-dot bg-primary" />
                        <button onClick={() => stateTimeline.pause('access-label')}
                        className="leading-4 font-semibold translate-x-0 md:translate-x-0"
                        >
                            Deal execution at scale
                        </button>
                    </div>
                    <div className="tracker-item_last tracker-act border-primary flex md:block justify-end">
                        <span className="tracker-dot tracker-dot_last bg-primary" />
                        <button
                            onClick={() => stateTimeline.pause('act-label')}
                            className="leading-4 font-semibold translate-x-0 md:translate-x-0"
                        >
                            Energy management
                        </button>
                    </div>
                </div>
                <div className="flex-1">
                    <div className="analyze my-16 md:flex items-center gap-16">
                        <div>
                            <Title2 className="text-secondary mb-6">Accelerate action</Title2>
                            <Body1>
                            Make data-driven decisions to prioritise your portfolio. Our AI-powered platform leverages diverse datasets to generate instant accurate energy and financial forecasts.
                            </Body1>
                        </div>
                        <div className="mt-14 md:mt-0 md:max-w-lg">
                            <CardGrid>
                                <div className="card-1">
                                    <img src={analyze1} alt="" />
                                </div>
                                <div className="card-2">
                                    <img src={analyze2} alt="" />
                                </div>
                                <div className="card-3">
                                    <img src={analyze3} alt="" />
                                </div>
                            </CardGrid>
                        </div>
                    </div>
                    <div className="access my-16 flex-col md:flex-row items-center md:gap-16">
                        <div>
                            <Title2 className="text-secondary mb-6">Deal execution at scale</Title2>
                            <Body1>
                            Engage landlords and tenants with InRange's pre-approved contracts. Our trusted installer network gets energy flowing.
                            </Body1>
                        </div>
                        <div className="access-img-wrapper scale-90 relative mt-14 md:mt-0 md:max-w-lg">
                            <img className="access-3 w-full h-full top-0 left-0" src={access3} alt="" />
                            <img className="access-2 absolute z-20 w-full h-full top-0 left-0" src={access2} alt="" />
                            <img className="access-1 absolute z-30 w-full h-full top-0 left-0" src={access1} alt="" />
                        </div>
                    </div>
                    <div className="act my-16 flex-col md:flex-row items-center md:gap-16">
                        <div className="flex-1">
                            <Title2 className="text-secondary mb-6">Energy management</Title2>
                            <Body1>
                            The InRange Marketplace optimises power production and distribution, giving you access to reliable and economical clean energy. Get certainty by selling & buying energy at fixed, long term tariffs.
                            </Body1>
                        </div>
                        <div className="flex-1 relative mt-14 md:mt-0 md:max-w-lg">
                            <img className="act-1 object-contain" src={act1} alt="" />
                            <img
                                className="act-2 absolute object-contain top-0 left-0 w-full h-full"
                                src={act2}
                                alt=""
                            />
                            <img
                                className="act-3 absolute z-20 object-contain top-1 left-0 w-full h-full"
                                src={act3}
                                alt=""
                            />
                            <img
                                className="act-4 absolute object-contain top-1 left-0 w-full h-full"
                                src={act4}
                                alt=""
                            />
                        </div>
                    </div>
                </div>
            </div>
        </PageSection>
    );
};

export default HowItWorks;
