import React, { useLayoutEffect, useRef } from "react";
import PageSection from "components/PageSection";
import { Body1, Title1 } from "components/Typography";
import Button from "components/Button";
import { useGsapContext } from "gsapContext";
import { HashLink } from "react-router-hash-link";

const CareersHeader = () => {
    const $context = useRef();
    const { gsap } = useGsapContext();

    useLayoutEffect(() => {
        let ctx = gsap.context(() => {
            const timeline = gsap.timeline();
            timeline.fromTo(".bottom-fade-in-1", { opacity: 0, y: "50px" }, { opacity: 1, y: 0, duration: 0.2 });
            timeline.fromTo(".bottom-fade-in-2", { opacity: 0, y: "50px" }, { opacity: 1, y: 0, duration: 0.2 });
            timeline.fromTo(".bottom-fade-in-3", { opacity: 0, y: "50px" }, { opacity: 1, y: 0, duration: 0.2 });
        }, $context);

        return () => ctx.revert();
        // eslint-disable-next-line
    }, []);

    return (
        <PageSection
            ref={$context}
            color="inverted"
            containerClass="flex flex-col items-center md:w-7/12 text-center pb-16 md:pb-24 lg:pb-48 pt-16 md:pt-24 lg:pt-48"
            element="main"
        >
            <Title1 className="bottom-fade-in-1 text-secondary">Join us!</Title1>
            <Body1 className="bottom-fade-in-2 mt-6 md:mt-10 mb-6">
                We’re looking for talented individuals to help us build a new kind of energy company.
            </Body1>
            <HashLink to="#careers-list">
                <Button className="bottom-fade-in-3 mt-16" element="div" color="secondary">
                    See openings
                </Button>
            </HashLink>
        </PageSection>
    );
};

export default CareersHeader;
