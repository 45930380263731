import React from 'react';
import styled from 'styled-components';
import PageSection from 'components/PageSection';
import waves from 'assets/images/waves.png';
import { large, medium } from 'styles/breakpoints';

const LargeTitle = styled.h2`

    font-weight: 700;
    ${medium(`
        font-size: 25px;
    `)}
    ${large(`
        font-size: 50px;
    `)}
`;

const MediumTitle = styled.h2`

    font-weight: 400;
    ${medium(`
        font-size: 25px;
    `)}
    ${large(`
        font-size: 30px;
    `)}
`;

const Capacity = () => {
    return (
        <PageSection
            background={`url(${waves}) no-repeat center`}
            backgroundSize="cover"
            color="neutral"
            className="animate-vibrate relative md:py-24 lg:py-48 after:absolute after:top-0 after:left-0 after:z-10 after:w-full after:h-full after:bg-neutral after:opacity-50"
            containerClass="flex itemx-center justify-center max-w-[100vw] md:max-w-[90vw] xl:max-w-[1200px]"
        >
            <div className="fade-in-scroll relative w-full md:w-auto z-20 flex flex-col justify-center bg-primary text-white px-8 pb-16 pt-24 md:rounded-lg">
                <LargeTitle className="text-center mb-12 text-white">
                Transforming the built environment into energy networks with AI and automation.
                </LargeTitle>
                <MediumTitle className="text-center mb-6 text-white">
                Turn your real estate assets into power plants with AI-based energy & financial models, streamlined contracts, and managed operations & settlement.
                </MediumTitle>
                <MediumTitle className="text-center mb-20 text-white">
                Each asset is part of a network, buying and selling on the InRange Marketplace.<br/> 
                AI-optimisations maximise asset value and energy savings.
                </MediumTitle>
            </div>
        </PageSection>
    );
};

export default Capacity;
