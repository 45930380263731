import axios from "axios";
import hash from "object-hash";
import { useQuery } from "react-query";

// Production Building API - https://github.com/InRange-io/api
const BUILDING_API_URL = "https://d3ec9b5jthpueq.cloudfront.net";

// production BMA - https://github.com/InRange-io/building-manager-api
const BUILDING_MANAGER_API_URL = "https://main-api.prod.inrange.io";

axios.defaults.headers.common["Content-Type"] = "application/json";
axios.defaults.headers.common.accept = "application/json";

const queryOptions = (opts) => {
  return {
    cacheTime: 1000 * 60 * 60, // 1 hour
    staleTime: 1000 * 60 * 60 * 24, // 24 hours
    refetchOnWindowFocus: false,
    retry: 3,
    ...opts,
  };
};

// ************************************************
//       Buildings - Geo location of Buildings
// ************************************************
function getBounds(mapBounds) {
  if (mapBounds === undefined) return { north: 0, south: 0, west: 0, east: 0 };
  return {
    north: mapBounds.getNorth(),
    south: mapBounds.getSouth(),
    west: mapBounds.getWest(),
    east: mapBounds.getEast(),
  };
}

const getBuildingsByBounds = async (north, south, west, east) => {
  const res = await axios.get(
    `${BUILDING_API_URL}/buildings/bounds_postgis/${north}/${south}/${west}/${east}`
  );
  const hashMap = {};
  res.data.buildings.forEach((building) => {
    const key = hash(JSON.stringify(building.geometry.coordinates));
    hashMap[key] = { ...building, key };
  });
  return hashMap;
};

const getMarketingSiteData = async (buildingKey, totalBuildingArea) => {
  const marketingSiteBody = {
    buildingKey,
    totalBuildingArea,
  };
  const res = await axios.post(
    `${BUILDING_MANAGER_API_URL}/marketing-site`,
    marketingSiteBody
  );
  return res;
};

export const buildPublicUserInteractionData = (
  formValues,
  buildingKey,
  buildingPoint,
  buildingPostcodeAddress,
  values = {}
) => {
  return {
    companyEmail: formValues?.companyEmail,
    name: formValues?.firstName + " " + formValues?.lastName,
    buildingKey: buildingKey,
    latitude: buildingPoint[0],
    longitude: buildingPoint[1],
    generation: values?.generation,
    avoidance: values?.avoidance,
    landlordRevenue: values?.landlordRevenue,
    tenantSavings: values?.tenantSavings,
    address: buildingPostcodeAddress,
  };
};

export const createPublicUserInteraction = async (
  companyEmail,
  latitude,
  longitude,
  name,
  buildingKey,
  status
) => {
  const publicUserInteractionBody = {
    companyEmail,
    latitude,
    longitude,
    name,
    buildingKey,
    status,
  };
  const res = await axios.post(
    `${BUILDING_MANAGER_API_URL}/public-user-interaction`,
    publicUserInteractionBody
  );
  return res;
};

const buildEventsData = (data, action) => {
  return {
    metadata: {
      type: ["PUBLIC_USER_INTERACTION"],
      action: action,
      version: "1.0.0",
      timestamp: Date.now(),
      userEmail: data?.companyEmail,
      service: "marketing-site",
      hostname: window.location.hostname,
    },
    data: data,
  };
};

export const postPublicUserInteractionMessage = async (data, action) => {
  const eventsBody = buildEventsData(data, action);
  const res = await axios.post(
    `${BUILDING_MANAGER_API_URL}/events`,
    eventsBody
  );
  return res;
};

export const useBuildingsByBounds = (mapBounds) => {
  const { north, south, west, east } = getBounds(mapBounds);

  let mapSizeMeters = 100_000;
  if (mapBounds !== undefined)
    mapSizeMeters =
      mapBounds._northEast.distanceTo(mapBounds._southWest) || 100_000;

  const data = useQuery(
    ["buildings_by_bounds", north, south, west, east],
    async () => {
      return await getBuildingsByBounds(north, south, west, east);
    },
    queryOptions({ enabled: !!mapBounds && mapSizeMeters < 7000 })
  );
  return data;
};

export const useGetMarketingSiteData = (
  buildingKey,
  totalBuildingArea,
  validBuilding
) => {
  const data = useQuery(
    ["marketing_site_data", buildingKey, totalBuildingArea],
    async () => {
      return await getMarketingSiteData(buildingKey, totalBuildingArea);
    },
    queryOptions({ enabled: !!totalBuildingArea && validBuilding })
  );
  return data;
};
