import React from 'react';
import styled from 'styled-components';
import PageSection from 'components/PageSection';
import { Display3 } from 'components/Typography';
import Button from 'components/Button';
import { Link } from 'react-router-dom';
import grid from 'assets/images/grid.png';
import { large, medium, xLarge } from 'styles/breakpoints';
import { paths } from 'constants';

const BackgroundGrid = styled.div`
    position: absolute;
    background-image: url(${grid});
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    top: 0;
    right: -5vw;
    z-index: 0;
    ${medium(`
        width: 70vw;
        height: 60vh;
        top: -5vh;
        right: -20vw;
    `)}
    ${large(`
        width: 50vw;
        height: 70vh;
        top: -8vh;
        right: -10vw;
    `)}
    ${xLarge(`
        width: 600px;
        height: 500px;
    `)}
`;

const JoinUs = () => {
    return (
        <PageSection color="neutral" containerClass="relative pb-24 pt-44 md:pb-52 md:pt-80 lg:py-72">
            <BackgroundGrid />
            <div className="fade-in-scroll">
                <Display3 className="w-64 md:w-1/2 lg:w-2/5">
                We're partnering with Tier 1, best-in-class EPC contractors and suppliers. 
                </Display3>
                <Link to={paths.epcComplete}>
                    <Button className="mt-8 md:mt-10" element="div">
                        Learn more
                    </Button>
                </Link>
            </div>
        </PageSection>
    );
};

export default JoinUs;
