import React from 'react';
import styled from 'styled-components';
import PageSection from 'components/PageSection';
import { Display3 } from 'components/Typography';
import appConfig from 'config/appConfig';
import grid from 'assets/images/grid.svg';
import { large, medium, xLarge } from 'styles/breakpoints';

const BackgroundGrid = styled.div`
    position: absolute;
    background-image: url(${grid});
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    top: -30vh;
    left: -5vw;
    z-index: 0;
    ${medium(`
        width: 40vw;
        height: 30vh;
    `)}
    ${large(`
        top: -35vh;
        width: 50vw;
        height: 60vh;
    `)}
    ${xLarge(`
        top: -320px;
        left: -250px;
        width: 600px;
        height: 500px;
    `)}
`;

const SolutionSectionTwo = () => {
    return (
        <PageSection className={`pb-60 ${appConfig.layout.header.fixed ? 'pt-60' : 'pt-32'}`} containerClass="relative">
            <BackgroundGrid />
            <div className="flex flex-col items-center justify-center gap-8 w-full">
                <Display3 className="fade-in-scroll max-w-[780px] z-30">
                Tenant engagement at scale. <br></br>
                Future proofed assets. <br></br>
                Protection from tenancy voids.
                </Display3>
            </div>
        </PageSection>
    );
};

export default SolutionSectionTwo;
