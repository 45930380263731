import Button from 'components/Button';
import List from 'components/List';
import ListItem from 'components/ListItem';
import { paths } from 'constants';
import PropTypes from 'prop-types';
import { forwardRef } from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

const MobileMenuStyled = styled.div`
    input[type='checkbox'] {
        position: absolute;
        visibility: hidden;
        &:checked {
            & ~ label > .menu-btn {
                .open-icon {
                    display: none;
                }
                .close-icon {
                    display: block;
                }
            }
            & ~ .menu-wrapper {
                transform: scaleY(1);
            }
        }
    }
    & .menu-btn {
        width: 36px;
        height: 36px;
        border: 1px solid
            ${(props) => (props.color === 'inverted' ? props.theme?.colors.secondary : props.theme?.colors?.neutral)};
        box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
        border-radius: 6px;
        padding: 11px;
        & .close-icon {
            display: none;
        }
        &:focus {
            outline: 1px solid ${(props) => props.theme?.colors?.primary};
        }
    }
    & .menu-wrapper {
        position: fixed;
        top: 114px;
        left: 0;
        width: 100vw;
        height: calc(100vh - 114px);
        background-color: white;
        transition: transform 0.15s ease;
        transform-origin: top;
        transform: scaleY(0);
        padding: 5vh 5vw 10vh 5vw;
    }
    & .menu-wrapper {
        background-color: ${(props) => (props.color === 'inverted' ? props.theme.colors.primary : '#FFFFFF')};
        color: ${(props) => (props.color === 'inverted' ? '#FFFFFF' : props.theme.colors.primary)};
    }
`;

const MobileMenu = forwardRef(({ color = 'default', navItems = [], onChange }, ref) => {
    return (
        <MobileMenuStyled color={color} className="shrink-0 md:hidden">
            <input onChange={onChange} ref={ref} name="open-menu-checkbox" id="open-menu-checkbox" type="checkbox" />
            <label className="cursor-pointer" htmlFor="open-menu-checkbox">
                <button
                    aria-controls="main-navigation-menu"
                    aria-expanded={ref?.current?.checked ? 'true' : 'false'}
                    className="menu-btn relative flex items-center justify-center bg-transparent z-50"
                    onClick={() => ref?.current?.click()}
                >
                    <svg
                        className="open-icon"
                        width="16"
                        height="14"
                        viewBox="0 0 16 14"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <path
                            d="M1 1.75H15M1 7H15M1 12.25H15"
                            stroke={color === 'inverted' ? '#FFFFFF' : '#737373'}
                            strokeWidth="1.67"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                        />
                    </svg>
                    <svg
                        className="close-icon"
                        width="12"
                        height="12"
                        viewBox="0 0 12 12"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <path
                            d="M1 11L11 1M1 1L11 11"
                            stroke={color === 'inverted' ? '#FFFFFF' : '#737373'}
                            strokeWidth="1.67"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                        />
                    </svg>
                </button>
            </label>

            <div className="menu-wrapper flex flex-col justify-between z-40">
                <nav id="main-navigation-menu">
                    <List className="gap-6 flex-1">
                        {navItems
                            .filter((item) => !item.hidden)
                            .map((item) => (
                                <ListItem
                                    onClick={() => ref?.current.click()}
                                    key={item.link}
                                    item={{
                                        ...item,
                                        children: item.children?.map((child) => ({
                                            ...child,
                                            onClick: () => ref?.current.click()
                                        }))
                                    }}
                                    target={item.target}
                                    externalLink={item.externalLink}
                                />
                            ))}
                    </List>
                </nav>
                <div className="flex justify-between items-center gap-4 pt-12 border-t-1 border-neutral">
                    <div />
                    {/* <Link to={paths.login}>
                        <Button element="div" onClick={() => ref?.current.click()} variant="outlined">
                            Log in
                        </Button>
                    </Link> */}
                    <Link to={paths.requestDemo}>
                        <Button
                            color={color === 'inverted' ? 'secondary' : 'primary'}
                            element="div"
                            onClick={() => ref?.current.click()}
                        >
                            Request a demo
                        </Button>
                    </Link>
                </div>
            </div>
        </MobileMenuStyled>
    );
});

export default MobileMenu;

MobileMenu.propTypes = {
    color: PropTypes.oneOf(['default', 'inverted']),
    navItems: PropTypes.arrayOf(
        PropTypes.shape({
            name: PropTypes.string,
            link: PropTypes.string,
            externalLink: PropTypes.bool,
            target: PropTypes.oneOf(['_blank', '_self', '_top']),
            hidden: PropTypes.bool
        })
    )
};
