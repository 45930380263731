import React from 'react';
import PropTypes from 'prop-types';

const List = ({ className = '', id = '', children, horizontal, style = {} }) => {
    return (
        <ul
            className={`flex ${!className?.includes('gap') ? 'gap-4' : ''} ${
                horizontal ? 'flex-row items-center' : 'flex-col'
            } ${className}`}
            id={id}
            style={style}
        >
            {children}
        </ul>
    );
};

export default List;

List.propTypes = {
    children: PropTypes.node,
    className: PropTypes.string,
    id: PropTypes.string,
    horizontal: PropTypes.bool,
    style: PropTypes.object
};
