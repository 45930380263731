import {
  buildPublicUserInteractionData,
  createPublicUserInteraction,
  postPublicUserInteractionMessage,
} from "InRangeAPI";
import { Link } from "react-router-dom";
import Button from "./Button";

const ClaimBuilding = ({
  claimed,
  setClaimed,
  values,
  formValues,
  buildingKey,
  buildingPoint,
  buildingPostcodeAddress,
}) => {
  const handleClick = () => {
    const data = buildPublicUserInteractionData(
      formValues,
      buildingKey,
      buildingPoint,
      buildingPostcodeAddress,
      values
    );
    const companyEmail = formValues.companyEmail;
    const name = formValues.firstName + " " + formValues.lastName;
    const latitude = buildingPoint[0];
    const longitude = buildingPoint[1];
    const status = "VALID_BUILDING";

    setClaimed(true);
    postPublicUserInteractionMessage(data, "CLAIM_BUILDING");
    createPublicUserInteraction(
      companyEmail,
      latitude,
      longitude,
      name,
      buildingKey,
      status
    );
  };

  return (
    <div>
      <p className="my-3">
        Are you the landlord or tenant of this building? Claim it to get a{" "}
        <b>free feasibility study</b> on the InRange platform.
      </p>
      <Button disabled={claimed} onClick={handleClick} className="mb-5">
        {claimed ? "Building claimed!" : "Claim for free"}
      </Button>
      {claimed ? (
        <div>
          Look out for an email from InRange with access to your free building
          data. Till then, search another building, or&nbsp;
          <Link className="underline" to="https://blog.inrange.io/">
            read our blog
          </Link>
          .
        </div>
      ) : null}
    </div>
  );
};

export default ClaimBuilding;
