import React, { useLayoutEffect, useRef } from 'react';
import PageSection from 'components/PageSection';
import { Body1, SectionTitle } from 'components/Typography';
import energyBuyerSolarGrid from 'assets/images/energy-buyer-solar-grid.png';
import overheadSite from 'assets/images/overhead.jpg';
import { useGsapContext } from 'gsapContext';

const SolutionSectionThree = () => {
    const $context = useRef();
    const { gsap } = useGsapContext();

    useLayoutEffect(() => {
        let ctx = gsap.context((self) => {
            const timeline = gsap.timeline({
                scrollTrigger: {
                    trigger: $context.current,
                    start: 'top center-=200',
                    end: 'top top',
                    toggleActions: 'restart none none reverse'
                }
            });
            timeline.to('.images-wrapper', { scale: 1.4, xPercent: 5 });
            timeline.to('.hero-card-1', { opacity: 1, scale: 1, duration: 0.1 });
            timeline.to('.hero-card-2', { opacity: 1, scale: 1, duration: 0.1 });
        }, $context);

        return () => ctx.revert();
        // eslint-disable-next-line
    }, []);

    return (
        <PageSection
            ref={$context}
            color="neutral"
            containerClass="flex flex-col py-32 gap-80 md:gap-[500px] lg:gap-48"
        >
            <div className="flex flex-col lg:flex-row gap-16">
                <div className="w-full lg:w-3/5">
                    <SectionTitle className="fade-in-scroll">Pre-vetted project access</SectionTitle>
                    <Body1 className="fade-in-scroll pt-4 pb-4">
                    As an InRange EPC contractor partner, you are connected to our network of committed commercial and industrial landlords, across their portfolio. <br/><br/>
                    That means access to pre-vetted, regulatory-compliant projects via a streamlined process and a single point of contact, eliminating unnecessary overhead. <br/><br/>
                    You’ll be part of getting our customers to commercially operational 40% faster.
                    </Body1>
                </div>
                <div className="w-full lg:w-2/5">
                    <div className="images-wrapper max-h-40 max-w-xs md:max-h-48 md:max-w-md lg:max-w-2xl lg:max-h-96 relative origin-top-left lg:origin-left">
                        <img
                            className="relative md:left-48 lg:left-0 first-img object-contain w-full h-full"
                            src={overheadSite}
                            alt="Overhead of site with solar"
                        />
                    </div>
                </div>
            </div>

            <div className="flex flex-col-reverse lg:flex-row gap-16 items-center">
                <div className="w-full lg:w-2/5">
                    <div className="fade-in-scroll max-w-md lg:max-w-lg">
                        <img src={energyBuyerSolarGrid} alt="" />
                    </div>
                </div>
                <div className="w-full lg:w-3/5">
                    <SectionTitle className="fade-in-scroll">
                    Larger scale, commercial installations
                    </SectionTitle>
                    <Body1 className="fade-in-scroll pt-4 pb-4">
                    If you’ve been proposing right-sized projects, you’re undercutting your and your customer’s value.<br/><br/>
                    InRange up-sizes installations, maximising roof space and generation with larger and more profitable installations. <br/> <br/>
                    Projects maintain the highest possible standards of design, health, and safety.
                    </Body1>
                </div>
            </div>
        </PageSection>
    );
};

export default SolutionSectionThree;
