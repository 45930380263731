import PageSection from "components/PageSection";
import { Body3, Small, Title2Alt } from "components/Typography";

const jobs = [
    {
        title: "Product Designer",
        subtitle: "Product • Remote UTC +/-3, Full time",
        link: "https://jobs.ashbyhq.com/inrange/8e4ba027-7e8f-4e55-90e8-687e8cf6beb5"
    },
    {
        title: "Senior Data Scientist",
        subtitle: "Engineering • Remote UTC +/-3, Full time",
        link: "https://jobs.ashbyhq.com/inrange/3526acb5-3a72-4c60-a479-4ab987efc885"
    },
    {
        title: "Junior Data Scientist",
        subtitle: "Engineering • Remote UTC +/-3, Full time",
        link: "https://jobs.ashbyhq.com/inrange/621c4093-589b-4a9c-9d05-e57e94600bea"
    },
    {
        title: "Head of Finance",
        subtitle: "Finance • Remote (UK Only) • Full time",
        link: "https://jobs.ashbyhq.com/inrange/ce80ea46-72cc-4b2a-9bb1-529fad7bde41",
        sallaryRange: "£80K - £85K • Offers Equity"
    },
    {
        title: "Commercial Real Estate Transactions Lead - UK",
        subtitle: "Business Development • Remote (UK Only) • Full time",
        link: "https://jobs.ashbyhq.com/inrange/eebbb6eb-3201-4fa9-8b42-d2470d987e55"
    },
    {
        title: "Business Development – C&I Renewable Energy ",
        subtitle: "Business Development • Remote UTC +/-3 • Full time",
        link: "https://jobs.ashbyhq.com/inrange/7df2875b-1588-4086-982e-21754cec7206"
    },
    {
        title: "Marketing and Content Manager",
        subtitle: "Business Development • Remote UTC +/-3 • Full time",
        link: "https://jobs.ashbyhq.com/inrange/92ee71a7-108e-4021-b0da-60c9d5b3b772"
    },
    {
        title: "MBA - Finance and Strategy Internship",
        subtitle: "Business Development • London • Full time",
        link: "https://jobs.ashbyhq.com/inrange/d336f620-f76b-4f4f-b14e-180294909ba0"
    }
];

const JobItem = ({ job }) => (
    <a href={job.link} target="_blank" rel="noreferrer">
        <div className="fade-in-scroll flex items-center justify-between w-full border-1 border-primary rounded-md px-8 py-2">
            <div className="flex-1">
                <Body3>{job?.title}</Body3>
                <Small>{job?.subtitle}</Small>
                {job?.sallaryRange && <small>{job.sallaryRange}</small>}
            </div>
            <svg
                className="-rotate-90"
                width="12"
                height="8"
                viewBox="0 0 12 8"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
            >
                <path
                    d="M1.41 0.579956L6 5.16996L10.59 0.579956L12 1.99996L6 7.99996L0 1.99996L1.41 0.579956Z"
                    className="fill-primary"
                />
            </svg>
        </div>
    </a>
);

const CareersList = () => {
    return (
        <PageSection className="relative z-10 bg-white" containerClass="py-16 md:pb-24 lg:py-32" id="careers-list">
            <div className="lg:w-1/2 mb-24">
                <Title2Alt className="fade-in-scroll mb-8">Join us in helping to create a sustainable future</Title2Alt>
                <Small className="fade-in-scroll">Find the right job for you.</Small>
            </div>
            {jobs?.length && (
                <div className="grid gap-4">
                    {jobs.map((job, index) => (
                        <JobItem key={job.title + `${index}`} job={job} />
                    ))}
                </div>
            )}
        </PageSection>
    );
};

export default CareersList;
