import { createGlobalStyle } from 'styled-components';

import '@fontsource/manrope';
import '@fontsource/manrope/400.css';
import '@fontsource/manrope/500.css';
import '@fontsource/manrope/700.css';
import '@fontsource/manrope/800.css';

const GlobalStyle = createGlobalStyle`
  * {
    scrollbar-width: thin;
    outline: none;
    box-sizing: border-box;
    -ms-overflow-style: none;
    scrollbar-width: none;  
  }
  *::-webkit-scrollbar {
    display: none;
  }
  html {
    overflow-x: hidden;
    width: 100vw;
  }
  body {
    margin: 0;
    padding: 0;
    background: white;
    font-family: Manrope;
    overflow-x: hidden;
    width: 100vw;
  }
  body.menu-open {
    position: fixed;
    left: 0;
    width: 100vw;
  }
`;

export default GlobalStyle;
