import Button from 'components/Button';
import List from 'components/List';
import ListItem from 'components/ListItem';
import { Logo } from 'components/Logo';
import PageSection from 'components/PageSection';
import { navItems } from 'config/navigation';
import { paths } from 'constants';
import PropTypes from 'prop-types';
import { useRef } from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import MobileMenu from './MobileMenu';

const SolutionsMenuItemStyled = styled.button`
    background-color: transparent;
    border: none;
    text-align: left;
    & .submenu {
        max-height: 0;
        overflow-y: hidden;
        opacity: 0;
        transition: opacity 0.2s ease;
        box-shadow: 0px 12px 12px rgba(0, 0, 0, 0.04);
    }
    & .solution-btn {
        position: relative;
        & svg {
            transition: transform 0.2s ease;
        }
        &::after {
            content: '';
            position: absolute;
            top: 100%;
            left: 0;
            width: 100%;
            height: 2rem;
        }
    }
    &:hover,
    &:focus,
    &:active {
        & .submenu {
            max-height: 100vh;
            opacity: 1;
            padding: 6rem 4rem;
            background-color: ${(props) => (props.color === 'inverted' ? props.theme.colors.primary : '#FFFFFF')};
            color: ${(props) => (props.color === 'inverted' ? '#FFFFFF' : props.theme.colors.primary)};
        }
        & svg {
            transform: rotate(-180deg);
        }
    }
`;

const SolutionsMenuItem = ({ color, item }) => (
    <SolutionsMenuItemStyled color={color}>
        <div className="solution-btn cursor-pointer flex items-center gap-4">
            <span>{item.name}</span>
            <svg width="12" height="8" viewBox="0 0 12 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                    d="M1.41 0.579956L6 5.16996L10.59 0.579956L12 1.99996L6 7.99996L0 1.99996L1.41 0.579956Z"
                    fill={color === 'inverted' ? '#FFFFFF' : '#000000'}
                />
            </svg>
        </div>
        <div className="submenu absolute w-[100vw] left-[50%] translate-x-[-50%] top-24 z-40">
            <div className="w-[90vw] xl:w-[1200px] mx-auto flex justify-center items-center gap-12">
                <p className="flex-1">
                     Landlords unlock portfolio value.<br></ br><br></ br>
                     Tenants supercharge energy procurement.<br></ br><br></ br>
                     Data centres access a new energy asset class.<br></ br><br></ br>
                     EPC contractors access pre-vetted projects.
                </p>
                <div className="flex-1 flex flex-col justify-between gap-8">
                    {item.children?.map((child) => (
                        <Link key={child.link} to={child.link} className="font-semibold block">
                            {child.name}
                        </Link>
                    ))}
                </div>
            </div>
        </div>
    </SolutionsMenuItemStyled>
);

const MainHeader = ({ color = 'default', fixed }) => {
    const $checkbox = useRef();

    const preventBodyFromScroll = () => {
        if ($checkbox.current.checked) document.body.classList.add('menu-open');
        else document.body.classList.remove('menu-open');
    };

    return (
        <PageSection
            element="header"
            className={`${color === 'inverted' ? 'bg-primary text-white border-b-1 border-primary-300' : 'bg-white'} ${
                fixed ? 'fixed w-full left-0 top-0 z-50' : ''
            }`}
            containerClass="relative w-full flex justify-between items-center gap-8 lg:gap-24 py-8 z-50"
        >
            <Link
                to={paths.home}
                onClick={() => {
                    if ($checkbox.current.checked) $checkbox.current.click();
                }}
            >
                <Logo className="w-28 md:w-36" color={color === 'inverted' ? 'white' : 'blue'} includeLogoType />
            </Link>

            <nav className="hidden md:block lg:flex-1">
                <List className="gap-6 lg:gap-16 w-full" horizontal>
                    {navItems
                        .filter((item) => !item.hidden)
                        .map((item) => {
                            if (item.link === '/solution')
                                return <SolutionsMenuItem color={color} item={item} key={item.link} />;
                            return (
                                <ListItem
                                    key={item.link}
                                    item={item}
                                    target={item.target}
                                    externalLink={item.externalLink}
                                />
                            );
                        })}
                </List>
            </nav>

            <MobileMenu color={color} navItems={navItems} ref={$checkbox} onChange={preventBodyFromScroll} />

            <div className="hidden md:flex md:items-center md:gap-4 lg:gap-0">
                <Link to={paths.login}>
                    <Button element="div" className="px-2 lg:px-10" variant="text">
                        Log in
                    </Button>
                </Link>
                <Link to={paths.requestDemo}>
                    <Button
                        color={color === 'inverted' ? 'secondary' : 'primary'}
                        element="div"
                        className="px-2 lg:px-10"
                    >
                        Request a demo
                    </Button>
                </Link>
            </div>
        </PageSection>
    );
};

export default MainHeader;

MainHeader.propTypes = {
    color: PropTypes.oneOf(['default', 'inverted']),
    fixed: PropTypes.bool
};
