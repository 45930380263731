import { postPublicUserInteractionMessage } from "InRangeAPI";
import "leaflet/dist/leaflet.css";
import { mapBoxKey } from "mapbox";
import hash from "object-hash";
import { useEffect } from "react";
import { GeoJSON, MapContainer, TileLayer, useMapEvent } from "react-leaflet";

const mapbox_url = (theme) => {
  return `https://api.mapbox.com/styles/v1/mapbox/${theme}/tiles/{z}/{x}/{y}?access_token=${mapBoxKey}`;
};

const mapboxSearch = (input) => {
  return `https://api.mapbox.com/geocoding/v5/mapbox.places/${input}.json?access_token=${mapBoxKey}&autocomplete=true`;
};

const setBounds = (map, setMapBounds) => {
  const bounds = map.getBounds();
  setMapBounds(bounds);
};

const validCountries = [
  "United Kingdom",
  "Spain",
  "Portugal",
  "Netherlands",
  "Belgium",
  "France",
  "Italy",
  "Ireland",
];

const MapEventView = ({ center, setMapBounds }) => {
  // useMapEvent is a hook from react-leaflet that cretes an event listener
  // as well as a map object
  const moveEndHandler = () => {
    setBounds(map, setMapBounds);
  };
  const map = useMapEvent("moveend", moveEndHandler);

  // if position changes, update the map center and bounds
  useEffect(() => {
    map.setView(center);
    setBounds(map, setMapBounds);
  }, [center, map, setMapBounds]);

  return null;
};

const MapWithPolygons = ({
  position,
  buildingsInBounds,
  setBuildingPoint,
  setBuildingPostcodeAddress,
  setMapBounds,
  setSelectedBuilding,
  setValid,
  selectedBuilding,
  formValues,
}) => {
  const onMapLoad = (event) => {
    const map = event.target;
    const bounds = map.getBounds();
    setMapBounds(bounds);
  };

  const mapZoom = 17;

  const { timeZone, locale } = Intl.DateTimeFormat().resolvedOptions();

  return (
    <div className="h-[300px] analyse:h-[650px] max-w-[675px]">
      <MapContainer
        style={{
          width: "100%",
          height: "100%",
          margin: "10px 0 10px",
          zIndex: "10",
        }}
        center={position}
        zoom={mapZoom}
        scrollWheelZoom={false}
        whenReady={onMapLoad}
        attributionControl={false}
      >
        <MapEventView
          center={position}
          setMapBounds={setMapBounds}
          setValid={setValid}
        />
        <TileLayer
          attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
          url={mapbox_url("streets-v11")}
        />
        <GeoBuildings
          buildings={buildingsInBounds}
          setBuildingPoint={setBuildingPoint}
          style={buildingStyle}
          setSelectedBuilding={setSelectedBuilding}
          setValid={setValid}
          setBuildingPostcodeAddress={setBuildingPostcodeAddress}
          formValues={formValues}
          timezone={timeZone}
          language={locale}
        />
        <GeoBuildings
          buildings={selectedBuilding}
          setBuildingPoint={setBuildingPoint}
          style={selectedBuildingStyle}
          formValues={formValues}
          timezone={timeZone}
          language={locale}
          setSelectedBuilding={() => alert(`Building already selected`)}
        />
      </MapContainer>
    </div>
  );
};

export default MapWithPolygons;

const GeoBuildings = ({
  buildings,
  setBuildingPoint,
  style,
  setSelectedBuilding,
  formValues,
  timezone,
  language,
  setValid = () => {},
  setBuildingPostcodeAddress = () => {},
}) => {
  return Object.values(buildings).map((b) => {
    const key = b.key || hash(b.geometry);
    const onClick = () => {
      const selectedBuilding = { [key]: buildings[key] };
      const validSize = buildings[key].surface_area_sqm > 500;
      const buildingPoint = buildings[key].geometry.coordinates[0][0];
      const lat = buildingPoint[1];
      const long = buildingPoint[0];
      fetch(mapboxSearch(`${long},${lat}`))
        .then((res) => res.json())
        .then((data) => {
          const countryFeature = data?.features.filter((feature) => {
            return feature.place_type[0] === "country";
          })[0];
          const postcodeFeature = data?.features.filter((feature) => {
            return feature.place_type[0] === "postcode";
          })[0];
          setValid({
            location: validCountries.includes(countryFeature.place_name),
            size: validSize,
          });
          setBuildingPostcodeAddress(postcodeFeature.place_name);
          const name = formValues?.firstName
            ? formValues.firstName + " " + formValues.lastName
            : undefined;
          const interactionPayload = {
            companyEmail: formValues?.companyEmail,
            name: name,
            buildingKey: key,
            timezone: timezone,
            language: language,
            latitude: lat,
            longitude: long,
            address: postcodeFeature.place_name,
          };
          postPublicUserInteractionMessage(
            interactionPayload,
            "CLICK_BUILDING"
          );
        });
      setSelectedBuilding(selectedBuilding);
      setBuildingPoint([...buildingPoint].reverse());
    };

    return (
      <GeoJSON
        key={"geojson " + key}
        data={b.geometry}
        style={style}
        eventHandlers={{
          click: onClick,
        }}
      />
    );
  });
};

const buildingStyle = {
  fillColor: "#404040",
  fillOpacity: 0.3,
  color: "#808080",
  weight: 2,
  opacity: 1,
};

const selectedBuildingStyle = {
  fillColor: "#00022F",
  fillOpacity: 0.55,
  color: "#00022F",
  weight: 2,
  opacity: 1,
};
