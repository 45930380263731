import AnalyseStatBox from "components/AnalyseStatBox";
import generationImg from "../../assets/images/generation.svg";
import revenueImage from "../../assets/images/revenue.svg";
import savingsImg from "../../assets/images/savings.svg";
import carbonImg from "../../assets/images/carbon.svg";
import styled from "styled-components";
import { small } from "../../styles/breakpoints";

const AnalyseStatBoxes = ({ values, hidden = true }) => {
  return (
    <div className="relative flex flex-col w-full flex-shrink-0 max-w-[675px] analyse:w-[480px] analyse:h-[378px] py-2 gap-2">
      <div className="w-full h-1/2 flex flex-row">
        <div className="w-1/2 h-full p-1.5">
          <AnalyseStatBox
            symbol="MWh"
            variant="contained"
            value={values.generation}
            image={generationImg}
            description="Annual estimated generation"
          />
        </div>
        <div className="w-1/2 h-full p-1.5">
          <AnalyseStatBox
            symbol="CO2e"
            variant="contained"
            value={values.avoidance}
            image={carbonImg}
            description="Annual avoided emissions"
          />
        </div>
      </div>
      <div className="w-full h-1/2 flex flex-row">
        <div className="w-1/2 h-full p-1.5">
          <AnalyseStatBox
            symbol="£"
            variant="outlined"
            value={values.landlordRevenue}
            image={revenueImage}
            hidden={hidden}
            description="Annual revenue for landlord"
          />
        </div>
        <div className="w-1/2 h-full p-1.5">
          <AnalyseStatBox
            symbol="£"
            variant="outlined"
            value={values.tenantSavings}
            image={savingsImg}
            hidden={hidden}
            description="Annual savings for tenant"
          />
        </div>
        {hidden && (
          <div className="absolute w-[96%] ml-[2%] mt-[10%] h-1/5 bg-white bg-opacity-80 text-center">
            <Title className="p-2">
              Provide your info below to reveal results
            </Title>
          </div>
        )}
      </div>
    </div>
  );
};

const Title = styled.h1`
  font-style: normal;
  letter-spacing: ${(props) => props.letterSpacing || "-0.04em"};
  font-size: 4vw;
  line-height: 40px;
  font-weight: 700;
  ${(props) =>
    props.variant &&
    `
    color: ${props.variant === "outlined" ? "primary" : "white"};
  `}
  ${small(`
      font-size: 25px;
      line-height: 48px;
  `)}
`;

export default AnalyseStatBoxes;
