import RedirectPage from "pages/RedirectPage";


function Blog () {
  return <RedirectPage
    destination={"https://blog.inrange.io"}
    text={"View our blog..."}
  />
}

export default Blog
