import { useGetMarketingSiteData } from "InRangeAPI";
import AnalyseSiteMap from "components/composite/AnalyseSiteMap";
import RevealQuickAnalysis from "components/composite/RevealQuickAnalysis";
import { useEffect, useState } from "react";
import { useParams } from "react-router";

const defaultValues = {
  generation: "...",
  avoidance: "...",
  landlordRevenue: "£...",
  tenantSavings: "£...",
};

const addCommas = (number) => {
  return number.toLocaleString("en-US", { maximumFractionDigits: 0 });
};

const createCurrencyRange = (value, range) => {
  const bottomRange = Math.floor(value - value * range);
  const topRange = Math.ceil(value + value * range);
  return "£" + addCommas(bottomRange) + " - £" + addCommas(topRange);
};

const formatValues = (values) => {
  if (Object.keys(values).length === 0) return defaultValues;

  let newValues = structuredClone(values);

  newValues["generation"] = addCommas(newValues["generation"] / 1000);
  newValues["avoidance"] = (newValues["avoidance"] / 1_000_000).toFixed(1);
  newValues["landlordRevenue"] = createCurrencyRange(
    newValues["landlordRevenue"],
    0.1
  );
  newValues["tenantSavings"] = createCurrencyRange(
    newValues["tenantSavings"],
    0.1
  );
  return newValues;
};

const AnalysePage = () => {
  const { latitude, longitude } = useParams();
  const initialLatLong = `${longitude},${latitude}`;
  const [buildingPoint, setBuildingPoint] = useState([latitude, longitude]);
  const [buildingPostcodeAddress, setBuildingPostcodeAddress] = useState();
  const [totalBuildingArea, setTotalBuildingArea] = useState();
  const [buildingKey, setBuildingKey] = useState();
  const [values, setValues] = useState(defaultValues);
  const [formValues, setFormValues] = useState();
  const [claims, setClaims] = useState({});
  const [requests, setRequests] = useState({});
  const [buildingValidity, setBuildingValidity] = useState({
    size: true,
    location: true,
  });
  const [hidden, setHidden] = useState(false);
  const [formSubmitted, setFormSubmitted] = useState(false);
  const [dataLoading, setDataLoading] = useState(false);

  const validBuilding = buildingValidity.size && buildingValidity.location;
  const fetchMarketingSiteData = useGetMarketingSiteData(
    buildingKey,
    totalBuildingArea,
    validBuilding
  );

  const setClaimed = (claimed) => {
    setClaims({ ...claims, [buildingKey]: claimed });
  };

  const setRequestedUpdate = (requestedUpdate) => {
    setRequests({ ...requests, [buildingKey]: requestedUpdate });
  };

  useEffect(() => {
    if (!validBuilding) {
      setDataLoading(false);
      setValues(defaultValues);
      setHidden(false);
      return;
    }

    if (!fetchMarketingSiteData.data) {
      setDataLoading(true);
      setValues(defaultValues);
      setHidden(false);
      return;
    }

    setDataLoading(false);
    const marketingSiteData = fetchMarketingSiteData.data.data;
    setValues(formatValues(marketingSiteData));

    // When we get data, we want to show the results
    // if the form has been submitted
    setHidden(!formSubmitted);
  }, [
    fetchMarketingSiteData.data,
    totalBuildingArea,
    formSubmitted,
    validBuilding,
  ]);

  return (
    <AnalysePageView
      dataLoading={dataLoading}
      buildingPoint={buildingPoint}
      setBuildingPoint={setBuildingPoint}
      buildingPostcodeAddress={buildingPostcodeAddress}
      setBuildingPostcodeAddress={setBuildingPostcodeAddress}
      values={values}
      hidden={hidden}
      requestedUpdate={requests[buildingKey]}
      setRequestedUpdate={setRequestedUpdate}
      claimed={claims[buildingKey]}
      setClaimed={setClaimed}
      formSubmitted={formSubmitted}
      setFormSubmitted={setFormSubmitted}
      setTotalBuildingArea={setTotalBuildingArea}
      buildingKey={buildingKey}
      setBuildingKey={setBuildingKey}
      buildingValidity={buildingValidity}
      setBuildingValidity={setBuildingValidity}
      formValues={formValues}
      setFormValues={setFormValues}
      initialLatLong={initialLatLong}
    ></AnalysePageView>
  );
};

export default AnalysePage;

const AnalysePageView = ({
  buildingPoint,
  dataLoading,
  setBuildingPoint,
  setBuildingPostcodeAddress,
  buildingPostcodeAddress,
  values,
  hidden,
  formSubmitted,
  setFormSubmitted,
  requestedUpdate,
  setRequestedUpdate,
  claimed,
  setClaimed,
  setTotalBuildingArea,
  buildingKey,
  setBuildingKey,
  buildingValidity,
  setBuildingValidity,
  formValues,
  setFormValues,
  initialLatLong,
}) => {
  return (
    <div className="flex flex-col analyse:grid analyse:grid-cols-2 justify-center container-screen">
      <div className="p-2 flex-shrink">
        <AnalyseSiteMap
          initialLatLong={initialLatLong}
          buildingPoint={buildingPoint}
          setBuildingPoint={setBuildingPoint}
          setBuildingPostcodeAddress={setBuildingPostcodeAddress}
          setTotalBuildingArea={setTotalBuildingArea}
          setBuildingKey={setBuildingKey}
          setValid={setBuildingValidity}
          formValues={formValues}
        ></AnalyseSiteMap>
      </div>
      <div className="p-2 flex-shrink-0">
        <div className="flex flex-col">
          <RevealQuickAnalysis
            buildingPoint={buildingPoint}
            buildingPostcodeAddress={buildingPostcodeAddress}
            dataLoading={dataLoading}
            formSubmitted={formSubmitted}
            setFormSubmitted={setFormSubmitted}
            buildingValidity={buildingValidity}
            claimed={claimed}
            setClaimed={setClaimed}
            values={values}
            hidden={hidden}
            formValues={formValues}
            setFormValues={setFormValues}
            buildingKey={buildingKey}
            requestedUpdate={requestedUpdate}
            setRequestedUpdate={setRequestedUpdate}
          />
        </div>
      </div>
    </div>
  );
};
