import React, { useLayoutEffect, useRef } from 'react';
import PageSection from 'components/PageSection';
import { Body1, SectionTitle } from 'components/Typography';
import energyBuyerSolarGrid from 'assets/images/energy-buyer-solar-grid.png';
import savingsByMonth from 'assets/images/savings-by-month.png';
import annualSavings from 'assets/images/savings.png';
import tenantShare from 'assets/images/tenant-share.png';
import { useGsapContext } from 'gsapContext';

const SolutionSectionThree = () => {
    const $context = useRef();
    const { gsap } = useGsapContext();

    useLayoutEffect(() => {
        let ctx = gsap.context((self) => {
            const timeline = gsap.timeline({
                scrollTrigger: {
                    trigger: $context.current,
                    start: 'top center-=200',
                    end: 'top top',
                    toggleActions: 'restart none none reverse'
                }
            });
            timeline.to('.images-wrapper', { scale: 1.4, xPercent: 5 });
            timeline.to('.hero-card-1', { opacity: 1, scale: 1, duration: 0.1 });
            timeline.to('.hero-card-2', { opacity: 1, scale: 1, duration: 0.1 });
        }, $context);

        return () => ctx.revert();
        // eslint-disable-next-line
    }, []);

    return (
        <PageSection
            ref={$context}
            color="neutral"
            containerClass="flex flex-col py-32 gap-80 md:gap-[500px] lg:gap-48"
        >
            <div className="flex flex-col lg:flex-row gap-16">
                <div className="w-full lg:w-3/5">
                    <SectionTitle className="fade-in-scroll">Energy procurement, simplified</SectionTitle>
                    <Body1 className="fade-in-scroll pt-4 pb-4">
                    InRange's efficient, automated process slashes energy costs by a consistent 25%. <br/><br/>
                    Get the best instant offers for on-site generation and for buying from the InRange network. <br/><br/>
                    Your team makes faster, data-driven decisions with AI-powered energy & financial forecasts. Landlords engage at scale with friction-reducing, pre-approved contracts.
                    </Body1>
                </div>
                <div className="w-full lg:w-2/5">
                    <div className="images-wrapper max-h-40 max-w-xs md:max-h-48 md:max-w-md lg:max-w-2xl lg:max-h-96 relative origin-top-left lg:origin-left">
                        <img
                            className="relative md:left-48 lg:left-0 first-img object-contain w-full h-full"
                            src={savingsByMonth}
                            alt="Product"
                        />

                        <img
                            className="hero-card-1 absolute opacity-0 scale-75 h-20 md:h-28 -bottom-20 -left-4 md:-bottom-32 md:left-0 lg:-bottom-4 lg:-left-10"
                            src={annualSavings}
                            alt=""
                        />
                        <img
                            className="hero-card-2 absolute opacity-0 scale-75 h-20 md:h-28 -bottom-32 left-20 md:-bottom-56 md:left-72 lg:-bottom-24 lg:left-40"
                            src={tenantShare}
                            alt=""
                        />
                    </div>
                </div>
            </div>

            <div className="flex flex-col-reverse lg:flex-row gap-16 items-center">
                <div className="w-full lg:w-2/5">
                    <div className="fade-in-scroll max-w-md lg:max-w-lg">
                        <img src={energyBuyerSolarGrid} alt="" />
                    </div>
                </div>
                <div className="w-full lg:w-3/5">
                    <SectionTitle className="fade-in-scroll">
                    Intelligent energy management
                    </SectionTitle>
                    <Body1 className="fade-in-scroll pt-4 pb-4">
                    Rely on InRange's advanced forecasting and analytics to optimise your energy delivery, balancing demand with supply across your sites for maximal savings. <br/><br/>
                    Access managed and transparent billing, settlement, and readings across your portfolio in a single, unified point of access. <br/> Your finance department will thank you.
                    </Body1>
                </div>
            </div>
        </PageSection>
    );
};

export default SolutionSectionThree;
