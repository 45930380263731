import PageSection from 'components/PageSection';
import { Body1, SectionTitle } from 'components/Typography';
import React from 'react';
// import imageBottom from 'assets/images/landlord-solution-bottom.png';

const SolutionSectionFive = () => {
    return (
        <PageSection
            color="inverted"
            containerClass="flex flex-col-reverse lg:flex-row lg:items-center gap-16"
            className="py-32"
        >
            <div className="w-full lg:w-2/5">
                {/* <div className="fade-in-scroll max-w-xs md:max-w-md mx-auto">
                    <img src={imageBottom} alt="" />
                </div> */}
            </div>
            <div className="w-full lg:w-3/5">
                <SectionTitle className="fade-in-scroll text-secondary">
                Intelligent energy management
                </SectionTitle>
                <Body1 className="fade-in-scroll pt-4 lg:max-w-xl">
                As part of InRange's renewable energy network, sell your sites' excess energy at guaranteed, fixed 10 year tariffs. Get revenue certainty, even in tenancy voids.
                </Body1>
                <Body1 className="fade-in-scroll pt-4 lg:max-w-xl">
                Our advanced forecasting optimises energy distribution across the InRange network - balancing supply and demand for maximal utilisation.
                </Body1>
                <Body1 className="fade-in-scroll pt-4 lg:max-w-xl">
                Access managed, transparent billing and settlement across your portfolio in a single point of access.
                </Body1>
            </div>
        </PageSection>
    );
};

export default SolutionSectionFive;
