import { mapBoxKey } from "../mapbox";
import { useState, useEffect, useRef } from "react";

const AddressBar = ({
  initialLatLong,
  border,
  suggestionHandler,
  setCurrentAddress = () => {},
}) => {
  const [suggestions, setSuggestions] = useState([]);
  const addressBarRef = useRef(null);

  const mapboxAddressSearch = (input) => {
    return `https://api.mapbox.com/geocoding/v5/mapbox.places/${input}.json?access_token=${mapBoxKey}&autocomplete=true`;
  };

  useEffect(() => {
    if (initialLatLong) {
      fetch(mapboxAddressSearch(initialLatLong))
        .then((res) => res.json())
        .then((data) => {
          const postCodeFeature = data?.features.filter((feature) => {
            return feature.place_type[0] === "postcode";
          });
          addressBarRef.current.value = postCodeFeature[0]?.place_name;
          setCurrentAddress(postCodeFeature[0]?.place_name);
        });
    }
  }, [initialLatLong, setCurrentAddress]);

  const searchAddress = (e) => {
    const input = e.target.value;
    fetch(mapboxAddressSearch(input))
      .then((res) => res.json())
      .then((data) => {
        setSuggestions(data?.features || []);
      });
  };

  const handleValidationOnBlur = (e) => {
    const focusedElement = e.relatedTarget ?? {};
    // Check if a search suggestion was clicked
    const compareString =
      focusedElement?.id === "suggestion"
        ? focusedElement?.innerText
        : e.target.value;
    const addressInSuggestion = suggestions.filter(
      (suggestion) => suggestion.place_name === compareString
    )[0];
    suggestionHandler(addressInSuggestion);
    addressBarRef.current.value = addressInSuggestion
      ? addressInSuggestion.place_name
      : compareString;
    setSuggestions([]);
  };

  return (
    <div className="relative bg-transparent w-full h-fit">
      <input
        className={`overflow-ellipsis rounded-sm py-2 px-2 w-full text-neutral-700 text-[14px] md:text-[15px] lg:text-[18px] ${
          border ? "border-solid border-1 border-neutral-800" : ""
        }`}
        onChange={searchAddress}
        onFocus={searchAddress}
        onBlur={handleValidationOnBlur}
        placeholder="Enter a building address to analyse"
        ref={addressBarRef}
      />
      <div
        className={`bg-white absolute w-full h-fit rounded-b rounded-t-none text-neutral-700 ${
          suggestions.length ? "border-1 z-10" : ""
        }`}
        id="suggestions"
      >
        {suggestions.map((suggestion) => (
          <div
            key={suggestion.id}
            id="suggestion"
            className="p-[10px] border-b"
            tabIndex={0}
          >
            {suggestion.place_name}
          </div>
        ))}
      </div>
    </div>
  );
};

export default AddressBar;
