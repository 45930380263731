import PageSection from 'components/PageSection';
import { Body1, SectionTitle } from 'components/Typography';
import React from 'react';

const EPCSolutionSectionFour = () => {
    return (
        <PageSection containerClass="py-16 md:py-32" className="border-b-1 border-neutral">
            <div className="w-full lg:w-1/2">
                <SectionTitle className="fade-in-scroll">
                Superior operational efficiency
                </SectionTitle>
            </div>
            <div className="flex flex-col lg:flex-row gap-8 lg:gap-16 mt-16">
                <div className="flex-1">
                    <Body1 className="fade-in-scroll">
                    As an EPC contractor partner, you gain access to finalised, actionable deals. Minimise soft costs from sales and marketing processes, and focus on delivering best-in-class installations. 
                        </Body1>
                </div>
                <div className="flex-1">
                    <Body1 className="fade-in-scroll">
                    Framework agreements facilitate a program-managed approach, with continuous improvement and economies of scale. And InRange manages hardware procurement, so you’re flexible to invest where your business needs it most.                     </Body1>
                </div>
            </div>
        </PageSection>
    );
};

export default EPCSolutionSectionFour;
