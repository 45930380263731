import React from "react";
import DataCentreSolutionHeaderAnalyse from "./DataCentreSolutionHeaderAnalyse";
import DataCentreSolutionSectionFour from "./DataCentreSolutionSectionFour";
import DataCentreSolutionSectionThree from "./DataCentreSolutionSectionThree";
import DataCentreSolutionSectionTwo from "./DataCentreSolutionSectionTwo";
import DynamicAnalyse from "pages/DynamicAnalyse";

const SolutionPage = () => {
  return (
    <>
      <DataCentreSolutionHeaderAnalyse />
      <DataCentreSolutionSectionTwo />
      <DataCentreSolutionSectionThree />
      <DataCentreSolutionSectionFour />
      <DynamicAnalyse page="datacentre" />
    </>
  );
};

export default SolutionPage;
