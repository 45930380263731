class ContactUs {
    baseUrl = '';

    formSpreeId = 'xqkozqpn';

    send(values) {
        console.log(`Send this form to ${this.baseUrl}: ${JSON.stringify(values)}`);
    }
}

const instance = new ContactUs();
export default instance;
