import PropTypes from 'prop-types';
import styled from 'styled-components';

import logo from '../assets/logo.svg';

const LogoBlue = () => (
    <svg width="322" height="50" viewBox="0 0 322 50" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M0 0.616577H3.5547V43.7758H0V0.616577ZM57.4968 0.616577V43.7758H54.5696L21.3227 6.28893V43.7758H17.768V0.616577H20.7651L53.9423 38.1034V0.616577H57.4968ZM101.718 43.7758L91.7511 31.1363C91.3329 31.1774 90.7057 31.1979 89.8692 31.1979H78.8567V43.7758H69.7957V0.616577H89.8692C94.0981 0.616577 97.7688 1.23314 100.882 2.46626C104.042 3.69938 106.458 5.46683 108.131 7.76867C109.803 10.0705 110.64 12.8039 110.64 15.9689C110.64 19.2161 109.734 22.0112 107.922 24.3541C106.156 26.6971 103.6 28.444 100.255 29.5949L111.476 43.7758H101.718ZM101.509 15.9689C101.509 13.215 100.487 11.0981 98.4423 9.61835C96.3982 8.13861 93.4011 7.39873 89.451 7.39873H78.8567V24.6008H89.451C93.4011 24.6008 96.3982 23.8609 98.4423 22.3811C100.487 20.8603 101.509 18.7229 101.509 15.9689Z"
            fill="#010020"
        />
        <path
            d="M143.593 19.0474C141.248 18.5429 139.094 17.478 137.51 15.9646L125.281 34.6856C125.09 34.6856 124.837 34.6295 124.647 34.6295C120.148 34.6295 116.473 37.8244 116.473 41.8601C116.473 45.8398 120.148 49.0907 124.647 49.0907C129.146 49.0907 132.821 45.8398 132.821 41.8601C132.821 40.3467 132.314 38.9455 131.363 37.7684L143.593 19.0474Z"
            fill="#010020"
        />
        <path
            d="M146.571 15.4605C146.761 15.4605 147.015 15.4045 147.205 15.4045L159.434 34.1255C161.081 32.6121 163.172 31.5471 165.517 31.0427L153.288 12.3217C154.175 11.1446 154.745 9.74333 154.745 8.22995C154.745 4.25034 151.07 0.99939 146.571 0.99939C142.072 0.99939 138.397 4.25034 138.397 8.22995C138.397 12.2096 142.072 15.4605 146.571 15.4605Z"
            fill="#010020"
        />
        <path
            d="M168.495 34.6316C165.263 34.6316 162.475 36.3131 161.144 38.7794H136.686C137.066 39.7322 137.193 40.7972 137.193 41.8622C137.193 42.9271 137.003 43.9921 136.686 44.945H161.144C162.475 47.3551 165.263 49.0927 168.495 49.0927C172.993 49.0927 176.668 45.8418 176.668 41.8622C176.668 37.8265 172.993 34.6316 168.495 34.6316Z"
            fill="#010020"
        />
        <path
            d="M224.521 0.61656V43.7758H217.064L190.159 14.5508V43.7758H181.168V0.61656H188.626L215.53 29.8415V0.61656H224.521ZM267.427 21.7029H276V38.9049C273.491 40.6722 270.563 42.0286 267.218 42.9742C263.872 43.9198 260.433 44.3923 256.902 44.3923C251.93 44.3923 247.446 43.4467 243.45 41.5561C239.454 39.6242 236.317 36.973 234.041 33.6025C231.763 30.232 230.625 26.4298 230.625 22.1962C230.625 17.9625 231.763 14.1603 234.041 10.7898C236.317 7.41929 239.454 4.7886 243.45 2.89783C247.493 0.965925 252.023 0 257.042 0C261.13 0 264.848 0.59599 268.194 1.78802C271.539 2.98006 274.35 4.72694 276.627 7.02878L270.912 11.9613C267.194 8.67292 262.71 7.02878 257.46 7.02878C254.021 7.02878 250.954 7.66591 248.259 8.94012C245.611 10.2143 243.52 12.0023 241.986 14.3042C240.499 16.606 239.756 19.2367 239.756 22.1962C239.756 25.0734 240.499 27.663 241.986 29.9648C243.52 32.2667 245.611 34.0752 248.259 35.3905C250.954 36.7059 253.998 37.3635 257.39 37.3635C261.2 37.3635 264.546 36.6237 267.427 35.1439V21.7029ZM321.058 37.0552V43.7758H284.465V0.61656H320.082V7.33706H293.526V18.5585H317.085V25.1556H293.526V37.0552H321.058Z"
            fill="#010020"
        />
    </svg>
);

const LogoWhite = () => (
    <svg width="322" height="50" viewBox="0 0 322 50" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M0 0.616577H3.5547V43.7758H0V0.616577ZM57.4968 0.616577V43.7758H54.5696L21.3227 6.28893V43.7758H17.768V0.616577H20.7651L53.9423 38.1034V0.616577H57.4968ZM101.718 43.7758L91.7511 31.1363C91.3329 31.1774 90.7057 31.1979 89.8692 31.1979H78.8567V43.7758H69.7957V0.616577H89.8692C94.0981 0.616577 97.7688 1.23314 100.882 2.46626C104.042 3.69938 106.458 5.46683 108.131 7.76867C109.803 10.0705 110.64 12.8039 110.64 15.9689C110.64 19.2161 109.734 22.0112 107.922 24.3541C106.156 26.6971 103.6 28.444 100.255 29.5949L111.476 43.7758H101.718ZM101.509 15.9689C101.509 13.215 100.487 11.0981 98.4423 9.61835C96.3982 8.13861 93.4011 7.39873 89.451 7.39873H78.8567V24.6008H89.451C93.4011 24.6008 96.3982 23.8609 98.4423 22.3811C100.487 20.8603 101.509 18.7229 101.509 15.9689Z"
            fill="#FFFFFF"
        />
        <path
            d="M143.593 19.0474C141.248 18.5429 139.094 17.478 137.51 15.9646L125.281 34.6856C125.09 34.6856 124.837 34.6295 124.647 34.6295C120.148 34.6295 116.473 37.8244 116.473 41.8601C116.473 45.8398 120.148 49.0907 124.647 49.0907C129.146 49.0907 132.821 45.8398 132.821 41.8601C132.821 40.3467 132.314 38.9455 131.363 37.7684L143.593 19.0474Z"
            fill="#FFFFFF"
        />
        <path
            d="M146.571 15.4605C146.761 15.4605 147.015 15.4045 147.205 15.4045L159.434 34.1255C161.081 32.6121 163.172 31.5471 165.517 31.0427L153.288 12.3217C154.175 11.1446 154.745 9.74333 154.745 8.22995C154.745 4.25034 151.07 0.99939 146.571 0.99939C142.072 0.99939 138.397 4.25034 138.397 8.22995C138.397 12.2096 142.072 15.4605 146.571 15.4605Z"
            fill="#FFFFFF"
        />
        <path
            d="M168.495 34.6316C165.263 34.6316 162.475 36.3131 161.144 38.7794H136.686C137.066 39.7322 137.193 40.7972 137.193 41.8622C137.193 42.9271 137.003 43.9921 136.686 44.945H161.144C162.475 47.3551 165.263 49.0927 168.495 49.0927C172.993 49.0927 176.668 45.8418 176.668 41.8622C176.668 37.8265 172.993 34.6316 168.495 34.6316Z"
            fill="#FFFFFF"
        />
        <path
            d="M224.521 0.61656V43.7758H217.064L190.159 14.5508V43.7758H181.168V0.61656H188.626L215.53 29.8415V0.61656H224.521ZM267.427 21.7029H276V38.9049C273.491 40.6722 270.563 42.0286 267.218 42.9742C263.872 43.9198 260.433 44.3923 256.902 44.3923C251.93 44.3923 247.446 43.4467 243.45 41.5561C239.454 39.6242 236.317 36.973 234.041 33.6025C231.763 30.232 230.625 26.4298 230.625 22.1962C230.625 17.9625 231.763 14.1603 234.041 10.7898C236.317 7.41929 239.454 4.7886 243.45 2.89783C247.493 0.965925 252.023 0 257.042 0C261.13 0 264.848 0.59599 268.194 1.78802C271.539 2.98006 274.35 4.72694 276.627 7.02878L270.912 11.9613C267.194 8.67292 262.71 7.02878 257.46 7.02878C254.021 7.02878 250.954 7.66591 248.259 8.94012C245.611 10.2143 243.52 12.0023 241.986 14.3042C240.499 16.606 239.756 19.2367 239.756 22.1962C239.756 25.0734 240.499 27.663 241.986 29.9648C243.52 32.2667 245.611 34.0752 248.259 35.3905C250.954 36.7059 253.998 37.3635 257.39 37.3635C261.2 37.3635 264.546 36.6237 267.427 35.1439V21.7029ZM321.058 37.0552V43.7758H284.465V0.61656H320.082V7.33706H293.526V18.5585H317.085V25.1556H293.526V37.0552H321.058Z"
            fill="#FFFFFF"
        />
    </svg>
);

export const Logo = ({ className = '', color = 'blue', includeLogoSmall, includeLogoType }) => {
    return (
        <LogoWrapper className={className}>
            {(includeLogoSmall || !includeLogoType) && <img src={logo} alt="InRange Logo" />}
            {includeLogoType && (
                <>
                    {color === 'blue' && <LogoBlue />}
                    {color === 'white' && <LogoWhite />}
                </>
            )}
        </LogoWrapper>
    );
};

Logo.propTypes = {
    className: PropTypes.string,
    color: PropTypes.oneOf(['white', 'blue']),
    includeLogoSmall: PropTypes.bool,
    includeLogoType: PropTypes.bool
};

const LogoWrapper = styled.div`
    display: flex;
    img {
        width: 100%;
        object-fit: contain;
    }
`;
