import React from "react";
import styled from "styled-components";

const AccordionStyled = styled.div`
    width: 100%;
    height: fit-content;
    .accordion-btn {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        cursor: pointer;
    }
    .accordion-content {
        padding-top: 0.5rem;
        overflow: hidden;
        max-height: 0;
        max-height: ${(props) => (props.open ? "3000vh" : "0")};
        & > div {
            height: 100%;
        }
    }
`;

const Accordion = ({ children, className, id, index, isOpen, setIsOpen, style, title }) => {
    return (
        <AccordionStyled className={className} id={id} open={isOpen} style={style}>
            <div
                className="accordion-btn px-4 py-2"
                onClick={(ev) => {
                    ev.stopPropagation();
                    setIsOpen();
                }}
            >
                <div className={`text-3xl ${isOpen ? "text-secondary" : ""}`}>
                    <span className="font-medium mr-4">{index}</span>
                    {title}
                </div>
            </div>

            <div className="accordion-content">
                <div className="px-4 py-2">{children}</div>
            </div>
        </AccordionStyled>
    );
};

export default Accordion;
