import React from "react";
import Form from "components/composite/Form";
import * as yup from "yup";

const RevealQuickAnalysisForm = ({
  handleSubmit,
  validateOnChange,
  submitText,
}) => {
  const emailRegex =
    /^([\w-.]+@(?!gmail\.com)(?!hotmail\.com)(?!live\.com)(?!icloud\.com)([\w-]+.)+[\w-]{1,4})?$/;

  yup.addMethod(
    yup.string,
    "customEmailValidation",
    function validateEmail(message) {
      return this.matches(emailRegex, {
        message,
        name: "customEmailValidation",
      });
    }
  );

  const fields = [
    {
      id: "firstName",
      value: "",
      label: "First name",
      placeholder: "Enter your first name",
      required: true,
    },
    {
      id: "lastName",
      value: "",
      label: "Last name",
      placeholder: "Enter your last name",
      required: true,
    },
    {
      fullSize: true,
      id: "companyEmail",
      value: "",
      label: "Company email",
      placeholder: "Enter your company email",
      required: true,
      type: "email",
    },
  ];

  const validations = {
    firstName: yup
      .string("Please provide a valid name")
      .required("Please provide a valid name"),
    lastName: yup
      .string("Please provide a valid last name")
      .required("Please provide a valid last name"),
    companyEmail: yup
      .string("Please provide a valid email")
      .email("Please provide a valid email")
      .customEmailValidation("Please provide your company email")
      .required("Please provide a valid email"),
  };

  return (
    <div>
      <Form
        className="py-4"
        fields={fields}
        nativeSubmit={false}
        onSubmit={handleSubmit}
        submitText={submitText}
        validations={validations}
        validateOnChange={validateOnChange}
        wrap={false}
        gap={0.5}
        inheritFont={true}
      ></Form>
    </div>
  );
};

export default RevealQuickAnalysisForm;
