import React, { createContext, useContext } from 'react';
import { gsap } from 'gsap';
import ScrollTrigger from 'gsap/ScrollTrigger';

export const GsapContext = createContext();

gsap.registerPlugin(ScrollTrigger);

export const GsapContextProvider = (props) => {
    const value = {
        gsap
    };

    return <GsapContext.Provider {...props} value={value} />;
};

export const useGsapContext = () => useContext(GsapContext);
