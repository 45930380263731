import React, { useLayoutEffect, useRef } from 'react';
import PageSection from 'components/PageSection';
import { Body1, SectionTitle } from 'components/Typography';
import energyBuyerSolarGrid from 'assets/images/energy-buyer-solar-grid.png';
import energyProcured from 'assets/images/energy-procured-by-month.png';
import tariffFixed from 'assets/images/tariff-fixed.png';
import cfeContribution from 'assets/images/CFE-contribution.png';
import { useGsapContext } from 'gsapContext';

const SolutionSectionThree = () => {
    const $context = useRef();
    const { gsap } = useGsapContext();

    useLayoutEffect(() => {
        let ctx = gsap.context((self) => {
            const timeline = gsap.timeline({
                scrollTrigger: {
                    trigger: $context.current,
                    start: 'top center-=200',
                    end: 'top top',
                    toggleActions: 'restart none none reverse'
                }
            });
            timeline.to('.images-wrapper', { scale: 1.4, xPercent: 5 });
            timeline.to('.hero-card-1', { opacity: 1, scale: 1, duration: 0.1 });
            timeline.to('.hero-card-2', { opacity: 1, scale: 1, duration: 0.1 });
        }, $context);

        return () => ctx.revert();
        // eslint-disable-next-line
    }, []);

    return (
        <PageSection
            ref={$context}
            color="neutral"
            containerClass="flex flex-col py-32 gap-80 md:gap-[500px] lg:gap-48"
        >
            <div className="flex flex-col lg:flex-row gap-16">
                <div className="w-full lg:w-3/5">
                    <SectionTitle className="fade-in-scroll">A new energy asset class</SectionTitle>
                    <Body1 className="fade-in-scroll pt-4 pb-4">
                    InRange delivers renewable energy at megawatt scale from distributed assets in the built environment.
                    </Body1>
                    <Body1 className="fade-in-scroll">
                    We aggregate  excess generation from commercial buildings, with the same procurement and settlement experience you're used to.
                    </Body1>
                    <Body1 className="fade-in-scroll pt-4 pb-4">
                    Match your demand to renewable energy supply with a single point of access, unlocking a new alternative energy asset class.                                        </Body1>
                </div>
                <div className="w-full lg:w-2/5">
                    <div className="images-wrapper max-h-40 max-w-xs md:max-h-48 md:max-w-md lg:max-w-2xl lg:max-h-96 relative origin-top-left lg:origin-left">
                        <img
                            className="relative md:left-48 lg:left-0 first-img object-contain w-full h-full"
                            src={energyProcured}
                            alt="Product"
                        />

                        <img
                            className="hero-card-1 absolute opacity-0 scale-75 h-20 md:h-28 -bottom-20 -left-4 md:-bottom-32 md:left-0 lg:-bottom-4 lg:-left-10"
                            src={tariffFixed}
                            alt=""
                        />
                        <img
                            className="hero-card-2 absolute opacity-0 scale-75 h-20 md:h-28 -bottom-32 left-20 md:-bottom-56 md:left-72 lg:-bottom-24 lg:left-40"
                            src={cfeContribution}
                            alt=""
                        />
                    </div>
                </div>
            </div>

            <div className="flex flex-col-reverse lg:flex-row gap-16 items-center">
                <div className="w-full lg:w-2/5">
                    <div className="fade-in-scroll max-w-md lg:max-w-lg">
                        <img src={energyBuyerSolarGrid} alt="" />
                    </div>
                </div>
                <div className="w-full lg:w-3/5">
                    <SectionTitle className="fade-in-scroll">
                    Forecasted and de-risked
                    </SectionTitle>
                    <Body1 className="fade-in-scroll pt-4 pb-4">
                    InRange delivers solar + battery power with a reliable production profile, using advanced AI forecasting to create system separations.
                    <br/><br/>
                    Assets are part of a managed, distributed energy network which forecasts generation and demand, automating and optimising energy distribution.
                    </Body1>
                </div>
            </div>
        </PageSection>
    );
};

export default SolutionSectionThree;
