import Button from 'components/Button';
import PageSection from 'components/PageSection';
import { Body1, SectionTitle } from 'components/Typography';
import appConfig from 'config/appConfig';
import { paths } from 'constants';
import { useGsapContext } from 'gsapContext';
import { useLayoutEffect, useRef } from 'react';
import { Link } from 'react-router-dom';

const ContactConfirmation = () => {
    const $context = useRef();
    const { gsap } = useGsapContext();

    useLayoutEffect(() => {
        let ctx = gsap.context(() => {
            const timeline = gsap.timeline({ delay: 0.3 });
            timeline.fromTo('.bottom-fade-in', { opacity: 0, y: '50px' }, { opacity: 1, y: 0, duration: 0.2 });
            timeline.fromTo('.bottom-fade-in-2', { opacity: 0, y: '50px' }, { opacity: 1, y: 0, duration: 0.2 });
            timeline.fromTo('.bottom-fade-in-3', { opacity: 0, y: '50px' }, { opacity: 1, y: 0, duration: 0.2 });
            timeline.fromTo('.bottom-fade-in-4', { opacity: 0, y: '50px' }, { opacity: 1, y: 0, duration: 0.2 });
        }, $context);

        return () => ctx.revert();
        // eslint-disable-next-line
    }, []);

    return (
        <PageSection
            ref={$context}
            className={`pb-16 lg:pb-32 ${appConfig.layout.header.fixed ? 'pt-24 lg:pt-32' : 'pt-16 lg:pt-20'}`}
            containerClass="flex flex-col lg:flex-row gap-16"
        >
            <div className="flex-1 max-w-lg">
                <SectionTitle className="bottom-fade-in">Thanks for getting in touch!</SectionTitle>
                <Body1 className="bottom-fade-in-2 mt-6 md:mt-10 mb-6">
                    We've received your request. You'll hear from us shortly. In the meantime, we invite you to read our
                    blog and learn more.
                </Body1>
                <div className="flex gap-4">
                    <a href="https://blog.inrange.io/" target="_blank" rel="noreferrer">
                        <Button className="bottom-fade-in-3" element="div">
                            Read blog
                        </Button>
                    </a>

                    <Link to={paths.home}>
                        <Button className="bottom-fade-in-4" element="div" variant="outlined">
                            Homepage
                        </Button>
                    </Link>
                </div>
            </div>
            <div className="flex-1"></div>
        </PageSection>
    );
};

export default ContactConfirmation;
