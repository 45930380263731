import React from 'react';

const Dots = (props) => {
    return (
        <svg {...props} width="80" height="72" viewBox="0 0 80 72" fill="none" xmlns="http://www.w3.org/2000/svg">
            <circle cx="4" cy="4" r="4" fill="#F2F2F2" />
            <circle cx="52" cy="4" r="4" fill="#F2F2F2" />
            <circle cx="28" cy="4" r="4" fill="#F2F2F2" />
            <circle cx="76" cy="4" r="4" fill="#F2F2F2" />
            <circle cx="4" cy="20" r="4" fill="#F2F2F2" />
            <circle cx="52" cy="20" r="4" fill="#F2F2F2" />
            <circle cx="28" cy="20" r="4" fill="#F2F2F2" />
            <circle cx="76" cy="20" r="4" fill="#F2F2F2" />
            <circle cx="4" cy="36" r="4" fill="#F2F2F2" />
            <circle cx="52" cy="36" r="4" fill="#F2F2F2" />
            <circle cx="28" cy="36" r="4" fill="#F2F2F2" />
            <circle cx="76" cy="36" r="4" fill="#F2F2F2" />
            <circle cx="4" cy="52" r="4" fill="#F2F2F2" />
            <circle cx="52" cy="52" r="4" fill="#F2F2F2" />
            <circle cx="28" cy="52" r="4" fill="#F2F2F2" />
            <circle cx="76" cy="52" r="4" fill="#F2F2F2" />
            <circle cx="4" cy="68" r="4" fill="#F2F2F2" />
            <circle cx="52" cy="68" r="4" fill="#F2F2F2" />
            <circle cx="28" cy="68" r="4" fill="#F2F2F2" />
            <circle cx="76" cy="68" r="4" fill="#F2F2F2" />
        </svg>
    );
};

export default Dots;
