const theme = {
    colors: {
        neutral: '#EEEEEE',
        neutral700: '#404040',
        primary: '#00022F',
        secondary: '#2779A7'
    },
    fonts: {
        primary: '"Manrope", sans-serif'
    },
    fontSize: {}
};

export default theme;
