import React from "react";
import PageSection from "components/PageSection";
import { Display3 } from "components/Typography";
import AnalyseSite from "components/composite/AnalyseSite";

const bodies = {
  product:
    "Start your streamlined energy procurement process.",
  landlord: "Unlock portfolio value and deliver for your tenants.",
  tenant: "Level up your energy procurement and start saving.",
  datacentre: "Get access to the new alternative energy asset class.",
  epc: "Expand your reach and keep your team building.",
};

const DynamicAnalyse = ({ page, backgroundColor }) => {
  const bodyText = bodies[page];
  return (
    <PageSection
      className="pt-20 pb-36 md:pt-38 md:pb-24 lg:py-32 z-10 relative"
      color={backgroundColor}
      containerClass="fade-in-scroll"
    >
      <Display3 className="md:w-4/5 lg:w-3/5">{bodyText}</Display3>
      <div className="flex flex-col items-center md:flex-row md:w-4/5 lg:w-3/5">
        <div className="w-full pt-4">
          <AnalyseSite buttonColor={"primary"} border={true}></AnalyseSite>
        </div>
      </div>
    </PageSection>
  );
};

export default DynamicAnalyse;
