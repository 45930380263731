import React from 'react';
import PageSection from 'components/PageSection';
import backgroundImage from 'assets/images/product-banner.jpg';
import { Display3 } from 'components/Typography';

const ProductBanner = () => {
    return (
        <PageSection
            background={`url(${backgroundImage}) no-repeat center`}
            backgroundSize="cover"
            className="relative py-24 md:py-36 after:absolute after:top-0 after:left-0 after:z-10 after:w-full after:h-full after:bg-neutral after:opacity-60"
            containerClass="flex lg:justify-end"
        >
            <Display3 className="fade-in-scroll uppercase relative z-20 w-3/4 lg:w-2/5" letterSpacing="0.22em">
            The only unified platform for clean energy procurement.
            </Display3>
        </PageSection>
    );
};

export default ProductBanner;
