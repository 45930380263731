import React from "react";
import PageSection from "components/PageSection";
import { Body1, BodyStrong1, Title2 } from "components/Typography";

const OurMission = () => {
    return (
        <PageSection
            className="relative z-10 bg-white"
            containerClass="flex flex-col items-center md:w-7/12 py-16 md:pb-32 lg:py-44"
        >
            <Title2 className="fade-in-scroll text-neutral-700 text-center mb-4">Our mission</Title2>
            <BodyStrong1 className="fade-in-scroll text-center mb-12">
                Create reliable, local, carbon-free energy at scale so that our local grids become 100% renewable.
            </BodyStrong1>
            <Body1 className="fade-in-scroll text-center">
                We're here to turn millions of square feet of empty commercial real estate rooftops into renewable energy generators.
                <br /> <br />
                By making it faster, easier, and more cost-effective for landlords and tenants to install solar and battery, we are building the next generation clean energy company.
            </Body1>
        </PageSection>
    );
};

export default OurMission;
