import Button from 'components/Button';
import PageSection from 'components/PageSection';
import { Body1, SectionSubtitle } from 'components/Typography';

function RedirectPage({ destination, text }) {
    window.location.replace(destination);
    return (
        <PageSection>
            <div className="bottom-fade-in flex flex-1 flex-col gap-6 items-start w-full">
                <SectionSubtitle>
                    <a href={destination}>{text}</a>
                </SectionSubtitle>
                <Body1>
                    <a href={destination}>
                        <Button element="div">Click here</Button>
                    </a>
                </Body1>
            </div>
        </PageSection>
    );
}

export default RedirectPage;
