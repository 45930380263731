import React from "react";
import PropTypes from "prop-types";
import { medium, small, xsmall } from "../styles/breakpoints";
import styled from "styled-components";
import generationImg from "../assets/images/generation.svg";
import carbonImg from "../assets/images/carbon.svg";

const AnalyseStatBox = ({
  variant = "contained",
  value = "...",
  symbol = "%",
  description = "Blank description",
  image = variant === "contained" ? generationImg : carbonImg,
  hidden = false,
}) => {
  const boxStyles = {
    contained: "w-full h-full bg-primary !text-white",
    outlined: "w-full h-full bg-transparent !text-primary",
  };

  const displayValue = hidden ? "..." : value;
  const symbolMap = {
    "£": displayValue,
    CO2e: displayValue + "t CO2e",
    MWh: displayValue + " MWh",
  };

  const boxStyle = boxStyles[variant] || boxStyles.contained;
  const symbolElement = symbolMap[symbol];

  return (
    <div
      className={
        boxStyle +
        " flex flex-col items-center justify-start rounded-xl border-2 p-1.5 sm:p-3 md:p-4 lg:p-5 border-primary"
      }
    >
      <div className="w-1/3 h-1/2">
        <img src={image} alt="Analyse stat box" />
      </div>
      <Title variant={variant}>{symbolElement}</Title>
      <Description variant={variant} className="text-center">
        {description}
      </Description>
    </div>
  );
};

export default AnalyseStatBox;

AnalyseStatBox.propTypes = {
  className: PropTypes.string,
  variant: PropTypes.oneOf(["contained", "outlined"]),
  value: PropTypes.string,
  symbol: PropTypes.oneOf(["£", "CO2e", "MWh"]),
  description: PropTypes.string,
  imageType: PropTypes.string,
};

const Title = styled.h1`
  font-style: normal;
  letter-spacing: ${(props) => props.letterSpacing || "-0.04em"};
  font-size: 4vw;
  line-height: 40px;
  font-weight: 700;
  white-space: nowrap;
  ${(props) =>
    props.variant &&
    `
    color: ${props.variant === "outlined" ? "primary" : "white"};
  `}
  ${medium(`
      font-size: 21px;
      line-height: 48px;
  `)}
`;

export const Description = styled.p`
  letter-spacing: ${(props) => props.letterSpacing || "-0.01em"};
  font-size: 8px;
  line-height: 1.2;
  font-style: normal;
  min-height: 40px;
  font-weight: 400;
  ${(props) =>
    props.variant &&
    `
    color: ${props.variant === "outlined" ? "primary" : "white"};
  `}
  ${xsmall(`
      font-size: 12px;
  `)}
  ${small(`
      font-size: 13px;
  `)}
  ${medium(`
    font-size: 14px;
  `)}
`;
