import {
  buildPublicUserInteractionData,
  createPublicUserInteraction,
  postPublicUserInteractionMessage,
} from "InRangeAPI";
import Button from "./Button";

const RequestUpdate = ({
  buildingPoint,
  requestedUpdate,
  setRequestedUpdate,
  buildingValidity,
  formValues,
  buildingKey,
  buildingPostcodeAddress,
}) => {
  const handleClick = () => {
    const data = buildPublicUserInteractionData(
      formValues,
      buildingKey,
      buildingPoint,
      buildingPostcodeAddress
    );
    const companyEmail = formValues.companyEmail;
    const name = formValues.firstName + " " + formValues.lastName;
    const latitude = buildingPoint[0];
    const longitude = buildingPoint[1];
    const status = buildingValidity.size ? "INVALID_LOCATION" : "INVALID_SIZE";

    setRequestedUpdate(true);
    postPublicUserInteractionMessage(
      data,
      !buildingValidity.size ? "REQUEST_UPDATE_SMALL" : "REQUEST_UPDATE_FOREIGN"
    );
    createPublicUserInteraction(
      companyEmail,
      latitude,
      longitude,
      name,
      buildingKey,
      status
    );
  };

  return (
    <div>
      <p className="mt-3">
        Leave your contact info and we’ll update you when we support this
        address. In the meantime, you can search another address.
      </p>
      <Button disabled={requestedUpdate} onClick={handleClick} className="mt-5">
        {requestedUpdate ? "Requested!" : "Let me know!"}
      </Button>
    </div>
  );
};

export default RequestUpdate;
