import { paths } from "constants";
import AddressBar from "components/AddressBar";
import Button from "components/Button";
import { useState } from "react";
import { useNavigate } from "react-router-dom/dist";
import { postPublicUserInteractionMessage } from "InRangeAPI";

const { timeZone, locale } = Intl.DateTimeFormat().resolvedOptions();

const AnalyseSite = ({
  className,
  initialLatLong = "",
  buttonColor,
  buttonStyle,
  setPosition = () => {},
  border,
}) => {
  const [location, setLocation] = useState({});
  const [addressError, setAddressError] = useState(false);
  const navigate = useNavigate();

  const suggestionHandler = (suggestion) => {
    if (!suggestion) {
      setAddressError(true);
      return;
    }
    setLocation(suggestion);
    setAddressError(false);
  };

  const onAnalyseClick = () => {
    if (addressError) return;

    const center = location.center;
    const lat = center[1];
    const long = center[0];
    const data = {
      address: location.place_name,
      timezone: timeZone,
      language: locale,
    };

    if (!location.center) {
      setAddressError(true);
      return;
    }
    setPosition([lat, long]);
    postPublicUserInteractionMessage(data, "ADDRESS_ANALYSED");
    navigate(`${paths.analyse}/${lat}/${long}`);
  };

  return (
    <>
      <div
        className={"flex flex-col md:flex-row gap-4 items-center" + className}
      >
        <div className="w-full md:w-2/3 pb-3">
          <AddressBar
            initialLatLong={initialLatLong}
            border={border}
            setError={setAddressError}
            suggestionHandler={suggestionHandler}
          />
          <div className="absolute">
            {addressError && (
              <span className="text-error">Provide a valid address.</span>
            )}
          </div>
        </div>
        <div className="flex items-center pb-3">
          <Button
            color={buttonColor}
            className={buttonStyle ?? "px-6 md:px-10 h-fit w-fit"}
            onClick={onAnalyseClick}
          >
            Analyse site
          </Button>
        </div>
      </div>
    </>
  );
};

export default AnalyseSite;
