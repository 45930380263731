import React from 'react';
import PageSection from 'components/PageSection';
import managedMarketplace from 'assets/images/managed-marketplace.png';
import { Body1, SectionTitle } from 'components/Typography';

const AccessOffSiteEnergy = () => {
    return (
        <PageSection className="py-32">
            <div className="flex flex-col lg:flex-row gap-8 lg:items-end">
                <div className="flex-1">
                    <SectionTitle className="fade-in-scroll mb-8">
                    Intelligent energy management
                    </SectionTitle>
                    <Body1 className="fade-in-scroll mb-4">
                    InRange's advanced forecasting and analytics optimise energy distribution, balancing supply and demand across the network for maximal utilisation.
                    </Body1>
                    <Body1 className="fade-in-scroll">
                    Control unknowns by selling & buying energy within your sites, or across our network, at fixed, long term tariffs. Transparent, unified settlement is built-in.
                    </Body1>
                </div>

                <div className="fade-in-scroll flex-1">
                    <img className="w-full" src={managedMarketplace} alt="" />
                </div>
            </div>
            <div className="w-full overflow-x-scroll md:overflow-hidden mt-16 pb-4 md:pb-0">
                <div className="flex justify-center gap-8 pt-16 md:px-12 w-[160vw] md:w-full">
                    <div className="fade-in-scroll flex-1 bg-primary text-white p-8 rounded-xl">
                        <Body1>
                          InRange is disrupting the industry's traditional “generate only what the tenant needs” approach by up-sizing solar installations.
                        </Body1>
                    </div>
                    <div className="fade-in-scroll flex-1 bg-primary text-white p-8 rounded-xl">
                        <Body1>
                        Maximising on-site generation, and guaranteeing sale of excess at fixed, long term tariffs on the InRange Marketplace, future proofs your portfolio.
                        </Body1>
                    </div>
                </div>
            </div>
        </PageSection>
    );
};

export default AccessOffSiteEnergy;
