import analyzeImg2 from 'assets/images/product-analyze2.png';
import analyzeImg3 from 'assets/images/product-analyze3.png';
import analyzeImg4 from 'assets/images/product-analyze4.png';
import analyzeImg5 from 'assets/images/product-analyze5.png';
import analyzeImg6 from 'assets/images/product-analyze6.png';
import analyzeImg7 from 'assets/images/product-analyze7.png';
import analyzeImg8 from 'assets/images/product-analyze8.png';
import Dots from 'components/Dots';
import PageSection from 'components/PageSection';
import { Body1, SectionSubtitle, SectionTitle } from 'components/Typography';
import { useGsapContext } from 'gsapContext';
import { useLayoutEffect, useRef } from 'react';
import styled from 'styled-components';
import { medium } from 'styles/breakpoints';
import appConfig from 'config/appConfig';

const DashboardStyled = styled.div`
    position: relative;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(6, 1fr);
    align-items: center;
    gap: 1rem;
    ${medium(`
        grid-template-columns: repeat(10, 1fr);
        grid-template-rows: 15% 85%;
    `)}
    & .button-item {
        &:nth-of-type(1) {
            grid-column: 1/2;
            grid-row: 1/3;
            ${medium(`
            grid-column: 1/3;
            grid-row: 1/2;
            `)}
        }
        &:nth-of-type(2) {
            grid-column: 1/2;
            grid-row: 3/5;
            ${medium(`
            grid-column: 3/5;
            grid-row: 1/2;
            `)}
        }
        &:nth-of-type(3) {
            grid-column: 1/2;
            grid-row: 5/7;
            ${medium(`
            grid-column: 5/7;
            grid-row: 1/2;
            `)}
        }
        &:nth-of-type(4) {
            grid-column: 2/3;
            grid-row: 2/4;
            ${medium(`
            grid-column: 7/9;
            grid-row: 1/2;
            `)}
        }
        &:nth-of-type(5) {
            grid-column: 2/3;
            grid-row: 4/6;
            ${medium(`
            grid-column: 9/11;
            grid-row: 1/2;
            `)}
        }
    }
    & .controls {
        display: none;
        grid-column: 1/6;
        ${medium(`
            display: block;
        `)}
    }
    & .half-map {
        display: none;
        grid-column: 6/11;
        object-fit: cover;
        transform: scaleY(1.1);
        ${medium(`
        display: block
        `)}
    }
`;

const Analyze = () => {
    const $context = useRef();
    const { gsap } = useGsapContext();

    useLayoutEffect(() => {
        let ctx = gsap.context(() => {
            const timeline = gsap.timeline({
                scrollTrigger: {
                    trigger: $context.current,
                    start: 'top center-=200',
                    end: 'top top',
                    toggleActions: 'restart none none reverse',
                    scrub: 1
                }
            });
            timeline.to('.map-img', { opacity: 0 });
            timeline.to('.button-item:nth-of-type(1)', { opacity: 1 });
            timeline.to('.button-item:nth-of-type(2)', { opacity: 1 });
            timeline.to('.button-item:nth-of-type(3)', { opacity: 1 });
            timeline.to('.button-item:nth-of-type(4)', { opacity: 1 });
            timeline.to('.button-item:nth-of-type(5)', { opacity: 1 });
            timeline.to('.controls', { opacity: 1 });
            timeline.to('.half-map', { opacity: 1 });
        }, $context);

        return () => ctx.revert();
        // eslint-disable-next-line
    }, []);

    return (
        <PageSection ref={$context} className={`pb-32 ${appConfig.layout.header.fixed ? 'pt-48' : 'pt-32'}`}>
            <div className="relative flex flex-col-reverse lg:flex-row gap-8">
                <DashboardStyled className="relative z-10 fade-in-scroll flex-1">
                    <img className="button-item opacity-0" src={analyzeImg3} alt="" />
                    <img className="button-item opacity-0" src={analyzeImg4} alt="" />
                    <img className="button-item opacity-0" src={analyzeImg5} alt="" />
                    <img className="button-item opacity-0" src={analyzeImg6} alt="" />
                    <img className="button-item opacity-0" src={analyzeImg7} alt="" />
                    <img className="controls opacity-0" src={analyzeImg2} alt="" />
                    <img className="half-map opacity-0" src={analyzeImg8} alt="" />
                </DashboardStyled>
                <div className="relative flex-1">
                    <div className="hidden md:block absolute top-0 right-0 z-0">
                        <Dots />
                    </div>
                    <SectionTitle className="fade-in-scroll relative z-10">Accelerate action</SectionTitle>
                    <SectionSubtitle className="fade-in-scroll mb-6 mt-1 relative z-10">
                     Empower your team to make faster, data-driven decisions.
                    </SectionSubtitle>
                    <Body1 className="fade-in-scroll mb-4 relative z-10">
                    From the first call, the InRange platform’s free automated analysis gives your team a CFO-ready, portfolio-wide view of what your assets can do.
                    </Body1>
                    <Body1 className="fade-in-scroll relative z-10">
                    Landlords see 25% more solar revenue, tenants get 25% cost savings, and data centres access a reliable, cost-effective energy alternative.
                    </Body1>
                </div>

                <div className="hidden md:block absolute -bottom-12 -left-8 z-0">
                    <Dots />
                </div>
            </div>
        </PageSection>
    );
};

export default Analyze;
