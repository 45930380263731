import React from "react";
import { useForm } from "@formspree/react";
import requestADemoService from "services/RequestADemo";
import Form from "components/composite/Form";
import { Link, Navigate } from "react-router-dom";
import * as yup from "yup";

const RequestADemoForm = () => {
  const [state, handleSubmit] = useForm(requestADemoService.formSpreeId);

  const fields = [
    {
      id: "firstName",
      value: "",
      label: "First name",
      placeholder: "Enter your first name",
      required: true,
    },
    {
      id: "lastName",
      value: "",
      label: "Last name",
      placeholder: "Enter your last name",
      required: true,
    },
    {
      id: "companyName",
      value: "",
      label: "Company name",
      placeholder: "Enter your company name",
      required: true,
    },
    {
      id: "phoneNumber",
      value: "",
      label: "Phone number",
      placeholder: "Enter your phone number",
      required: true,
    },
    {
      fullSize: true,
      id: "companyEmail",
      value: "",
      label: "Company email",
      placeholder: "Enter your company email",
      required: true,
      type: "email",
    },
    {
      fullSize: true,
      id: "message",
      value: "",
      label: "How can we help you?",
      placeholder: "",
      required: true,
      type: "textarea",
    },
  ];

  const validations = {
    firstName: yup
      .string("Please provide a valid name")
      .required("Please provide a valid name")
      .min(2, "Please provide a valid name (at least 2 characters)"),
    lastName: yup
      .string("Please provide a valid last name")
      .required("Please provide a valid last name")
      .min(2, "Please provide a valid last name (at least 2 characters)"),
    companyName: yup
      .string("Please provide a valid company name")
      .required("Please provide a valid company name")
      .min(2, "Please provide a valid company name (at least 2 characters)"),
    phoneNumber: yup
      .string("Please provide a valid phone number")
      .matches(
        // eslint-disable-next-line
        /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/,
        'Please provide a valid phone number (no spaces, only numbers and "+" symbol are allowed)'
      )
      .required("Please provide a valid phone number"),
    companyEmail: yup
      .string("Please provide a valid email")
      .email("Please provide a valid email")
      .required("Please provide a valid email"),
    message: yup
      .string("Please provide a valid message")
      .required("Please provide a message")
      .min(2, "Please provide a valid message (at least 2 characters)"),
  };

  if (state.succeeded) return <Navigate to="/subscription-confirmation" />;

  return (
    <div>
      <Form
        className="py-8"
        fields={fields}
        nativeSubmit
        onSubmit={handleSubmit}
        submitText="Request a demo"
        validateOnChange={true}
        validations={validations}
      >
        <small className="text-neutral-800">
          By requesting a demo you agree to InRange's{" "}
          <Link className="underline" to="/privacy-policy">
            privacy policy
          </Link>
          , and consent to recieve InRange’s marketing communications.
        </small>
      </Form>
    </div>
  );
};

export default RequestADemoForm;
