import React from "react";
import PageSection from "components/PageSection";
import { Body2, Body3, Small, Title2Alt } from "components/Typography";

const WhatWeOffer = () => {
    return (
        <PageSection
            className="relative z-10 bg-white"
            containerClass="flex flex-col items-center gap-24 py-16 md:pb-24 lg:py-32"
        >
            <div className="text-center lg:w-1/2">
                <Body2 className="fade-in-scroll uppercase text-secondary mb-4">What we offer</Body2>
                <Title2Alt className="fade-in-scroll">What's it like to join InRange?</Title2Alt>
            </div>
            <div className="flex flex-col lg:flex-row w-full gap-16">
                <div className="fade-in-scroll lg:w-1/4">
                    <Body3 className="mb-4" style={{ fontWeight: 600 }}>
                        Competitive salary
                    </Body3>
                    <Small>
                        We are hiring the best, and setting them loose on disrupting the industry. That means paying them what they're worth.
                    </Small>
                </div>
                <div className="fade-in-scroll lg:w-1/4">
                    <Body3 className="mb-4" style={{ fontWeight: 600 }}>
                        Flexibility
                    </Body3>
                    <Small>
                        To do your best, you need flexibility. That's why we're not location specific, and have a
                        flexible, remote or office environment. 
                    </Small>
                </div>
                <div className="fade-in-scroll lg:w-1/4">
                    <Body3 className="mb-4" style={{ fontWeight: 600 }}>
                        Generous equity
                    </Body3>
                    <Small>
                        We're early stage, so equity can have a potentially life-changing upside. 
                        That becomes reality when we execute seamlessly as a team.
                    </Small>
                </div>
                <div className="fade-in-scroll lg:w-1/4">
                    <Body3 className="mb-4" style={{ fontWeight: 600 }}>
                        Autonomy to build
                    </Body3>
                    <Small>
                        You’ll work with an experienced team of startup builders and investors. 
                        We'll give you the autonomy and responsibilty that drives your growth.
                    </Small>
                </div>
            </div>
        </PageSection>
    );
};

export default WhatWeOffer;
