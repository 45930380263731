import AccessOffSiteEnergy from "./AccessOffSiteEnergy";
import Analyze from "./Analyze";
import DistributedEnergy from "./DistributedEnergy";
import DynamicAnalyse from "pages/DynamicAnalyse";
import GenerationPotential from "./GenerationPotential";
import HeaderAnalyse from "./HeaderAnalyse";
import ProductBanner from "./ProductBanner";

function ProductPage() {
  return (
    <>
      <HeaderAnalyse />
      <Analyze />
      <GenerationPotential />
      <ProductBanner />
      <AccessOffSiteEnergy />
      <DistributedEnergy />
      <DynamicAnalyse page="product" backgroundColor="neutral" />
    </>
  );
}

export default ProductPage;
