import { useGsapContext } from 'gsapContext';
import React, { useLayoutEffect, useRef } from 'react';

const InitialAnimation = () => {
    const $context = useRef();
    const { gsap } = useGsapContext();

    useLayoutEffect(() => {
        document.body.classList.add('menu-open');
        let ctx = gsap.context((self) => {
            const timeline = gsap.timeline();
            timeline.fromTo(
                $context.current,
                { opacity: 1 },
                { opacity: 0, duration: 0.4, delay: 0.6, ease: 'circ.out' }
            );
            timeline.to($context.current, { yPercent: -2000 });
        }, $context);

        setTimeout(() => {
            document.body.classList.remove('menu-open');
        }, 600);

        return () => ctx.revert();
        // eslint-disable-next-line
    }, []);

    return (
        <div
            ref={$context}
            className="flex pointer-events-none items-center justify-center absolute top-0 left-0 w-screen h-screen z-50 bg-primary"
        >
            <svg width="153" height="139" viewBox="0 0 153 139" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                    d="M68.9304 52.1625C62.9715 50.7045 57.4957 47.6265 53.4694 43.2526L22.3863 97.3602C21.9031 97.3602 21.2589 97.1982 20.7757 97.1982C9.34102 97.1982 0 106.432 0 118.096C0 129.598 9.34102 138.994 20.7757 138.994C32.2104 138.994 41.5515 129.598 41.5515 118.096C41.5515 113.722 40.2631 109.672 37.8473 106.27L68.9304 52.1625Z"
                    fill="#FFFFFF"
                />
                <path
                    d="M76.5005 41.7957C76.9836 41.7957 77.6279 41.6337 78.111 41.6337L109.194 95.7413C113.381 91.3673 118.696 88.2894 124.655 86.8314L93.572 32.7238C95.8268 29.3218 97.2762 25.2718 97.2762 20.8978C97.2762 9.39593 87.9352 0 76.5005 0C65.0658 0 55.7247 9.39593 55.7247 20.8978C55.7247 32.3998 65.0658 41.7957 76.5005 41.7957Z"
                    fill="#FFFFFF"
                />
                <path
                    d="M132.224 97.2043C124.011 97.2043 116.924 102.064 113.542 109.192H51.376C52.3424 111.946 52.6645 115.024 52.6645 118.102C52.6645 121.18 52.1813 124.258 51.376 127.012H113.542C116.924 133.978 124.011 139 132.224 139C143.659 139 153 129.604 153 118.102C153 106.438 143.659 97.2043 132.224 97.2043Z"
                    fill="#FFFFFF"
                />
            </svg>
        </div>
    );
};

export default InitialAnimation;
